// import { FaArrowLeft } from "react-icons/fa";
import { IMAGES } from "../../Assets";
import { Box, GenericLoader, Typography } from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTER_PATH } from "../../config";
import { FaArrowLeft } from "react-icons/fa";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18n from "../../Translation/i18n";

export default function ThankYou() {
  const navigate = useNavigate();
  const { width } = useWindowSizeHook();
  const { t } = useTranslation();
  const { state } = useLocation();
  const chat = useSelector((state) => state.chat);

  useEffect(() => {
    i18n.changeLanguage(state?.language);
  }, [state]);

  // const report1 =
  //   "- **Chief Complaint**\n  - Swelling in legs and feet over the past couple of weeks, making it difficult to walk comfortably.\n\n- **Symptoms**\n  - Swelling in legs\n  - Swelling in feet\n  - Difficulty walking comfortably\n\n- **Patient History**\n  - Patient is a 26-year-old male engineer named Irfan Malik.\n\n- **Family History**\n  - No family history provided.";
  // const report ="- ** الشكوى الرئيسية **\n- ألم شديد في الصدر، خاصة عند التنفس، وقد حدث ذلك منذ يومين.\n  \n- ** الأعراض **\n- ألم شديد في الصدر.\n- انزعاج عند التنفس.\n\n- ** تاريخ المريض **\n- اسم المريض: عرفان مالك.\n- العمر: 26 سنة.\n- الجنس: ذكر.\n- المهنة: مهندس.\n\n- ** التاريخ العائلي **\n- لا توجد معلومات متاحة عن التاريخ العائلي."
  // const navigateFun = (url) => {
  //   window.location.href = url;
  // };

  const renderSummary = (summary) => {
    let headingCount = 0;

    if (summary) {
      const parts = summary?.split(/-\s+/);
      return parts.map((part, index) => {
        if (part.includes("**")) {
          headingCount++;
          const heading = part.replace(/\*\*/g, " ");
          return (
            <h5
              key={index}
              style={{
                direction: state.language === "ar" ? "rtl" : "ltr",
              }}
            >
              {headingCount}) {heading}
            </h5>
          );
        }
        return (
          <p
            key={index}
            style={{
              direction: state.language === "ar" ? "rtl" : "ltr",
            }}
          >
            {part.trim()}
          </p>
        );
      });
    }
  };

  console.log("state langugage", state);

  return (
    <Box
      width="100%"
      maxWidth="202rem"
      minHeight="50rem"
      borderRadius="1.375rem"
      background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
      shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
      backdropFilter="blur(15px)"
      className="p-5"
    >
      <div>
        <span
          className="text-primary fw-bold d-flex align-items-center justify-content-start  cursor-pointer mb-3"
          onClick={() => navigate(ROUTER_PATH.CHAT_HISTORY)}
        >
          <FaArrowLeft className="me-2" /> Chat History
        </span>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src={IMAGES.THANKYOU_ICON}
          alt=""
          width={width < 600 ? "100" : "150"}
          className="mb-3"
        />
        <Typography as={width < 600 ? "h6" : "h4"} className="text-center">
          {t("thanksMessage")}
          {/* Thank you for using Cloud Solutions. */}
          {/* Thank you for using Xeven Pre-Consultant. */}
        </Typography>
      </div>
      {/* <Typography as={width < 600 ? "label" : "h5"} className="text-center">
        
      </Typography> */}
      <div className="d-flex mt-3 justify-content-center align-items-center">
        <div>
          {chat?.GENERATE_CHAT_SUMMARY_LOADING ? (
            <GenericLoader />
          ) : (
            // renderSummary(report)
            renderSummary(chat?.GENERATE_CHAT_SUMMARY?.data?.summary)
          )}
        </div>
        {/* <Button
          onClick={() =>
            navigateFun("https://form.typeform.com/to/CeQtkWw1")
            // navigateFun("https://viwnilw91ea.typeform.com/medistreamline")
          }
          className="text-white "
        >
          Give Feedback
        </Button> */}
      </div>
    </Box>
  );
}
