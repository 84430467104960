const INITIAL_STATES = {
  SAVE_SPECIALIZATION_QUESTIONS_LOADING: false,
  SAVE_SPECIALIZATION_QUESTIONS_SUCCESS: false,
  SAVE_SPECIALIZATION_QUESTIONS_FAILURE: false,
  SAVE_SPECIALIZATION_QUESTIONS_ERROR: null,
  SAVE_SPECIALIZATION_QUESTIONS: null,

  GET_SPECIALIZATION_QUESTIONS_LOADING: false,
  GET_SPECIALIZATION_QUESTIONS_SUCCESS: false,
  GET_SPECIALIZATION_QUESTIONS_FAILURE: false,
  GET_SPECIALIZATION_QUESTIONS: null,
  GET_SPECIALIZATION_QUESTIONS_ERROR: null,

  GET_SUGGESTED_QUESTIONS_LOADING: false,
  GET_SUGGESTED_QUESTIONS_SUCCESS: false,
  GET_SUGGESTED_QUESTIONS_FAILURE: false, 
  GET_SUGGESTED_QUESTIONS: null,
  GET_SUGGESTED_QUESTIONS_ERROR: null,

  TRANSLATE_QUESTIONS_LOADING: false,
  TRANSLATE_QUESTIONS_SUCCESS: false,
  TRANSLATE_QUESTIONS_FAILURE: false, 
  TRANSLATE_QUESTIONS: null,
  TRANSLATE_QUESTIONS_ERROR: null,
};

export function specializationQuestionsReducer(state = INITIAL_STATES, action) {
  switch (action.type) {
    // --------Save Questions----------
    case "SAVE_SPECIALIZATION_QUESTIONS_REQUEST":
      return {
        ...state,
        SAVE_SPECIALIZATION_QUESTIONS_LOADING: true,
        SAVE_SPECIALIZATION_QUESTIONS_SUCCESS: false,
        SAVE_SPECIALIZATION_QUESTIONS_FAILURE: false,
        SAVE_SPECIALIZATION_QUESTIONS_ERROR: null,
      };
    case "SAVE_SPECIALIZATION_QUESTIONS_SUCCESS":
      return {
        ...state,
        SAVE_SPECIALIZATION_QUESTIONS_LOADING: false,
        SAVE_SPECIALIZATION_QUESTIONS_SUCCESS: true,
        SAVE_SPECIALIZATION_QUESTIONS_FAILURE: false,
        SAVE_SPECIALIZATION_QUESTIONS_ERROR: null,
        SAVE_SPECIALIZATION_QUESTIONS: action.payload,
      };
    case "SAVE_SPECIALIZATION_QUESTIONS_FAILURE":
      return {
        ...state,
        SAVE_SPECIALIZATION_QUESTIONS_LODAING: false,
        SAVE_SPECIALIZATION_QUESTIONS_SUCCESS: false,
        SAVE_SPECIALIZATION_QUESTIONS_FAILURE: true,
        SAVE_SPECIALIZATION_QUESTIONS_ERROR: action.payload,
      };

    // --------Get Questions----------

    case "GET_SPECIALIZATION_QUESTIONS_REQUEST":
      return {
        ...state,
        GET_SPECIALIZATION_QUESTIONS_LOADING: true,
        GET_SPECIALIZATION_QUESTIONS_SUCCESS: false,
        GET_SPECIALIZATION_QUESTIONS_FAILURE: false,
        GET_SPECIALIZATION_QUESTIONS_ERROR: null,
      };
    case "GET_SPECIALIZATION_QUESTIONS_SUCCESS":
      return {
        ...state,
        GET_SPECIALIZATION_QUESTIONS_LOADING: false,
        GET_SPECIALIZATION_QUESTIONS_SUCCESS: true,
        GET_SPECIALIZATION_QUESTIONS_FAILURE: false,
        GET_SPECIALIZATION_QUESTIONS: action.payload,
        GET_SPECIALIZATION_QUESTIONS_ERROR: null,
      };
    case "GET_SPECIALIZATION_QUESTIONS_FAILURE":
      return {
        ...state,
        GET_SPECIALIZATION_QUESTIONS_LOADING: false,
        GET_SPECIALIZATION_QUESTIONS_SUCCESS: false,
        GET_SPECIALIZATION_QUESTIONS_FAILURE: true,
        MESSAGE: null,
        GET_SPECIALIZATION_QUESTIONS_ERROR: action.payload,
      };

    // -------Get Suggested  Questions----------

    case "GET_SUGGESTED_QUESTIONS_REQUEST":
      return {
        ...state,
        GET_SUGGESTED_QUESTIONS_LOADING: true,
        GET_SUGGESTED_QUESTIONS_SUCCESS: false,
        GET_SUGGESTED_QUESTIONS_FAILURE: false,
        GET_SUGGESTED_QUESTIONS_ERROR: null,
      };
    case "GET_SUGGESTED_QUESTIONS_SUCCESS":
      return {
        ...state,
        GET_SUGGESTED_QUESTIONS_LOADING: false,
        GET_SUGGESTED_QUESTIONS_SUCCESS: true,
        GET_SUGGESTED_QUESTIONS_FAILURE: false,
        GET_SUGGESTED_QUESTIONS: action.payload,
        GET_SUGGESTED_QUESTIONS_ERROR: null,
      };
    case "GET_SUGGESTED_QUESTIONS_FAILURE":
      return {
        ...state,
        GET_SUGGESTED_QUESTIONS_LOADING: false,
        GET_SUGGESTED_QUESTIONS_SUCCESS: false,
        GET_SUGGESTED_QUESTIONS_FAILURE: true,
        GET_SUGGESTED_QUESTIONS_ERROR: action.payload,
      };

       // -------Translate Suggested Questions----------

    case "TRANSLATE_QUESTIONS_REQUEST":
        return {
          ...state,
          TRANSLATE_QUESTIONS_LOADING: true,
          TRANSLATE_QUESTIONS_SUCCESS: false,
          TRANSLATE_QUESTIONS_FAILURE: false,
          TRANSLATE_QUESTIONS_ERROR: null,
        };
      case "TRANSLATE_QUESTIONS_SUCCESS":
        return {
          ...state,
          TRANSLATE_QUESTIONS_LOADING: false,
          TRANSLATE_QUESTIONS_SUCCESS: true,
          TRANSLATE_QUESTIONS_FAILURE: false,
          TRANSLATE_QUESTIONS: action.payload,
          TRANSLATE_QUESTIONS_ERROR: null,
        };
      case "TRANSLATE_QUESTIONS_FAILURE":
        return {
          ...state,
          TRANSLATE_QUESTIONS_LOADING: false,
          TRANSLATE_QUESTIONS_SUCCESS: false,
          TRANSLATE_QUESTIONS_FAILURE: true,
          TRANSLATE_QUESTIONS_ERROR: action.payload,
        };

    default:
      return state;
  }
}
