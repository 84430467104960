export const maleFullbodyParts = {
  "front-head": { "english": "head", "arabic": "الرأس" },
  "front-eye-rt": { "english": "right eye", "arabic": "العين اليمنى" },
  "front-eye-lt": { "english": "left eye", "arabic": "العين اليسرى" },
  "front-ear-rt": { "english": "right ear", "arabic": "الأذن اليمنى" },
  "front-ear-lt": { "english": "left ear", "arabic": "الأذن اليسرى" },
  "front-nose": { "english": "nose", "arabic": "الأنف" },
  "front-mouth": { "english": "mouth", "arabic": "الفم" },
  "front-neck": { "english": "neck", "arabic": "الرقبة" },
  "front-chest": { "english": "chest", "arabic": "الصدر" },
  "front-abdomen": { "english": "abdomen", "arabic": "البطن" },
  "front-pelvis": { "english": "pelvis", "arabic": "الحوض" },
  "front-pubis": { "english": "pubic area", "arabic": "المنطقة العانية" },
  "front-shoulder-rt": { "english": "right shoulder", "arabic": "الكتف اليمنى" },
  "front-shoulder-lt": { "english": "left shoulder", "arabic": "الكتف اليسرى" },
  "front-arm-rt": { "english": "right arm", "arabic": "الذراع اليمنى" },
  "front-arm-lt": { "english": "left arm", "arabic": "الذراع اليسرى" },
  "front-elbow-rt": { "english": "right elbow", "arabic": "المرفق الأيمن" },
  "front-elbow-lt": { "english": "left elbow", "arabic": "المرفق الأيسر" },
  "front-forearm-rt": { "english": "right forearm", "arabic": "الساعد الأيمن" },
  "front-forearm-lt": { "english": "left forearm", "arabic": "الساعد الأيسر" },
  "front-wrist-rt": { "english": "right wrist", "arabic": "المعصم الأيمن" },
  "front-wrist-lt": { "english": "left wrist", "arabic": "المعصم الأيسر" },
  "front-hand-rt": { "english": "right hand", "arabic": "اليد اليمنى" },
  "front-hand-lt": { "english": "left hand", "arabic": "اليد اليسرى" },
  "front-thigh-rt": { "english": "right thigh", "arabic": "الفخذ الأيمن" },
  "front-thigh-lt": { "english": "left thigh", "arabic": "الفخذ الأيسر" },
  "front-knee-rt": { "english": "right knee", "arabic": "الركبة اليمنى" },
  "front-knee-lt": { "english": "left knee", "arabic": "الركبة اليسرى" },
  "front-leg-rt": { "english": "right lower leg", "arabic": "الساق اليمنى" },
  "front-leg-lt": { "english": "left lower leg", "arabic": "الساق اليسرى" },
  "front-ankle-rt": { "english": "right ankle", "arabic": "الكاحل الأيمن" },
  "front-ankle-lt": { "english": "left ankle", "arabic": "الكاحل الأيسر" },
  "front-foot-rt": { "english": "right foot", "arabic": "القدم اليمنى" },
  "front-foot-lt": { "english": "left foot", "arabic": "القدم اليسرى" },
  "back-head": { "english": "back of head", "arabic": "مؤخرة الرأس" },
  "back-neck": { "english": "back of neck", "arabic": "خلف الرقبة" },
  "back-back": { "english": "upper back", "arabic": "الظهر العلوي" },
  "back-loin": { "english": "lower back", "arabic": "أسفل الظهر" },
  "back-buttocks": { "english": "buttocks", "arabic": "الأرداف" },
  "back-shoulder-rt": { "english": "back of right shoulder", "arabic": "خلف الكتف اليمنى" },
  "back-shoulder-lt": { "english": "back of left shoulder", "arabic": "خلف الكتف اليسرى" },
  "back-arm-rt": { "english": "back of right arm", "arabic": "خلف الذراع اليمنى" },
  "back-arm-lt": { "english": "back of left arm", "arabic": "خلف الذراع اليسرى" },
  "back-elbow-rt": { "english": "back of right elbow", "arabic": "خلف المرفق الأيمن" },
  "back-elbow-lt": { "english": "back of left elbow", "arabic": "خلف المرفق الأيسر" },
  "back-forearm-rt": { "english": "back of right forearm", "arabic": "خلف الساعد الأيمن" },
  "back-forearm-lt": { "english": "back of left forearm", "arabic": "خلف الساعد الأيسر" },
  "back-wrist-rt": { "english": "back of right wrist", "arabic": "خلف المعصم الأيمن" },
  "back-wrist-lt": { "english": "back of left wrist", "arabic": "خلف المعصم الأيسر" },
  "back-hand-rt": { "english": "back of right hand", "arabic": "خلف اليد اليمنى" },
  "back-hand-lt": { "english": "back of left hand", "arabic": "خلف اليد اليسرى" },
  "back-hamstring-rt": { "english": "back of right hamstring", "arabic": "خلف عضلة الفخذ اليمنى" },
  "back-hamstring-lt": { "english": "back of left hamstring", "arabic": "خلف عضلة الفخذ اليسرى" },
  "back-knee-rt": { "english": "back of right knee", "arabic": "خلف الركبة اليمنى" },
  "back-knee-lt": { "english": "back of left knee", "arabic": "خلف الركبة اليسرى" },
  "back-calf-rt": { "english": "back of right calf", "arabic": "خلف الساق اليمنى" },
  "back-calf-lt": { "english": "back of left calf", "arabic": "خلف الساق اليسرى" },
  "back-ankle-rt": { "english": "back of right ankle", "arabic": "خلف الكاحل الأيمن" },
  "back-ankle-lt": { "english": "back of left ankle", "arabic": "خلف الكاحل الأيسر" },
  "back-sole-rt": { "english": "sole of right foot", "arabic": "باطن القدم اليمنى" },
  "back-sole-lt": { "english": "sole of left foot", "arabic": "باطن القدم اليسرى" },
  "back-foot-rt": { "english": "back of right foot", "arabic": "خلف القدم اليمنى" },
  "back-foot-lt": { "english": "back of left foot", "arabic": "خلف القدم اليسرى" }
};

export const femaleFullbodyParts = {
  "front-head": { "english": "head", "arabic": "الرأس" },
  "front-eye-rt": { "english": "right eye", "arabic": "العين اليمنى" },
  "front-eye-lt": { "english": "left eye", "arabic": "العين اليسرى" },
  "front-ear-rt": { "english": "right ear", "arabic": "الأذن اليمنى" },
  "front-ear-lt": { "english": "left ear", "arabic": "الأذن اليسرى" },
  "front-nose": { "english": "nose", "arabic": "الأنف" },
  "front-mouth": { "english": "mouth", "arabic": "الفم" },
  "front-neck": { "english": "neck", "arabic": "الرقبة" },
  "front-chest": { "english": "chest", "arabic": "الصدر" },
  "front-breasts": { "english": "breasts", "arabic": "الثديين" },
  "front-abdomen": { "english": "abdomen", "arabic": "البطن" },
  "front-pelvis": { "english": "pelvis", "arabic": "الحوض" },
  "front-pubis": { "english": "pubic area", "arabic": "المنطقة العانية" },
  "front-shoulder-rt": { "english": "right shoulder", "arabic": "الكتف اليمنى" },
  "front-shoulder-lt": { "english": "left shoulder", "arabic": "الكتف اليسرى" },
  "front-arm-rt": { "english": "right arm", "arabic": "الذراع اليمنى" },
  "front-arm-lt": { "english": "left arm", "arabic": "الذراع اليسرى" },
  "front-elbow-rt": { "english": "right elbow", "arabic": "المرفق الأيمن" },
  "front-elbow-lt": { "english": "left elbow", "arabic": "المرفق الأيسر" },
  "front-forearm-rt": { "english": "right forearm", "arabic": "الساعد الأيمن" },
  "front-forearm-lt": { "english": "left forearm", "arabic": "الساعد الأيسر" },
  "front-wrist-rt": { "english": "right wrist", "arabic": "المعصم الأيمن" },
  "front-wrist-lt": { "english": "left wrist", "arabic": "المعصم الأيسر" },
  "front-hand-rt": { "english": "right hand", "arabic": "اليد اليمنى" },
  "front-hand-lt": { "english": "left hand", "arabic": "اليد اليسرى" },
  "front-thigh-rt": { "english": "right thigh", "arabic": "الفخذ الأيمن" },
  "front-thigh-lt": { "english": "left thigh", "arabic": "الفخذ الأيسر" },
  "front-knee-rt": { "english": "right knee", "arabic": "الركبة اليمنى" },
  "front-knee-lt": { "english": "left knee", "arabic": "الركبة اليسرى" },
  "front-leg-rt": { "english": "right lower leg", "arabic": "الساق اليمنى" },
  "front-leg-lt": { "english": "left lower leg", "arabic": "الساق اليسرى" },
  "front-ankle-rt": { "english": "right ankle", "arabic": "الكاحل الأيمن" },
  "front-ankle-lt": { "english": "left ankle", "arabic": "الكاحل الأيسر" },
  "front-foot-rt": { "english": "right foot", "arabic": "القدم اليمنى" },
  "front-foot-lt": { "english": "left foot", "arabic": "القدم اليسرى" },
  "back-head": { "english": "back of head", "arabic": "مؤخرة الرأس" },
  "back-neck": { "english": "back of neck", "arabic": "خلف الرقبة" },
  "back-back": { "english": "upper back", "arabic": "الظهر العلوي" },
  "back-loin": { "english": "lower back", "arabic": "أسفل الظهر" },
  "back-buttocks": { "english": "buttocks", "arabic": "الأرداف" },
  "back-shoulder-rt": { "english": "back of right shoulder", "arabic": "خلف الكتف اليمنى" },
  "back-shoulder-lt": { "english": "back of left shoulder", "arabic": "خلف الكتف اليسرى" },
  "back-arm-rt": { "english": "back of right arm", "arabic": "خلف الذراع اليمنى" },
  "back-arm-lt": { "english": "back of left arm", "arabic": "خلف الذراع اليسرى" },
  "back-elbow-rt": { "english": "back of right elbow", "arabic": "خلف المرفق الأيمن" },
  "back-elbow-lt": { "english": "back of left elbow", "arabic": "خلف المرفق الأيسر" },
  "back-forearm-rt": { "english": "back of right forearm", "arabic": "خلف الساعد الأيمن" },
  "back-forearm-lt": { "english": "back of left forearm", "arabic": "خلف الساعد الأيسر" },
  "back-wrist-rt": { "english": "back of right wrist", "arabic": "خلف المعصم الأيمن" },
  "back-wrist-lt": { "english": "back of left wrist", "arabic": "خلف المعصم الأيسر" },
  "back-hand-rt": { "english": "back of right hand", "arabic": "خلف اليد اليمنى" },
  "back-hand-lt": { "english": "back of left hand", "arabic": "خلف اليد اليسرى" },
  "back-hamstring-rt": { "english": "back of right hamstring", "arabic": "خلف عضلة الفخذ اليمنى" },
  "back-hamstring-lt": { "english": "back of left hamstring", "arabic": "خلف عضلة الفخذ اليسرى" },
  "back-knee-rt": { "english": "back of right knee", "arabic": "خلف الركبة اليمنى" },
  "back-knee-lt": { "english": "back of left knee", "arabic": "خلف الركبة اليسرى" },
  "back-calf-rt": { "english": "back of right calf", "arabic": "خلف الساق اليمنى" },
  "back-calf-lt": { "english": "back of left calf", "arabic": "خلف الساق اليسرى" },
  "back-ankle-rt": { "english": "back of right ankle", "arabic": "خلف الكاحل الأيمن" },
  "back-ankle-lt": { "english": "back of left ankle", "arabic": "خلف الكاحل الأيسر" },
  "back-sole-rt": { "english": "sole of right foot", "arabic": "باطن القدم اليمنى" },
  "back-sole-lt": { "english": "sole of left foot", "arabic": "باطن القدم اليسرى" },
  "back-foot-rt": { "english": "back of right foot", "arabic": "خلف القدم اليمنى" },
  "back-foot-lt": { "english": "back of left foot", "arabic": "خلف القدم اليسرى" }
};

export const maleFullbodyParts2 = {
  "front-head": "head",
  "front-eye-rt": "right eye",
  "front-eye-lt": "left eye",
  "front-ear-rt": "right ear",
  "front-ear-lt": "left ear",
  "front-nose": "nose",
  "front-mouth": "mouth",
  "front-neck": "neck",
  "front-chest": "chest",
  "front-abdomen": "abdomen",
  "front-pelvis": "pelvis",
  "front-pubis": "pubic area",
  "front-shoulder-rt": "right shoulder",
  "front-shoulder-lt": "left shoulder",
  "front-arm-rt": "right arm",
  "front-arm-lt": "left arm",
  "front-elbow-rt": "right elbow",
  "front-elbow-lt": "left elbow",
  "front-forearm-rt": "right forearm",
  "front-forearm-lt": "left forearm",
  "front-wrist-rt": "right wrist",
  "front-wrist-lt": "left wrist",
  "front-hand-rt": "right hand",
  "front-hand-lt": "left hand",
  "front-thigh-rt": "right thigh",
  "front-thigh-lt": "left thigh",
  "front-knee-rt": "right knee",
  "front-knee-lt": "left knee",
  "front-leg-rt": "right lower leg",
  "front-leg-lt": "left lower leg",
  "front-ankle-rt": "right ankle",
  "front-ankle-lt": "left ankle",
  "front-foot-rt": "right foot",
  "front-foot-lt": "left foot",
  "back-head": "back of head",
  "back-neck": "back of neck",
  "back-back": "upper back",
  "back-loin": "lower back",
  "back-buttocks": "buttocks",
  "back-shoulder-rt": "back of right shoulder",
  "back-shoulder-lt": "back of left shoulder",
  "back-arm-rt": "back of right arm",
  "back-arm-lt": "back of left arm",
  "back-elbow-rt": "back of right elbow",
  "back-elbow-lt": "back of left elbow",
  "back-forearm-rt": "back of right forearm",
  "back-forearm-lt": "back of left forearm",
  "back-wrist-rt": "back of right wrist",
  "back-wrist-lt": "back of left wrist",
  "back-hand-rt": "back of right hand",
  "back-hand-lt": "back of left hand",
  "back-hamstring-rt": "back of right hamstring",
  "back-hamstring-lt": "back of left hamstring",
  "back-knee-rt": "back of right knee",
  "back-knee-lt": "back of left knee",
  "back-calf-rt": "back of right calf",
  "back-calf-lt": "back of left calf",
  "back-ankle-rt": "back of right ankle",
  "back-ankle-lt": "back of left ankle",
  "back-sole-rt": "sole of right foot",
  "back-sole-lt": "sole of left foot",
  "back-foot-rt": "back of right foot",
  "back-foot-lt": "back of left foot",
};

export const femaleFullbodyParts2 = {
  "front-head": "head",
  "front-eye-rt": "right eye",
  "front-eye-lt": "left eye",
  "front-ear-rt": "right ear",
  "front-ear-lt": "left ear",
  "front-nose": "nose",
  "front-mouth": "mouth",
  "front-neck": "neck",
  "front-chest": "chest",
  "front-breasts": "breasts",
  "front-abdomen": "abdomen",
  "front-pelvis": "pelvis",
  "front-pubis": "pubic area",
  "front-shoulder-rt": "right shoulder",
  "front-shoulder-lt": "left shoulder",
  "front-arm-rt": "right arm",
  "front-arm-lt": "left arm",
  "front-elbow-rt": "right elbow",
  "front-elbow-lt": "left elbow",
  "front-forearm-rt": "right forearm",
  "front-forearm-lt": "left forearm",
  "front-wrist-rt": "right wrist",
  "front-wrist-lt": "left wrist",
  "front-hand-rt": "right hand",
  "front-hand-lt": "left hand",
  "front-thigh-rt": "right thigh",
  "front-thigh-lt": "left thigh",
  "front-knee-rt": "right knee",
  "front-knee-lt": "left knee",
  "front-leg-rt": "right lower leg",
  "front-leg-lt": "left lower leg",
  "front-ankle-rt": "right ankle",
  "front-ankle-lt": "left ankle",
  "front-foot-rt": "right foot",
  "front-foot-lt": "left foot",
  "back-head": "back of head",
  "back-neck": "back of neck",
  "back-back": "upper back",
  "back-loin": "lower back",
  "back-buttocks": "buttocks",
  "back-shoulder-rt": "back of right shoulder",
  "back-shoulder-lt": "back of left shoulder",
  "back-arm-rt": "back of right arm",
  "back-arm-lt": "back of left arm",
  "back-elbow-rt": "back of right elbow",
  "back-elbow-lt": "back of left elbow",
  "back-forearm-rt": "back of right forearm",
  "back-forearm-lt": "back of left forearm",
  "back-wrist-rt": "back of right wrist",
  "back-wrist-lt": "back of left wrist",
  "back-hand-rt": "back of right hand",
  "back-hand-lt": "back of left hand",
  "back-hamstring-rt": "back of right hamstring",
  "back-hamstring-lt": "back of left hamstring",
  "back-knee-rt": "back of right knee",
  "back-knee-lt": "back of left knee",
  "back-calf-rt": "back of right calf",
  "back-calf-lt": "back of left calf",
  "back-ankle-rt": "back of right ankle",
  "back-ankle-lt": "back of left ankle",
  "back-sole-rt": "sole of right foot",
  "back-sole-lt": "sole of left foot",
  "back-foot-rt": "back of right foot",
  "back-foot-lt": "back of left foot"
};


const maleFullbodyParts1 = {
  "front-head": "",
  "front-eye-rt": "",
  "front-eye-lt": "",
  "front-ear-rt": "",
  "front-ear-lt": "",
  "front-nose": "",
  "front-mouth": "",
  "front-neck": "",
  "front-chest": "",
  "front-abdomen": "",
  "front-pelvis": "",
  "front-pubis": "",
  "front-shoulder-rt": "",
  "front-shoulder-lt": "",
  "front-arm-rt": "",
  "front-arm-lt": "",
  "front-elbow-rt": "",
  "front-elbow-lt": "",
  "front-forearm-rt": "",
  "front-forearm-lt": "",
  "front-wrist-rt": "",
  "front-wrist-lt": "",
  "front-hand-rt": "",
  "front-hand-lt": "",
  "front-thigh-rt": "",
  "front-thigh-lt": "",
  "front-knee-rt": "",
  "front-knee-lt": "",
  "front-leg-rt": "",
  "front-leg-lt": "",
  "front-ankle-rt": "",
  "front-ankle-lt": "",
  "front-foot-rt": "",
  "front-foot-lt": "",
  "back-head": "",
  "back-neck": "",
  "back-back": "",
  "back-loin": "",
  "back-buttocks": "",
  "back-shoulder-rt": "",
  "back-shoulder-lt": "",
  "back-arm-rt": "",
  "back-arm-lt": "",
  "back-elbow-rt": "",
  "back-elbow-lt": "",
  "back-forearm-rt": "",
  "back-forearm-lt": "",
  "back-wrist-rt": "",
  "back-wrist-lt": "",
  "back-hand-rt": "",
  "back-hand-lt": "",
  "back-hamstring-rt": "",
  "back-hamstring-lt": "",
  "back-knee-rt": "",
  "back-knee-lt": "",
  "back-calf-rt": "",
  "back-calf-lt": "",
  "back-ankle-rt": "",
  "back-ankle-lt": "",
  "back-sole-rt": "",
  "back-sole-lt": "",
  "back-foot-rt": "",
  "back-foot-lt": "",
};

const femaleFullbodyParts1 = {
  "front-head": "",
  "front-eye-rt": "",
  "front-eye-lt": "",
  "front-ear-rt": "",
  "front-ear-lt": "",
  "front-nose": "",
  "front-mouth": "",
  "front-neck": "",
  "front-chest": "",
  "front-breasts": "",
  "front-abdomen": "",
  "front-pelvis": "",
  "front-pubis": "",
  "front-shoulder-rt": "",
  "front-shoulder-lt": "",
  "front-arm-rt": "",
  "front-arm-lt": "",
  "front-elbow-rt": "",
  "front-elbow-lt": "",
  "front-forearm-rt": "",
  "front-forearm-lt": "",
  "front-wrist-rt": "",
  "front-wrist-lt": "",
  "front-hand-rt": "",
  "front-hand-lt": "",
  "front-thigh-rt": "",
  "front-thigh-lt": "",
  "front-knee-rt": "",
  "front-knee-lt": "",
  "front-leg-rt": "",
  "front-leg-lt": "",
  "front-ankle-rt": "",
  "front-ankle-lt": "",
  "front-foot-rt": "",
  "front-foot-lt": "",
  "back-head": "",
  "back-neck": "",
  "back-back": "",
  "back-loin": "",
  "back-buttocks": "",
  "back-shoulder-rt": "",
  "back-shoulder-lt": "",
  "back-arm-rt": "",
  "back-arm-lt": "",
  "back-elbow-rt": "",
  "back-elbow-lt": "",
  "back-forearm-rt": "",
  "back-forearm-lt": "",
  "back-wrist-rt": "",
  "back-wrist-lt": "",
  "back-hand-rt": "",
  "back-hand-lt": "",
  "back-hamstring-rt": "",
  "back-hamstring-lt": "",
  "back-knee-rt": "",
  "back-knee-lt": "",
  "back-calf-rt": "",
  "back-calf-lt": "",
  "back-ankle-rt": "",
  "back-ankle-lt": "",
  "back-sole-rt": "",
  "back-sole-lt": "",
  "back-foot-rt": "",
  "back-foot-lt": "",
};
