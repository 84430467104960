/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, GenericLoader, Typography } from "../../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { getAllLookupList } from "../../../../Redux/actions";

const PhysicalActivity = ({ values, setFieldValue }) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLookupList("WeeklyHabit"));
  }, []);

  const data = useSelector((state) => state.patientProfile);

  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#E30020">
        LIFE PATTERNS & HABITS
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Weekly Physical Activity Level
      </Typography>
      {data?.GET_LOOKUP_LIST_LOADING ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <GenericLoader />
        </div>
      ) : (
        data?.GET_LOOKUP_LIST?.map((option, idx) => (
          <>
            <Box
              className="p-3 my-3 cursor-pointer option-wrapper"
              onClick={() => setFieldValue("weeklyHabit", option?.lookupId)}
              height="100%"
              width="100%"
              borderRadius="0.5rem"
              background={values?.weeklyHabit === option?.lookupId && "#E30020"}
              border={
                values?.weeklyHabit === option?.lookupId && "1px solid #E30020"
              }
            >
              <Typography
                fontSize=".9rem"
                fontWeight={values?.weeklyHabit === option?.lookupId && "500"}
                className="heading"
                color={values?.weeklyHabit === option?.lookupId && "white"}
              >
                {option?.text}
              </Typography>
              <span style={{ fontSize: "12px" }}>{option?.description}</span>
            </Box>
          </>
        ))
      )}
    </div>
  );
};

export default PhysicalActivity;
