export const musclesbodyParts1 = {
    "sternocleidomastoid-rt-front": "right sternocleidomastoid",
    "sternocleidomastoid-lt-front": "left sternocleidomastoid",
    "trapezius-rt-front": "right trapezius",
    "trapezius-lt-front": "left trapezius",
    "deltoid-rt-front": "right deltoid",
    "deltoid-lt-front": "left deltoid",
    "pectoralis-major-rt-front": "right pectoralis major",
    "pectoralis-major-lt-front": "left pectoralis major",
    "rectus-abdominis-front": "rectus abdominis",
    "serratus-anterior-rt-front": "right serratus anterior",
    "serratus-anterior-lt-front": "left serratus anterior",
    "abdominal-external-oblique-rt-front": "right external oblique",
    "abdominal-external-oblique-lt-front": "left external oblique",
    "biceps-brachii-rt-front": "right biceps brachii",
    "biceps-brachii-lt-front": "left biceps brachii",
    "brachioradialis-rt-front": "right brachioradialis",
    "brachioradialis-lt-front": "left brachioradialis",
    "flexor-carpi-radialis-rt-front": "right flexor carpi radialis",
    "flexor-carpi-radialis-lt-front": "left flexor carpi radialis",
    "palmaris-longus-rt-front": "right palmaris longus",
    "palmaris-longus-lt-front": "left palmaris longus",
    "gluteus-medius-rt-front": "right gluteus medius",
    "gluteus-medius-lt-front": "left gluteus medius",
    "vastus-lateralis-rt-front": "right vastus lateralis",
    "vastus-lateralis-lt-front": "left vastus lateralis",
    "rectus-femoris-rt-front": "right rectus femoris",
    "rectus-femoris-lt-front": "left rectus femoris",
    "sartorius-rt-front": "right sartorius",
    "sartorius-lt-front": "left sartorius",
    "pectineus-rt-front": "right pectineus",
    "pectineus-lt-front": "left pectineus",
    "adductor-longus-rt-front": "right adductor longus",
    "adductor-longus-lt-front": "left adductor longus",
    "vastus-medialis-rt-front": "right vastus medialis",
    "vastus-medialis-lt-front": "left vastus medialis",
    "gracilis-rt-front": "right gracilis",
    "gracilis-lt-front": "left gracilis",
    "gastrocnemius-rt-front": "right gastrocnemius",
    "gastrocnemius-lt-front": "left gastrocnemius",
    "soleus-rt-front": "right soleus",
    "soleus-lt-front": "left soleus",
    "tibialis-anterior-rt-front": "right tibialis anterior",
    "tibialis-anterior-lt-front": "left tibialis anterior",
    "peroneus-longus-rt-front": "right peroneus longus",
    "peroneus-longus-lt-front": "left peroneus longus",
    "trapezius-back": "trapezius",
    "infraspinatus-rt-back": "right infraspinatus",
    "infraspinatus-lt-back": "left infraspinatus",
    "teres-major-rt-back": "right teres major",
    "teres-major-lt-back": "left teres major",
    "latissimus-dorsi-rt-back": "right latissimus dorsi",
    "latissimus-dorsi-lt-back": "left latissimus dorsi",
    "deltoid-rt-back": "right deltoid",
    "deltoid-lt-back": "left deltoid",
    "triceps-brachii-rt-back": "right triceps brachii",
    "triceps-brachii-lt-back": "left triceps brachii",
    "extensor-carpi-ulnaris-rt-back": "right extensor carpi ulnaris",
    "extensor-carpi-ulnaris-lt-back": "left extensor carpi ulnaris",
    "flexor-carpi-ulnaris-rt-back": "right flexor carpi ulnaris",
    "flexor-carpi-ulnaris-lt-back": "left flexor carpi ulnaris",
    "gluteus-medius-rt-back": "right gluteus medius",
    "gluteus-medius-lt-back": "left gluteus medius",
    "gluteus-maximus-rt-back": "right gluteus maximus",
    "gluteus-maximus-lt-back": "left gluteus maximus",
    "vastus-lateralis-rt-back": "right vastus lateralis",
    "vastus-lateralis-lt-back": "left vastus lateralis",
    "biceps-femoris-rt-back": "right biceps femoris",
    "biceps-femoris-lt-back": "left biceps femoris",
    "semitendinosus-rt-back": "right semitendinosus",
    "semitendinosus-lt-back": "left semitendinosus",
    "semimembranosus-rt-back": "right semimembranosus",
    "semimembranosus-lt-back": "left semimembranosus",
    "adductor-magnus-rt-back": "right adductor magnus",
    "adductor-magnus-lt-back": "left adductor magnus",
    "gastrocnemius-rt-back": "right gastrocnemius",
    "gastrocnemius-lt-back": "left gastrocnemius"
};

export const musclesbodyParts = {
    "sternocleidomastoid-rt-front": { "english": "right sternocleidomastoid", "arabic": "عضلة القصية الترقوية الخشائية اليمنى" },
    "sternocleidomastoid-lt-front": { "english": "left sternocleidomastoid", "arabic": "عضلة القصية الترقوية الخشائية اليسرى" },
    "trapezius-rt-front": { "english": "right trapezius", "arabic": "عضلة شبه المنحرف اليمنى" },
    "trapezius-lt-front": { "english": "left trapezius", "arabic": "عضلة شبه المنحرف اليسرى" },
    "deltoid-rt-front": { "english": "right deltoid", "arabic": "عضلة الدالية اليمنى" },
    "deltoid-lt-front": { "english": "left deltoid", "arabic": "عضلة الدالية اليسرى" },
    "pectoralis-major-rt-front": { "english": "right pectoralis major", "arabic": "عضلة الصدر الكبرى اليمنى" },
    "pectoralis-major-lt-front": { "english": "left pectoralis major", "arabic": "عضلة الصدر الكبرى اليسرى" },
    "rectus-abdominis-front": { "english": "rectus abdominis", "arabic": "عضلة البطن المستقيمة" },
    "serratus-anterior-rt-front": { "english": "right serratus anterior", "arabic": "عضلة المنشاري الأمامي اليمنى" },
    "serratus-anterior-lt-front": { "english": "left serratus anterior", "arabic": "عضلة المنشاري الأمامي اليسرى" },
    "abdominal-external-oblique-rt-front": { "english": "right external oblique", "arabic": "عضلة البطن المائلة الخارجية اليمنى" },
    "abdominal-external-oblique-lt-front": { "english": "left external oblique", "arabic": "عضلة البطن المائلة الخارجية اليسرى" },
    "biceps-brachii-rt-front": { "english": "right biceps brachii", "arabic": "عضلة ذات الرأسين العضدية اليمنى" },
    "biceps-brachii-lt-front": { "english": "left biceps brachii", "arabic": "عضلة ذات الرأسين العضدية اليسرى" },
    "brachioradialis-rt-front": { "english": "right brachioradialis", "arabic": "عضلة العضد والزند اليمنى" },
    "brachioradialis-lt-front": { "english": "left brachioradialis", "arabic": "عضلة العضد والزند اليسرى" },
    "flexor-carpi-radialis-rt-front": { "english": "right flexor carpi radialis", "arabic": "عضلة مثنية الرسغ الشعاعية اليمنى" },
    "flexor-carpi-radialis-lt-front": { "english": "left flexor carpi radialis", "arabic": "عضلة مثنية الرسغ الشعاعية اليسرى" },
    "palmaris-longus-rt-front": { "english": "right palmaris longus", "arabic": "عضلة راحية طويلة اليمنى" },
    "palmaris-longus-lt-front": { "english": "left palmaris longus", "arabic": "عضلة راحية طويلة اليسرى" },
    "gluteus-medius-rt-front": { "english": "right gluteus medius", "arabic": "عضلة الألوية المتوسطة اليمنى" },
    "gluteus-medius-lt-front": { "english": "left gluteus medius", "arabic": "عضلة الألوية المتوسطة اليسرى" },
    "vastus-lateralis-rt-front": { "english": "right vastus lateralis", "arabic": "عضلة الفخذ الجانبية اليمنى" },
    "vastus-lateralis-lt-front": { "english": "left vastus lateralis", "arabic": "عضلة الفخذ الجانبية اليسرى" },
    "rectus-femoris-rt-front": { "english": "right rectus femoris", "arabic": "عضلة الفخذ المستقيمة اليمنى" },
    "rectus-femoris-lt-front": { "english": "left rectus femoris", "arabic": "عضلة الفخذ المستقيمة اليسرى" },
    "sartorius-rt-front": { "english": "right sartorius", "arabic": "عضلة الخياطة اليمنى" },
    "sartorius-lt-front": { "english": "left sartorius", "arabic": "عضلة الخياطة اليسرى" },
    "pectineus-rt-front": { "english": "right pectineus", "arabic": "عضلة العانة اليمنى" },
    "pectineus-lt-front": { "english": "left pectineus", "arabic": "عضلة العانة اليسرى" },
    "adductor-longus-rt-front": { "english": "right adductor longus", "arabic": "عضلة الضامة الطويلة اليمنى" },
    "adductor-longus-lt-front": { "english": "left adductor longus", "arabic": "عضلة الضامة الطويلة اليسرى" },
    "vastus-medialis-rt-front": { "english": "right vastus medialis", "arabic": "عضلة الفخذ الوسطى اليمنى" },
    "vastus-medialis-lt-front": { "english": "left vastus medialis", "arabic": "عضلة الفخذ الوسطى اليسرى" },
    "gracilis-rt-front": { "english": "right gracilis", "arabic": "عضلة الرشيقة اليمنى" },
    "gracilis-lt-front": { "english": "left gracilis", "arabic": "عضلة الرشيقة اليسرى" },
    "gastrocnemius-rt-front": { "english": "right gastrocnemius", "arabic": "عضلة الساق اليمنى" },
    "gastrocnemius-lt-front": { "english": "left gastrocnemius", "arabic": "عضلة الساق اليسرى" },
    "soleus-rt-front": { "english": "right soleus", "arabic": "عضلة الساق السفلية اليمنى" },
    "soleus-lt-front": { "english": "left soleus", "arabic": "عضلة الساق السفلية اليسرى" },
    "tibialis-anterior-rt-front": { "english": "right tibialis anterior", "arabic": "عضلة الظنبوب الأمامية اليمنى" },
    "tibialis-anterior-lt-front": { "english": "left tibialis anterior", "arabic": "عضلة الظنبوب الأمامية اليسرى" },
    "peroneus-longus-rt-front": { "english": "right peroneus longus", "arabic": "عضلة الشظوية الطويلة اليمنى" },
    "peroneus-longus-lt-front": { "english": "left peroneus longus", "arabic": "عضلة الشظوية الطويلة اليسرى" },
    "trapezius-back": { "english": "trapezius", "arabic": "عضلة شبه المنحرف" },
    "infraspinatus-rt-back": { "english": "right infraspinatus", "arabic": "عضلة تحت الشوكة اليمنى" },
    "infraspinatus-lt-back": { "english": "left infraspinatus", "arabic": "عضلة تحت الشوكة اليسرى" },
    "teres-major-rt-back": { "english": "right teres major", "arabic": "عضلة المدورة الكبرى اليمنى" },
    "teres-major-lt-back": { "english": "left teres major", "arabic": "عضلة المدورة الكبرى اليسرى" },
    "latissimus-dorsi-rt-back": { "english": "right latissimus dorsi", "arabic": "عضلة الظهر العريضة اليمنى" },
    "latissimus-dorsi-lt-back": { "english": "left latissimus dorsi", "arabic": "عضلة الظهر العريضة اليسرى" },
    "deltoid-rt-back": { "english": "right deltoid", "arabic": "عضلة الدالية اليمنى" },
    "deltoid-lt-back": { "english": "left deltoid", "arabic": "عضلة الدالية اليسرى" },
    "triceps-brachii-rt-back": { "english": "right triceps brachii", "arabic": "عضلة ثلاثية الرؤوس اليمنى" },
    "triceps-brachii-lt-back": { "english": "left triceps brachii", "arabic": "عضلة ثلاثية الرؤوس اليسرى" },
    "extensor-carpi-ulnaris-rt-back": { "english": "right extensor carpi ulnaris", "arabic": "عضلة باسطة الرسغ الزندية اليمنى" },
    "extensor-carpi-ulnaris-lt-back": { "english": "left extensor carpi ulnaris", "arabic": "عضلة باسطة الرسغ الزندية اليسرى" },
    "flexor-carpi-ulnaris-rt-back": { "english": "right flexor carpi ulnaris", "arabic": "عضلة مثنية الرسغ الزندية اليمنى" },
    "flexor-carpi-ulnaris-lt-back": { "english": "left flexor carpi ulnaris", "arabic": "عضلة مثنية الرسغ الزندية اليسرى" },
    "gluteus-medius-rt-back": { "english": "right gluteus medius", "arabic": "عضلة الألوية المتوسطة اليمنى" },
    "gluteus-medius-lt-back": { "english": "left gluteus medius", "arabic": "عضلة الألوية المتوسطة اليسرى" },
    "gluteus-maximus-rt-back": { "english": "right gluteus maximus", "arabic": "عضلة الألوية العظمى اليمنى" },
    "gluteus-maximus-lt-back": { "english": "left gluteus maximus", "arabic": "عضلة الألوية العظمى اليسرى" },
    "vastus-lateralis-rt-back": { "english": "right vastus lateralis", "arabic": "عضلة الفخذ الجانبية اليمنى" },
    "vastus-lateralis-lt-back": { "english": "left vastus lateralis", "arabic": "عضلة الفخذ الجانبية اليسرى" },
    "biceps-femoris-rt-back": { "english": "right biceps femoris", "arabic": "عضلة الفخذ ذات الرأسين اليمنى" },
    "biceps-femoris-lt-back": { "english": "left biceps femoris", "arabic": "عضلة الفخذ ذات الرأسين اليسرى" },
    "semitendinosus-rt-back": { "english": "right semitendinosus", "arabic": "عضلة نصف وترية اليمنى" },
    "semitendinosus-lt-back": { "english": "left semitendinosus", "arabic": "عضلة نصف وترية اليسرى" },
    "semimembranosus-rt-back": { "english": "right semimembranosus", "arabic": "عضلة نصف غشائية اليمنى" },
    "semimembranosus-lt-back": { "english": "left semimembranosus", "arabic": "عضلة نصف غشائية اليسرى" },
    "adductor-magnus-rt-back": { "english": "right adductor magnus", "arabic": "عضلة الضامة الكبرى اليمنى" },
    "adductor-magnus-lt-back": { "english": "left adductor magnus", "arabic": "عضلة الضامة الكبرى اليسرى" },
    "gastrocnemius-rt-back": { "english": "right gastrocnemius", "arabic": "عضلة الساق اليمنى" },
    "gastrocnemius-lt-back": { "english": "left gastrocnemius", "arabic": "عضلة الساق اليسرى" }
};


const musclesList={
     "sternocleidomastoid-rt-front": "",
     "sternocleidomastoid-lt-front": "",
     "trapezius-rt-front": "",
     "trapezius-lt-front": "",
     "deltoid-rt-front": "",
     "deltoid-lt-front": "",
     "pectoralis-major-rt-front": "",
     "pectoralis-major-lt-front": "",
     "rectus-abdominis-front": "",
     "serratus-anterior-rt-front": "",
     "serratus-anterior-lt-front": "",
     "abdominal-external-oblique-rt-front": "",
     "abdominal-external-oblique-lt-front": "",
     "biceps-brachii-rt-front": "",
     "biceps-brachii-lt-front": "",
     "brachioradialis-rt-front": "",
     "brachioradialis-lt-front": "",
     "flexor-carpi-radialis-rt-front": "",
     "flexor-carpi-radialis-lt-front": "",
     "palmaris-longus-rt-front": "",
     "palmaris-longus-lt-front": "",
     "gluteus-medius-rt-front": "",
     "gluteus-medius-lt-front": "",
     "vastus-lateralis-rt-front": "",
     "vastus-lateralis-lt-front": "",
     "rectus-femoris-rt-front": "",
     "rectus-femoris-lt-front": "",
     "sartorius-rt-front": "",
     "sartorius-lt-front": "",
     "pectineus-rt-front": "",
     "pectineus-lt-front": "",
     "adductor-longus-rt-front": "",
     "adductor-longus-lt-front": "",
     "vastus-medialis-rt-front": "",
     "vastus-medialis-lt-front": "",
     "gracilis-rt-front": "",
     "gracilis-lt-front": "",
     "gastrocnemius-rt-front": "",
     "gastrocnemius-lt-front": "",
     "soleus-rt-front": "",
     "soleus-lt-front": "",
     "tibialis-anterior-rt-front": "",
     "tibialis-anterior-lt-front": "",
     "peroneus-longus-rt-front": "",
     "peroneus-longus-lt-front": "",
     "trapezius-back": "",
     "infraspinatus-rt-back": "",
     "infraspinatus-lt-back": "",
     "teres-major-rt-back": "",
     "teres-major-lt-back": "",
     "latissimus-dorsi-rt-back": "",
     "latissimus-dorsi-lt-back": "",
     "deltoid-rt-back": "",
     "deltoid-lt-back": "",
     "triceps-brachii-rt-back": "",
     "triceps-brachii-lt-back": "",
     "extensor-carpi-ulnaris-rt-back": "",
     "extensor-carpi-ulnaris-lt-back": "",
     "flexor-carpi-ulnaris-rt-back": "",
     "flexor-carpi-ulnaris-lt-back": "",
     "gluteus-medius-rt-back": "",
     "gluteus-medius-lt-back": "",
     "gluteus-maximus-rt-back": "",
     "gluteus-maximus-lt-back": "",
     "vastus-lateralis-rt-back": "",
     "vastus-lateralis-lt-back": "",
     "biceps-femoris-rt-back": "",
     "biceps-femoris-lt-back": "",
     "semitendinosus-rt-back": "",
     "semitendinosus-lt-back": "",
     "semimembranosus-rt-back": "",
     "semimembranosus-lt-back": "",
     "adductor-magnus-rt-back": "",
     "adductor-magnus-lt-back": "",
     "gastrocnemius-rt-back": "",
     "gastrocnemius-lt-back": "",
}