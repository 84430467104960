import { Box, Typography } from "../../../../Components";
import { Form } from "react-bootstrap";

const Chronic = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const options = [
    {
      id: 1,
      label: "No",
    },
    {
      id: 2,
      label: "Yes",
    },
  ];

  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#E30020">
        MEDICAL HISTORY
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Chronic And Past Health Conditions
      </Typography>
      <Typography fontSize="0.8rem" fontWeight="300">
        Include any chronic conditions or medical issues experienced. Essential
        for understanding health history and personalized care.
      </Typography>
      {options?.map((option, idx) => (
        <>
          <Box
            className="p-3 my-3 cursor-pointer option-wrapper"
            onClick={() => {
              setFieldValue("isChronic", option?.id);
            }}
            height="100%"
            width="100%"
            borderRadius="0.5rem"
            background={values?.isChronic === option?.id && "#E30020"}
            border={values?.isChronic === option?.id && "1px solid #E30020"}
          >
            <Typography
              fontSize=".9rem"
              fontWeight={values?.isChronic === option?.id && "500"}
              className="heading"
              color={values?.isChronic === option?.id && "white"}
            >
              {option?.label}
            </Typography>
          </Box>
          {values?.isChronic === 2 && option?.id === 2 && (
            <>
              <Form.Control
                type="text"
                backgroundColor="#EBEBFF"
                name="medicalHistory"
                placeholder={
                  "e.g.; High Blood Pressure, Heart Attack 2 years ago"
                }
                className="w-100 fs-6"
                value={values?.medicalHistory}
                onChange={(e) =>
                  setFieldValue("medicalHistory", e.target.value)
                }
                onBlur={handleBlur}
                isInvalid={touched.medicalHistory && !!errors.medicalHistory}
                size="lg"
                autoFocus
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.medicalHistory}
              </Form.Control.Feedback>
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default Chronic;
