/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-var */
/* eslint-disable import/no-duplicates */
import jQuery from "jquery";

var hms_config = {
  /*==============================================================================*/
  /*==============================================================================*/
  /*================ !!! CUSTOMIZATION OF THE FIRST SIDE !!!  ====================*/
  /*==============================================================================*/
  /*==============================================================================*/

  hms_1: {
    //sternocleidomastoid-rt-front
    hover: "STERNOCLEIDOMASTOID [RT]", //info of the popup
   // url: "https://www.humananatomyillustrations.com/", //link to any webpage
   // target: "_self", // use "_blank", "_self", or "none"
    active: true, //true/false to show/hide
  },
  hms_2: {
    //sternocleidomastoid-lt-front
    hover: "STERNOCLEIDOMASTOID [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_3: {
    //trapezius-rt-front
    hover: "TRAPEZIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_4: {
    //trapezius-lt-front
    hover: "TRAPEZIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_5: {
    //deltoid-rt-front
    hover: "DELTOID [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_6: {
    //deltoid-lt-front
    hover: "DELTOID [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_7: {
    //pectoralis-major-rt-front
    hover: "PECTORALIS MAJOR [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_8: {
    //pectoralis-major-lt-front
    hover: "PECTORALIS MAJOR [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_9: {
    //rectus-abdominis-front
    hover: "RECTUS ABDOMINIS",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_10: {
    //serratus-anterior-rt-front
    hover: "SERRATUS ANTERIOR [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_11: {
    //serratus-anterior-lt-front
    hover: "SERRATUS ANTERIOR [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_12: {
    //abdominal-external-oblique-rt-front
    hover: "ABDOMINAL EXTERNAL OBLIQUE [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_13: {
    //abdominal-external-oblique-lt-front
    hover: "ABDOMINAL EXTERNAL OBLIQUE [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_14: {
    //biceps-brachii-rt-front
    hover: "BICEPS BRACHII [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_15: {
    //biceps-brachii-lt-front
    hover: "BICEPS BRACHII [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_16: {
    //brachioradialis-rt-front
    hover: "BRACHIORADIALIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_17: {
    //brachioradialis-lt-front
    hover: "BRACHIORADIALIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_18: {
    //flexor-carpi-radialis-rt-front
    hover: "FLEXOR CARPI RADIALIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_19: {
    //flexor-carpi-radialis-lt-front
    hover: "FLEXOR CARPI RADIALIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_20: {
    //palmaris-longus-rt-front
    hover: "PALMARIS LONGUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_21: {
    //palmaris-longus-lt-front
    hover: "PALMARIS LONGUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_22: {
    //gluteus-medius-rt-front
    hover: "GLUTEUS MEDIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_23: {
    //gluteus-medius-lt-front
    hover: "GLUTEUS MEDIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_24: {
    //vastus-lateralis-rt-front
    hover: "VASTUS LATERALIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_25: {
    //vastus-lateralis-lt-front
    hover: "VASTUS LATERALIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_26: {
    //rectus-femoris-rt-front
    hover: "RECTUS FEMORIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_27: {
    //rectus-femoris-lt-front
    hover: "RECTUS FEMORIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_28: {
    //sartorius-rt-front
    hover: "SARTORIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_29: {
    //sartorius-lt-front
    hover: "SARTORIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_30: {
    //pectineus-rt-front
    hover: "PECTINEUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_31: {
    //pectineus-lt-front
    hover: "PECTINEUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_32: {
    //adductor-longus-rt-front
    hover: "ADDUCTOR LONGUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_33: {
    //adductor-longus-lt-front
    hover: "ADDUCTOR LONGUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_34: {
    //vastus-medialis-rt-front
    hover: "VASTUS MEDIALIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_35: {
    //vastus-medialis-lt-front
    hover: "VASTUS MEDIALIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_36: {
    //gracilis-rt-front
    hover: "GRACILIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_37: {
    //gracilis-lt-front
    hover: "GRACILIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_38: {
    //gastrocnemius-rt-front
    hover: "GASTROCNEMIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_39: {
    //gastrocnemius-lt-front
    hover: "GASTROCNEMIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_40: {
    //soleus-rt-front
    hover: "SOLEUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_41: {
    //soleus-lt-front
    hover: "SOLEUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_42: {
    //tibialis-anterior-rt-front
    hover: "TIBIALIS ANTERIOR [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_43: {
    //tibialis-anterior-lt-front
    hover: "TIBIALIS ANTERIOR [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_44: {
    //peroneus-longus-rt-front
    hover: "PERONEUS LONGUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_45: {
    //peroneus-longus-lt-front
    hover: "PERONEUS LONGUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  /*===============================================================================*/
  /*===============================================================================*/
  /*================  !!! CUSTOMIZATION OF THE OTHER SIDE !!!  ====================*/
  /*===============================================================================*/
  /*===============================================================================*/

  hms_46: {
    //trapezius-back
    hover: "TRAPEZIUS",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_47: {
    //infraspinatus-rt-back
    hover: "INFRASPINATUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_48: {
    //infraspinatus-lt-back
    hover: "INFRASPINATUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_49: {
    //teres-major-rt-back
    hover: "TERES MAJOR [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_50: {
    //teres-major-lt-back
    hover: "TERES MAJOR [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_51: {
    //latissimus-dorsi-rt-back
    hover: "LATISSIMUS DORSI [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_52: {
    //latissimus-dorsi-lt-back
    hover: "LATISSIMUS DORSI [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_53: {
    //deltoid-rt-back
    hover: "DELTOID [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_54: {
    //deltoid-lt-back
    hover: "DELTOID [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_55: {
    //triceps-brachii-rt-back
    hover: "TRICEPS BRACHII [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_56: {
    //triceps-brachii-lt-back
    hover: "TRICEPS BRACHII [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_57: {
    //extensor-carpi-ulnaris-rt-back
    hover: "EXTENSOR CARPI ULNARIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_58: {
    //extensor-carpi-ulnaris-lt-back
    hover: "EXTENSOR CARPI ULNARIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_59: {
    //flexor-carpi-ulnaris-rt-back
    hover: "FLEXOR CARPI ULNARIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_60: {
    //flexor-carpi-ulnaris-lt-back
    hover: "FLEXOR CARPI ULNARIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_61: {
    //gluteus-medius-rt-back
    hover: "GLUTEUS MEDIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_62: {
    //gluteus-medius-lt-back
    hover: "GLUTEUS MEDIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_63: {
    //gluteus-maximus-rt-back
    hover: "GLUTEUS MAXIMUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_64: {
    //gluteus-maximus-lt-back
    hover: "GLUTEUS MAXIMUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_65: {
    //vastus-lateralis-rt-back
    hover: "VASTUS LATERALIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_66: {
    //vastus-lateralis-lt-back
    hover: "VASTUS LATERALIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_67: {
    //biceps-femoris-rt-back
    hover: "BICEPS FEMORIS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_68: {
    //biceps-femoris-lt-back
    hover: "BICEPS FEMORIS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_69: {
    //semitendinosus-rt-back
    hover: "SEMITENDINOSUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_70: {
    //semitendinosus-lt-back
    hover: "SEMITENDINOSUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_71: {
    //semimembranosus-rt-back
    hover: "SEMIMEMBRANOSUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_72: {
    //semimembranosus-lt-back
    hover: "SEMIMEMBRANOSUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_73: {
    //adductor-magnus-rt-back
    hover: "ADDUCTOR MAGNUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_74: {
    //adductor-magnus-lt-back
    hover: "ADDUCTOR MAGNUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_75: {
    //gastrocnemius-rt-back
    hover: "GASTROCNEMIUS [RT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
  hms_76: {
    //gastrocnemius-lt-back
    hover: "GASTROCNEMIUS [LT]",
   // url: "https://www.humananatomyillustrations.com/",
   // target: "_self",
    active: true,
  },
};

(function ($) {
    "use strict";
  
    function isTouchEnabled() {
      return (
        "ontouchstart" in window ||
        navigator.MaxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    }
  
    function initializeMuscleBody() {
      $("#hms-baseb").hide().animate({ opacity: "0" }, 100);
      $(".hms-gob").on("click", function () {
        $("#hms-basea").hide().animate({ opacity: "0" }, 100);
        $("#hms-baseb").show().animate({ opacity: "1" }, 100);
      });
      $(".hms-goa").on("click", function () {
        $("#hms-baseb").hide().animate({ opacity: "0" }, 100);
        $("#hms-basea").show().animate({ opacity: "1" }, 100);
      });
  
      $('path[id^="hms_"]').each(function (i, e) {
        addEvent($(e).attr("id"));
      });
    }
  
    function addEvent(id) {
      var _obj = $("#" + id);
      $("#hms-wrapper").css({ opacity: "1" });
  
      _obj.attr({ fill: "rgba(255, 0, 0, 0)", stroke: "rgba(255, 102, 102, 1)" });
      _obj.attr({ cursor: "default" });
  
      if (hms_config[id]["active"] === true) {
        if (isTouchEnabled()) {
          var touchmoved;
          _obj
            .on("touchend", function (e) {
              if (touchmoved !== true) {
                _obj.on("touchstart", function (e) {
                  let touch = e.originalEvent.touches[0];
                  let x = touch.pageX - 10,
                    y = touch.pageY + -15;
  
                  let $hmsatip = $("#tip-hms");
                  let hmsanatomytipw = $hmsatip.outerWidth(),
                    hmsanatomytiph = $hmsatip.outerHeight();
  
                  x =
                    x + hmsanatomytipw >
                    $(document).scrollLeft() + $(window).width()
                      ? x - hmsanatomytipw - 20 * 2
                      : x;
                  y =
                    y + hmsanatomytiph >
                    $(document).scrollTop() + $(window).height()
                      ? $(document).scrollTop() +
                        $(window).height() -
                        hmsanatomytiph -
                        10
                      : y;
  
                  if (hms_config[id]["target"] !== "none") {
                    _obj.css({ fill: "rgba(255, 0, 0, 0.7)" });
                  }
                  $hmsatip.show().html(hms_config[id]["hover"]);
                  $hmsatip.css({ left: x, top: y });
                });
                _obj.on("touchend", function () {
                  _obj.css({ fill: "rgba(255, 0, 0, 0)" });
                  if (hms_config[id]["target"] === "_blank") {
                    window.open(hms_config[id]["url"]);
                  } else if (hms_config[id]["target"] === "_self") {
                    window.parent.location.href = hms_config[id]["url"];
                  }
                  $("#tip-hms").hide();
                });
              }
            })
            .on("touchmove", function (e) {
              touchmoved = true;
            })
            .on("touchstart", function () {
              touchmoved = false;
            });
        }
        _obj.attr({ cursor: "pointer" });
  
        _obj
          .on("mouseenter", function () {
            $("#tip-hms").show().html(hms_config[id]["hover"]);
            _obj.css({ fill: "rgba(255, 0, 0, 0.3)" });
          })
          .on("mouseleave", function () {
            $("#tip-hms").hide();
            _obj.css({ fill: "rgba(255, 0, 0, 0)" });
          });
        if (hms_config[id]["target"] !== "none") {
          _obj.on("mousedown", function () {
            _obj.css({ fill: "rgba(255, 0, 0, 0.7)" });
          });
        }
        _obj.on("mouseup", function () {
          _obj.css({ fill: "rgba(255, 0, 0, 0.3)" });
          if (hms_config[id]["target"] === "_blank") {
            window.open(hms_config[id]["url"]);
          } else if (hms_config[id]["target"] === "_self") {
            window.parent.location.href = hms_config[id]["url"];
          }
        });
        _obj.on("mousemove", function (e) {
          // Calculate position based on cursor within the modal
          let x = e.clientX + 10;  // Position tooltip 10px to the right of the cursor
          let y = e.clientY + 15;  // Position tooltip 15px below the cursor
        
          let $ahms = $("#tip-hms");
          let hmsanatomytipw = $ahms.outerWidth();
          let hmsanatomytiph = $ahms.outerHeight();
        
          // Check if tooltip goes out of bounds within modal and adjust
          if (x + hmsanatomytipw > $(window).width()) {
            x = x - hmsanatomytipw - 20;
          }
        
          if (y + hmsanatomytiph > $(window).height()) {
            y = y - hmsanatomytiph - 10;
          }
        
          // Apply the calculated position to the tooltip
          $ahms.css({
            left: x + "px",
            top: y + "px",
            position: "fixed"  // Ensure tooltip stays relative to viewport, not document
          });
        });
        
        
      } else {
        _obj.hide();
      }
    }
  
    // Export the initialization function
    window.initializeMuscleBody = initializeMuscleBody;
  
  })(jQuery);
  

// export function initializeHoverEvents() {
//     jQuery('path[id^="hms_"]').each((i, e) => {
//         addEvent(jQuery(e).attr("id"));
//     });
//   }
  


// _obj.on("mousemove", function (e) {
//   let modalOffset = $("#modal-id").offset(); // Replace #modal-id with your actual modal ID
//   let x = e.pageX - modalOffset.left + 10;
//   let y = e.pageY - modalOffset.top + 15;

//   let $ahms = $("#tip-hms");
//   let hmsanatomytipw = $ahms.outerWidth(),
//       hmsanatomytiph = $ahms.outerHeight();

//   x =
//     x + hmsanatomytipw > $(window).width()
//       ? x - hmsanatomytipw - 20 * 2
//       : x;
//   y =
//     y + hmsanatomytiph > $(window).height()
//       ? $(window).height() - hmsanatomytiph - 10
//       : y;

//   $ahms.css({ left: x, top: y });
// });
