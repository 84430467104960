/* eslint-disable jsx-a11y/img-redundant-alt */
import PropTypes from "prop-types";
import { Box, Typography } from "../../../Components";
import useWindowSizeHook from "../../../Components/WindowSizeHook";
import React from "react";
import { getLoggedInUser } from "../../../utils/helper";

export default function MessageTemplate({ ...props }) {
  const getLocalData = getLoggedInUser();
  const { width } = useWindowSizeHook();

  return (
    <div className="ps-5 d-flex justify-content-end my-4 align-items-center">
      {/* Render both sections conditionally if they exist */}
      {props?.image && (
        <img
          src={props?.image}
          alt="image"
          width="150px"
          height="150px"
          className="mx-2"
        />
      )}
      {typeof props?.message !== "string" ? (  props.message?.map(item =>      <Box
                  background="linear-gradient(90deg, #ff4c65, #e30020)"
                  borderRadius="0.75rem 0.75rem 0 0.75rem"
                  width="fit-content"
                  className="px-3 py-1 ms-1"
              >
                <Typography
                    as={width < 600 ? "label" : "p"}
                    className="m-2"
                    color="#fff"
                    style={{ whiteSpace: "pre-wrap" }}
                >
                  {typeof item === "string" &&
                      item.split("\n").map((msg, index) => (
                          <React.Fragment key={index}>
                            {msg}
                            <br />
                          </React.Fragment>
                      ))}
                </Typography>
              </Box>)
          ):
      props?.message && (
        <Box
          background="linear-gradient(90deg, #ff4c65, #e30020)"
          borderRadius="0.75rem 0.75rem 0 0.75rem"
          width="fit-content"
          className="px-3 py-1"
        >
          <Typography
            as={width < 600 ? "label" : "p"}
            className="m-2"
            color="#fff"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {typeof props?.message === "string" &&
              props.message.split("\n").map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  <br />
                </React.Fragment>
              ))}
          </Typography>
        </Box>
      )}
      {/* Always display the avatar */}
      <img
        src={`https://ui-avatars.com/api/?name=${`${
          props?.fromUser || getLocalData?.name
        }`}&background=fff&color=231671`}
        alt=""
        width="60px"
        height="60px"
        className="mx-2 rounded-circle"
      />
    </div>
  );
}

MessageTemplate.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.string, // Add image prop type
  fromUser: PropTypes.string, // Add fromUser prop type if necessary
};
