/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, GenericLoader, Typography } from "../../../../Components";
import { useDispatch, useSelector } from "react-redux";
import { getAllLookupList } from "../../../../Redux/actions";
import { MenuItem, Select } from "@mui/material";
import { API_URLS } from "../../../../config";
import axios from "axios";

const Gender = ({ values, setFieldValue }) => {
  let dispatch = useDispatch();
  const [apiResponse, setApiResponse] = useState();

  useEffect(() => {
    dispatch(getAllLookupList("Gender"));
  }, []);

  const data = useSelector((state) => state.patientProfile);
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setFieldValue("menstrualCycle", value);
  };

  useEffect(() => {
    const fetchMenstrualCycleData = async () => {
      try {
        const response = await axios.get(
          `${API_URLS.LOOKUP.GET_LOOKUP}?type=MenstrualCycle`
        );
        setApiResponse(response?.data?.data);
      } catch (error) {
        console.error("Error fetching menstrual cycle data", error);
      }
    };

    if (values?.sexId === 102) {
      fetchMenstrualCycleData();
    }
  }, [values?.sexId]);

  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#E30020">
        GENERAL INFORMATION
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Sex Assigned At Birth
      </Typography>
      <Typography fontSize="0.8rem" fontWeight="300">
        Biological sex can impact risk for certain conditions and response to
        treatments.
      </Typography>
      {data?.GET_LOOKUP_LIST_LOADING ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <GenericLoader />
        </div>
      ) : (
        data?.GET_LOOKUP_LIST?.map((option, idx) => (
          <>
            <Box
              className="p-3 my-3 cursor-pointer option-wrapper"
              onClick={() => {
                setFieldValue("sexId", option?.lookupId);
              }}
              height="100%"
              width="100%"
              borderRadius="0.5rem"
              background={values?.sexId === option?.lookupId && "#E30020"}
              border={values?.sexId === option?.lookupId && "1px solid #E30020"}
            >
              <Typography
                fontSize=".9rem"
                color={values?.sexId === option?.lookupId && "white"}
                fontWeight={values?.sexId === option?.lookupId && "500"}
                className="heading"
              >
                {option?.text}
              </Typography>
            </Box>
          </>
        ))
      )}
      {console.log("list", apiResponse)}
      {values?.sexId === 102 && (
        <>
          <label>What’s your average menstrual cycle?</label>
          <Select
            className="w-100 my-1"
            value={values?.menstrualCycle}
            onChange={handleSelectChange}
          >
            {apiResponse &&
              apiResponse?.map((item) => (
                <MenuItem key={item.lookupId} value={item.lookupId}>
                  {item.text}
                </MenuItem>
              ))}
          </Select>
        </>
      )}
    </div>
  );
};

export default Gender;
