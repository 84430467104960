import axios from "axios";
import { API_URLS } from "../../config";

function getSpecializationList(id) {
  return axios.get(
    `${API_URLS.SPECIALIZATION_QUESTIONS.GET_SPECIALIZATION_QUESTION}?specializationId=${id}`
  );
}

function saveSpecializationList(data) {
  return axios.post(
    `${API_URLS.SPECIALIZATION_QUESTIONS.SAVE_SPECIALIZATION_QUESTION}`,
    data
  );
}

function getSuggestedQuestionsAPI(specialization, language) {
  return axios.get(
    `${API_URLS.SPECIALIZATION_QUESTIONS.GET_SUGGESTED_QUESTION_LIST}?doctorField=${specialization}&language=${language}`
  );
}
function translateQuestionsAPI(payload) {

  return axios.post(
    `${API_URLS.SPECIALIZATION_QUESTIONS.TRANSALATE_QUESTION_LIST}`,payload
  );
}

export {
  getSpecializationList,
  saveSpecializationList,
  getSuggestedQuestionsAPI,
  translateQuestionsAPI,
};
