import React, { useEffect, useState } from "react";
import { Box, Button, GenericLoader, Typography } from "../../Components";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getSpecializationQuestionsList } from "../../Redux/actions/specializationQuestionsAction";
import { Field, Form, Formik } from "formik";
import AddQuestionsAgainstSpecialization from "./questionsList";

function DoctorHome() {
  const dispatch = useDispatch();
  const { width } = useWindowSizeHook();
  const specializationQuestions = useSelector(
    (state) => state.specializationQuestions
  );
  const questionList =
    specializationQuestions?.GET_SPECIALIZATION_QUESTIONS?.data;


  const [showDoctorProfileModal, setShowDoctorProfileModal] = useState(0);
  const [doctorInfo, setDoctorInfo] = useState(null);
  const [speciality, setSpeciality] = useState(1);



  const [initialValues, setInitialValues] = useState(
    {
      specializationId: "",
      questions:  [{
          question: "",
          questionType: ["Multi-Select", "Single-Select", "Text"],
          options: [],
          symptoms: [""],
          selectedType: "",
        }],
    }
  );

  const handleEdit = (questionObj) => {
    setDoctorInfo({
      specializationId: questionObj?.specializationId,
      questions: questionObj?.questions || [{ question: "", symptoms: [""] }],
    });
    setShowDoctorProfileModal(1);
  };

  // Update `initialValues` whenever `questionList` changes
  // useEffect(() => {
  //   setInitialValues(
  //     questionList || {
  //       specializationId: "",
  //       questions: [
  //         { question: "", questionType: ["MS", "US", "TEXT"], symptoms: [""] },
  //       ],
  //     }
  //   );
  // }, [questionList]);

  // useEffect(() => {
  //   if (questionList) {
  //     const mappedQuestions = questionList.questions.map((q) => ({
  //       question: q.Question,
  //       questionType: q.QuestionType === "mu"
  //         ? "Multi-Select"
  //         : q.QuestionType === "us"
  //         ? "Single-Select"
  //         : "Text",
  //       symptoms: q.Symptoms || [],
  //       selectedType: "",
  //     }));

  //     setInitialValues({
  //       specializationId: questionList.specializationId,
  //       questions: mappedQuestions,
  //     });
  //   }
  // }, [questionList]);

  useEffect(() => {
    dispatch(getSpecializationQuestionsList(1));
  }, [dispatch]);

  useEffect(() => {
    if (questionList) {
      setDoctorInfo({ specializationId: questionList.specializationId });
    }
  }, [questionList]);

  const consultantSpecializationList = [
    { id: "1", title: "Physician", key: "physician" },
    { id: "2", title: "Gastroenterologist", key: "gastroenterologist" },
    { id: "3", title: "Pulmonologist", key: "pulmonologist" },
    { id: "4", title: "Urologist", key: "urologist" },
    { id: "5", title: "Orthopedic", key: "orthopedic" },
  ];

  function getSaveQuestions(specialityId) {
    dispatch(getSpecializationQuestionsList(specialityId));
  }

  return (
    <div className="mx-auto">
      {/* <div style={{ width: "80%" }} className="mx-auto">
        <LayoutNavbar />
      </div> */}
      {showDoctorProfileModal !== 0 ? (
        <Box
          width="80%"
          maxWidth="202rem"
          borderRadius="1.375rem"
          background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
          shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
          backdropFilter="blur(15px)"
          className={`${width > 600 ? "m-auto p-5" : "p-3 m-auto"}`}
        >
          <Typography as={width < 1300 ? "h5" : "h2"} align="center">
            Welcome to Pre Consultant
          </Typography>
          <Typography
            as="p"
            align="center"
            color="var(--logo-black-700, #4A4F4D)"
            className="pb-5 px-2"
          >
            Your AI Health Assistant! Gather essential health information to
            streamline your doctor's consultation, all from the comfort of your
            screen.
          </Typography>
          <Formik
            initialValues={{
              specialization: doctorInfo?.specializationId || "",
            }}
            enableReinitialize
            onSubmit={(values) => {
              const { specialization } = values;
              // dispatch(getSpecializationQuestionsList(specialization));
              getSaveQuestions(specialization);
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <Form>
                <div className="mb-4 w-100 d-flex justify-content-between align-items-center">
                  <div className="w-100">
                    <label className="fw-bold mb-2">Specialization</label>
                    <Field
                      as="select"
                      name="specialization"
                      className="form-control w-25 cursor-pointer"
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("specialization", value);
                        handleSubmit();
                        setSpeciality(e.target.value);
                      }}
                    >
                      {consultantSpecializationList?.map((item) => (
                        <option key={item.key} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {(questionList === null ||
                    questionList?.questions.length <= 0) && (
                    <div>
                      <Button
                        type="button"
                        className="btn btn-color text-white"
                        onClick={() => handleEdit()}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {specializationQuestions?.GET_SPECIALIZATION_QUESTIONS_LOADING ? (
            <div className="text-center">
              <GenericLoader />
            </div>
          ) : questionList === null || questionList?.questions.length <= 0 ? (
            <p className="text-center">
              No data available against this Specialization.
            </p>
          ) : (
            <div className="p-4">
              {questionList?.questions?.length > 0 ? (
                questionList?.questions?.map((questionObj, index) => (
                  <div key={index} style={{ marginBottom: "8px" }}>
                    <div>
                      <div className="d-flex justify-content-end align-items-center mb-2">
                        <button
                          className="bg-transparent border-0"
                          onClick={() => handleEdit(questionObj)}
                        >
                          <FaEdit />
                        </button>
                      </div>
                      <p>
                        {" "}
                        <strong>{`${index + 1}) ${
                          questionObj?.question
                        }`}</strong>{" "}
                      </p>
                      {questionObj?.symptoms?.length > 0 ? (
                        <ul>
                          {questionObj?.symptoms?.map(
                            (symptom, symptomIndex) =>
                              symptom && <li key={symptomIndex}>{symptom}</li>
                          )}
                        </ul>
                      ) : (
                        <p>No symptoms listed.</p>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No records found.</p>
              )}
            </div>
          )}
        </Box>
      ) : (
        // showDoctorProfileModal && (
        <AddQuestionsAgainstSpecialization
          show={showDoctorProfileModal}
          onHide={() => setShowDoctorProfileModal(0)}
          setDoctorInfo={setDoctorInfo}
          speciality={speciality}
          getSaveQuestions={getSaveQuestions}
          initialValues={initialValues}
        />

        // <InitialProfileModal
        //   show={showDoctorProfileModal}
        //   onHide={() => setShowDoctorProfileModal(false)}
        //   setDoctorInfo={setDoctorInfo}
        //   speciality={speciality}
        //   initialValues={
        //     questionList || {
        //       specializationId: "",
        //       questions: [{ question: "", symptoms: [""] }],
        //     }
        //   }
        // />
        // )
      )}
    </div>
  );
}

export default DoctorHome;
