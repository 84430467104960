import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTER_PATH } from "../../config";

export default function PrivateRoutes() {
  const auth = JSON.parse(localStorage.getItem("med_user"));
  const RoleId = auth?.roleId;
  const location = useLocation();

  if (
    auth &&
    RoleId === 1 &&
    location.pathname !== ROUTER_PATH.DOCTOR_HOME_PAGE
  ) {
    return (
      <Navigate
        to={ROUTER_PATH.DOCTOR_HOME_PAGE}
        state={{ from: location }}
        replace
      />
    );
  }
  return auth ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTER_PATH.SIGN_IN} state={{ from: location }} replace />
  );
}
