/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types";
import IMAGES from "../../Assets/images";
import { useEffect } from "react";

export function MusclesStructure({
  selectedPatient,
  avatarData,
  setAvatarData,
  bodyLoader,
  showModal,
  onHide,
}) {
  useEffect(() => {
    if (window.initializeMuscleBody) {
      window.initializeMuscleBody();
    }
  }, []);

  function avatarValues(val) {
    if (!val || val.classList.value.trim() === "") {
      return;
    }

    const clickedValue = val.classList.value;

    const index = avatarData?.indexOf(clickedValue);

    if (index === -1) {
      setAvatarData((prevData) => [...prevData, clickedValue]);
      onHide();
    } else {
      setAvatarData((prevData) =>
        prevData?.filter((item) => item !== clickedValue)
      );
      onHide();
    }
  }

  return (
    <div
      className={`container card-height-style p-0 ${
        bodyLoader && "cursor-not-allowed"
      }`}
    >
      <div className="">
        <div className="card-body card-body-height-for-avatar p-0 pt-2">
          {/* <div className="col-md-4 col-lg-4 col-12">
                    <h6 className="text-uppercase mt-4">Selected Body Parts</h6>
                     <p className="txt">{avatarData.toString()}</p>
                    <div className="avatar-body-part-fixed-height">
                      {avatarData?.map((string, index) => (
                        <ul key={index}>
                          <li>{string}</li>
                        </ul>
                        // <p key={index}>{string}</p>
                      ))}
                    </div>
                  </div> */}
          <div className="col-md-12 col-lg-12 col-12 webside-parent-class">
            <div className="form-group center">
              <span id="tip-hms" />
              <div id="anawrapper" className="row">
                {/* V1.000.2.3 JS */}
                <div className="col-6">
                  <div id="hms-basea">
                    <svg
                      style={{ pointerEvents: bodyLoader ? "none" : "" }}
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 600 980"
                      xmlSpace="preserve"
                      onClick={(e) =>
                        selectedPatient?.avatarCoordinates
                          ? ""
                          : avatarValues(e.target)
                      }
                    >
                      <image
                        overflow="visible"
                        width="600"
                        height="980"
                        xlinkHref={IMAGES.MUSCLE_MODEL_A}
                      />
                      <path
                        id="hms_1"
                        className="sternocleidomastoid-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "sternocleidomastoid-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M287.229,173.333c3.667,6.833,6.333,1,3.333-5.667s-5.666-22-7-32.167c-1.333-10.167-12-14.5-16.833-24.5c1.667,20.167,7,24.834,8.5,32.453S283.562,166.5,287.229,173.333z"
                      />
                      <path
                        id="hms_2"
                        className="sternocleidomastoid-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "sternocleidomastoid-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M310.666,173.333c3.668-6.833,10.5-22.262,12-29.881s6.834-12.286,8.5-32.453c-4.832,10-15.499,14.333-16.832,24.5c-1.334,10.167-4,25.5-7,32.167S306.999,180.167,310.666,173.333z"
                      />
                      <path
                        id="hms_3"
                        className="trapezius-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("trapezius-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M268.396,175.667c10.833-1,1.5-21.667,0-27.167c-2.334,5.667-21.5,23.667-30.334,27.167C249.192,177.667,257.562,176.667,268.396,175.667z"
                      />
                      <path
                        id="hms_4"
                        className="trapezius-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("trapezius-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M329.5,175.667c10.834,1,19.202,2,30.334,0c-8.834-3.5-28-21.5-30.334-27.167C328,154,318.666,174.667,329.5,175.667z"
                      />
                      <path
                        id="hms_5"
                        className="deltoid-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("deltoid-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M261.146,179.5c-7.584,0.833-20.584-0.167-25.084-2c-2.187-0.891-1.5-1.5-9-1.5c-7.5,0-23.667-1.333-38.833,14.167c-13.354,13.646-16.667,25-18,40.833c-0.363,4.318,0.833,13.333,0,17.5c2.568-3.604,12.949-7.438,22.334-15.167C203.896,224,219.199,222.466,225.896,213C233.396,202.25,242.636,185.864,261.146,179.5z"
                      />
                      <path
                        id="hms_6"
                        className="deltoid-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("deltoid-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M405.333,233.333c9.385,7.729,19.766,11.563,22.334,15.167c-0.833-4.167,0.363-13.182,0-17.5c-1.333-15.833-4.646-27.187-18-40.833C394.5,174.667,378.334,176,370.834,176s-6.813,0.609-9,1.5c-4.5,1.833-17.5,2.833-25.084,2c18.51,6.364,27.75,22.75,35.25,33.5C378.697,222.466,394,224,405.333,233.333z"
                      />
                      <path
                        id="hms_7"
                        className="pectoralis-major-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "pectoralis-major-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M261.396,264c9.148-1.663,15.068-3.865,19.594-6.519c3.636-2.133,6.371-4.558,9.156-7.231c6.25-6,3.176-27,1.713-35.5s3.703-47.083-30.713-35.25c-18.51,6.364-27.75,22.75-35.25,33.5c2.5,0.25,2,3.75,1.25,7.75s-2.25,23,2,31.5c1.72,3.44,4.628,6.512,8.575,8.751c4.028,2.286,9.14,3.704,15.175,3.76C255.554,264.786,258.392,264.546,261.396,264z"
                      />
                      <path
                        id="hms_8"
                        className="pectoralis-major-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "pectoralis-major-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M336.75,179.5c-34.416-11.833-29.25,26.75-30.713,35.25s-4.537,29.5,1.713,35.5c2.785,2.673,5.52,5.099,9.156,7.231c4.525,2.654,10.445,4.855,19.594,6.519c3.003,0.546,5.841,0.786,8.5,0.761c6.035-0.056,11.146-1.474,15.175-3.76c3.946-2.239,6.854-5.311,8.575-8.751c4.25-8.5,2.75-27.5,2-31.5s-1.25-7.5,1.25-7.75C364.5,202.25,355.26,185.864,336.75,179.5z"
                      />
                      <path
                        id="hms_9"
                        className="rectus-abdominis-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("rectus-abdominis-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M342.161,330c-0.608,2.307-1.827,4.5-0.661,6.667s1.167,5,0.834,8.833C342,349.333,340,363.167,339,366.333s-0.833,5.063-0.666,9.948c0.166,4.885,0.832,17.052-0.668,23.552s-13.167,43.667-15.5,49.5c-2.332,5.833-9.667,25.5-10.5,27.833c-0.832,2.334-1.5,3.667-4,5c-1.982,1.395-7.291,4.584-9.541,4.584s-6.5-3.75-7.896-4.584c-2.434-1.451-3.167-2.666-4-5c-0.833-2.333-8.167-22-10.5-27.833s-14-43-15.5-49.5s-0.833-18.667-0.667-23.552c0.167-4.885,0.334-6.781-0.666-9.948s-3-17-3.334-20.833c-0.333-3.833-0.333-6.667,0.834-8.833c1.166-2.167-0.054-4.36-0.662-6.667c-1.088-4.125-0.213-14.25,1.537-17.5s12.103-10,17.863-13.25c5.762-3.25,12.429-6,15.095-6.875c1.788-0.586,5.242-2.066,8.719,0.333c3.477-2.399,6.932-0.919,8.719-0.333c2.667,0.875,9.334,3.625,15.095,6.875c5.762,3.25,16.114,10,17.864,13.25S343.25,325.875,342.161,330z M272.396,296.125c5.076-2.648,18.125-8.5,21.521-10.875s1.979-14,1.979-18.083c0-10.572-7.45-11.167-14.906-9.686c-4.525,2.654-10.445,4.855-19.594,6.519c-3.167,3-6.968,22.005-7.277,30.333c-0.114,3.086-0.025,6.667,1.027,10.542C256.864,311.205,263.771,300.625,272.396,296.125z M343.777,294.333C343.467,286.005,339.666,267,336.5,264c-9.148-1.663-15.068-3.865-19.594-6.519C309.449,256,302,256.595,302,267.167c0,4.083-1.416,15.708,1.979,18.083s16.444,8.227,21.521,10.875c8.625,4.5,15.531,15.08,17.25,8.75C343.802,301,343.892,297.42,343.777,294.333z"
                      />
                      <path
                        id="hms_10"
                        className="serratus-anterior-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "serratus-anterior-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M247.729,367c2.833-6,2.667-17.167,0-20.167s1.762-1.667,1.464-6.167c-0.297-4.5,2.036-17.167-3.631-20.167c-5.666-3,4.127,0,4.23-7.167s1.437-15.334-4.396-19.667c2.166-1.667,7.333-4.833,6.5-13.333c-0.834-8.5-2.501-8.5-4.167-9.833c2.833-1.333,3.46-3.466,5.167-5.739c-6.035-0.056-11.146-1.474-15.175-3.76c-2.159-0.001-6.495-0.318-8.159-0.667c-3.205-0.672-6.666-1.333-8.333,8.167s-0.667,28,2.667,36.5s2.167,7.833,2,10.5s1.167,14.667,2.833,18c0.639,1.279,1.69,5.476,1.594,10.268c-0.154,7.7-3.094,16.398-0.761,18.065c1.308,0.935,7.72,5.945,12.806,6.959C244.774,369.272,246.819,368.927,247.729,367z"
                      />
                      <path
                        id="hms_11"
                        className="serratus-anterior-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "serratus-anterior-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M350.167,270.5c-1.667,1.333-3.333,1.333-4.167,9.833s4.334,11.667,6.5,13.333c-5.834,4.333-4.5,12.5-4.396,19.667s9.896,4.167,4.23,7.167c-5.667,3-3.334,15.667-3.632,20.167c-0.297,4.5,4.132,3.167,1.464,6.167c-2.667,3-2.832,14.167,0,20.167c0.91,1.927,2.955,2.272,5.361,1.792c5.086-1.014,11.498-6.024,12.807-6.959c2.332-1.667-0.607-10.365-0.762-18.065c-0.096-4.792,0.955-8.989,1.594-10.268c1.667-3.333,3.001-15.333,2.834-18s-1.333-2,2-10.5s4.334-27,2.666-36.5c-1.667-9.5-5.128-8.839-8.332-8.167c-1.665,0.349-6,0.667-8.159,0.667c-4.028,2.286-9.14,3.704-15.175,3.76C346.707,267.034,347.334,269.167,350.167,270.5z"
                      />
                      <path
                        id="hms_12"
                        className="abdominal-external-oblique-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "abdominal-external-oblique-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M252.229,430.748c3,4.581,2.333-4.581,2.5-8.581c0.166-4-3.5-9-1.833-13s1-18.333,0-27.667s-7.834-11.333-10.527-12.708c-5.086-1.014-11.498-6.024-12.806-6.959c-0.998-0.731-2.5-0.333-3.167,11s-0.333,17.833,0,23.167s11.333,13.5,13.666,15.667c2.334,2.167,2.667,5,2.307,6.5c0.048,0.22,0.096,0.437,0.144,0.65C243.513,421.039,249.547,426.654,252.229,430.748z"
                      />
                      <path
                        id="hms_13"
                        className="abdominal-external-oblique-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "abdominal-external-oblique-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M345,381.5c-1,9.333-1.667,23.667,0,27.667s-2,9-1.833,13s-0.5,13.161,2.5,8.581c2.681-4.093,8.716-9.709,9.716-11.93c0.049-0.214,0.097-0.43,0.145-0.65c-0.36-1.5-0.027-4.333,2.307-6.5c2.333-2.167,13.332-10.333,13.666-15.667s0.666-11.833,0-23.167s-2.169-11.731-3.166-11c-1.309,0.935-7.721,5.945-12.807,6.959C352.834,370.167,346,372.167,345,381.5z"
                      />
                      <path
                        id="hms_14"
                        className="biceps-brachii-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("biceps-brachii-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M192.563,233.333c-9.385,7.729-19.766,11.563-22.334,15.167c-3.583,7.5-6.589,22.07-7.616,29c-1.56,10.527-2.499,20.945-2.338,29.833c0.12,6.59,0.846,12.339,2.371,16.667c1.477,4.19,4.75,11.5,5.75,15.232c19-8.732,34.833-31.565,44.75-57.732c8.725-23.021,5.5-53.25,12.75-68.5C219.199,222.466,203.896,224,192.563,233.333z"
                      />
                      <path
                        id="hms_15"
                        className="biceps-brachii-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("biceps-brachii-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M437.621,307.333c0.161-8.888-0.778-19.305-2.338-29.833c-1.027-6.93-4.033-21.5-7.616-29c-2.568-3.604-12.949-7.438-22.334-15.167C394,224,378.697,222.466,372,213c7.25,15.25,4.025,45.479,12.75,68.5c9.917,26.167,25.75,49,44.75,57.732c1-3.732,4.273-11.042,5.75-15.232C436.775,319.671,437.501,313.922,437.621,307.333z"
                      />
                      <path
                        id="hms_16"
                        className="brachioradialis-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("brachioradialis-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M160.274,307.333c-2.712,11.001-12.165,23.075-13.712,34.667c-1.515,11.348,0,42.667,0,47.333s-1.334,18-3,22.333s1,9,0,17.667c4.334-7,14-23.333,17.694-34c7.624-22.014,8.639-47.333,7.139-56.101c-1-3.732-4.273-11.042-5.75-15.232C161.12,319.671,160.395,313.922,160.274,307.333z"
                      />
                      <path
                        id="hms_17"
                        className="brachioradialis-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("brachioradialis-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M454.333,411.667c-1.666-4.333-3-17.666-3-22.333s1.515-35.986,0-47.333c-1.547-11.592-11-23.667-13.712-34.667c-0.12,6.59-0.846,12.339-2.371,16.667c-1.477,4.19-4.75,11.5-5.75,15.232c-1.5,8.768-0.485,34.087,7.139,56.101c3.694,10.667,13.36,27,17.694,34C453.333,420.667,455.999,416,454.333,411.667z"
                      />
                      <path
                        id="hms_18"
                        className="flexor-carpi-radialis-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "flexor-carpi-radialis-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M192.896,344c1.333-3.167-5.716-9.875-7.667-7.667c-3.833,4.167-13.834,16.333-14.667,23s-4.999,39.5-8.499,49.833s0.5,9.167,4.833,1.5s13.833-20.5,17-35.167S191.563,347.167,192.896,344z"
                      />
                      <path
                        id="hms_19"
                        className="flexor-carpi-radialis-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "flexor-carpi-radialis-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M431,410.667c4.333,7.667,8.333,8.833,4.833-1.5s-7.666-43.167-8.499-49.833s-10.834-18.833-14.667-23c-1.951-2.208-9,4.5-7.667,7.667s5.833,16.833,9,31.5S426.667,403,431,410.667z"
                      />
                      <path
                        id="hms_20"
                        className="palmaris-longus-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("palmaris-longus-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M192.896,344c-1.333,3.167-5.833,16.833-9,31.5s-12.667,27.5-17,35.167c-2,5.833-10.167,18.667-7.667,20c1.266,0.675,3.502-2.948,7.167-8.5c2.095-3.173,14.974-24.655,23.18-43.199c6.153-13.905,8.367-26.159,8.653-27.801C198.896,347.334,193.896,342.667,192.896,344z"
                      />
                      <path
                        id="hms_21"
                        className="palmaris-longus-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("palmaris-longus-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M414,375.5c-3.167-14.667-7.667-28.333-9-31.5c-1-1.333-6,3.334-5.333,7.167c0.286,1.643,2.5,13.896,8.653,27.801c8.206,18.544,21.085,40.026,23.18,43.199c3.665,5.552,5.901,9.175,7.167,8.5c2.5-1.333-5.667-14.167-7.667-20C426.667,403,417.167,390.167,414,375.5z"
                      />
                      <path
                        id="hms_22"
                        className="gluteus-medius-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-medius-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M236.062,432c-0.833-4.5-1.833-15-1.666-18.5c0.166-3.5-6.333-7-8.385-11c-1.218,18.667-9.949,46.333-13.449,60.5c-3.5,14.166-10,35.5-10.5,56.334c2-20.5,17.834-43.667,27.167-56S236.896,436.5,236.062,432z"
                      />
                      <path
                        id="hms_23"
                        className="gluteus-medius-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-medius-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M361.834,432c-0.834,4.5-2.501,19,6.832,31.333c9.334,12.333,25.168,35.5,27.168,56c-0.5-20.834-7-42.168-10.5-56.334c-3.5-14.167-12.231-41.833-13.449-60.5c-2.051,4-8.551,7.5-8.385,11S362.667,427.5,361.834,432z"
                      />
                      <path
                        id="hms_24"
                        className="vastus-lateralis-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "vastus-lateralis-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M206.146,538.75c0.5-15.5,9.705-55.674,23.083-75.417c-9.333,12.333-25.167,35.5-27.167,56c-1.959,19.479-1.166,30.416,0,61.916s5.334,45.25,7.834,53s7.75,10.5,6.75,24.25c3.417-11.166,5.25-13.834,2.75-25.75c-1.273-3.594-7.621-14.153-8.75-19C206.102,594.25,205.646,554.25,206.146,538.75z"
                      />
                      <path
                        id="hms_25"
                        className="vastus-lateralis-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "vastus-lateralis-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M391.75,538.75c0.5,15.5,0.044,55.5-4.5,75c-1.129,4.847-7.477,15.406-8.75,19c-2.5,11.916-0.666,14.584,2.75,25.75c-1-13.75,4.25-16.5,6.75-24.25s6.668-21.5,7.834-53s1.959-42.438,0-61.916c-2-20.5-17.834-43.667-27.168-56C382.045,483.076,391.25,523.25,391.75,538.75z"
                      />
                      <path
                        id="hms_26"
                        className="rectus-femoris-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("rectus-femoris-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M250.896,522.334c-6.834-27.834-7.334-48.834-7.834-56.834s-1.666-22.833-7-33.5c0.834,4.5,2.501,19-6.833,31.333c-13.379,19.743-22.583,59.917-23.083,75.417s-0.044,55.5,4.5,75c1.129,4.847,7.477,15.406,8.75,19c1.286,3.628,5.668,8.516,9.661,8.485c3.809-0.03,7.264-4.806,7.339-8.485c0.341-16.75,8.238-37.176,12.797-57.25C252.162,562.426,253.146,539.75,250.896,522.334z"
                      />
                      <path
                        id="hms_27"
                        className="rectus-femoris-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("rectus-femoris-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M391.75,538.75c-0.5-15.5-9.705-55.674-23.084-75.417C359.333,451,361,436.5,361.834,432c-5.334,10.667-6.5,25.5-7,33.5s-1,29-7.834,56.834c-2.25,17.416-1.267,40.092,1.702,53.166c4.56,20.074,12.457,40.5,12.798,57.25c0.074,3.68,3.529,8.455,7.339,8.485c3.993,0.03,8.376-4.857,9.661-8.485c1.273-3.594,7.621-14.153,8.75-19C391.794,594.25,392.25,554.25,391.75,538.75z"
                      />
                      <path
                        id="hms_28"
                        className="sartorius-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("sartorius-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M273.646,617c-0.5-10.25,0.25-25.75-1.25-40.75c-0.216-2.158-0.457-4.918-0.75-8.121c-0.69-7.537-1.673-17.531-3.308-27.947c-1.735-11.053-4.133-22.578-6.129-32.144c-0.879-4.212-2.972-14.749-3.265-16.409c-1.36-7.707-2.242-14.445-3.182-20.859c-1.22-8.331-2.534-16.117-5.117-24.77c-4.854-16.263-6.528-20.036-8.133-27.183c-0.119-0.264-0.182-0.491-0.144-0.65c-1.151,4.542-5.973-4.042-7.973-4.667c-0.167,3.5,0.833,14,1.666,18.5c5.334,10.667,6.5,25.5,7,33.5s1,29,7.834,56.834c6.833,27.833,13.75,70.166,15,85.666s2.75,34-2.5,53.75c-4.038,15.188-7.484,25.35-6.586,30.598c0.27,1.576,10.086,8.652,13.586-7.598S274.146,627.25,273.646,617z"
                      />
                      <path
                        id="hms_29"
                        className="sartorius-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("sartorius-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M347.25,446c-2.583,8.653-3.897,16.438-5.118,24.77c-0.938,6.414-1.821,13.152-3.181,20.859c-0.293,1.66-2.386,12.197-3.266,16.409c-1.996,9.565-4.393,21.091-6.128,32.144c-1.635,10.416-2.617,20.41-3.308,27.947c-0.294,3.203-0.534,5.963-0.75,8.121c-1.5,15-0.75,30.5-1.25,40.75s-0.25,51.5,3.25,67.75s13.316,9.174,13.586,7.598c0.898-5.248-2.549-15.409-6.586-30.598C329.25,642,330.75,623.5,332,608s8.166-57.833,15-85.666c6.834-27.834,7.334-48.834,7.834-56.834s1.666-22.833,7-33.5c0.833-4.5,1.832-15,1.666-18.5c-2,0.625-6.821,9.209-7.973,4.667c0.038,0.16-0.025,0.386-0.145,0.65C353.777,425.964,352.104,429.737,347.25,446z"
                      />
                      <path
                        id="hms_30"
                        className="pectineus-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("pectineus-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M272.948,483.035c-1.769-5.777-3.477-12.121-5.22-24.702c1,10.833-0.162,13.995-3,16.833c-2.167,2.167-6.568,6.757-5.784,16.462c0.293,1.66,2.386,12.197,3.265,16.409C263.356,500.909,270.396,492.334,272.948,483.035z"
                      />
                      <path
                        id="hms_31"
                        className="pectineus-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("pectineus-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M335.686,508.038c0.88-4.212,2.973-14.749,3.266-16.409c0.784-9.705-3.617-14.295-5.784-16.462c-2.839-2.839-4-6-3-16.833c-1.743,12.581-3.452,18.924-5.22,24.702C327.5,492.334,334.539,500.909,335.686,508.038z"
                      />
                      <path
                        id="hms_32"
                        className="adductor-longus-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("adductor-longus-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M268.338,540.182c1.635,10.416,2.617,20.41,3.308,27.947c2.083-11.871,7.75-15.795,10.334-26.784c0.153-8.373-0.35-18.487-2.251-30.179c-2.453-15.086-4.66-21.202-6.78-28.131c-2.553,9.299-9.592,17.874-10.739,25.003C264.205,517.604,266.603,529.129,268.338,540.182z"
                      />
                      <path
                        id="hms_33"
                        className="adductor-longus-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("adductor-longus-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M318.166,511.166c-1.901,11.691-2.404,21.806-2.25,30.179c2.584,10.989,8.25,14.913,10.334,26.784c0.69-7.537,1.673-17.531,3.308-27.947c1.735-11.053,4.132-22.578,6.128-32.144c-1.146-7.129-8.186-15.704-10.738-25.003C322.827,489.964,320.62,496.08,318.166,511.166z"
                      />
                      <path
                        id="hms_34"
                        className="vastus-medialis-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("vastus-medialis-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M265.896,608c-1.25-15.5-8.167-57.833-15-85.666c2.25,17.416,1.267,40.092-1.703,53.166c-4.559,20.074-12.456,40.5-12.797,57.25c1.5,19.25,5.5,30.5,9,33.75s13.25-0.5,18-4.75C268.646,642,267.146,623.5,265.896,608z"
                      />
                      <path
                        id="hms_35"
                        className="vastus-medialis-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("vastus-medialis-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M347,522.334c-6.834,27.833-13.75,70.166-15,85.666s-2.75,34,2.5,53.75c4.75,4.25,14.5,8,18,4.75s7.5-14.5,9-33.75c-0.341-16.75-8.238-37.176-12.798-57.25C345.733,562.426,344.75,539.75,347,522.334z"
                      />
                      <path
                        id="hms_36"
                        className="gracilis-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gracilis-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M267.729,458.333c1.743,12.581,3.451,18.924,5.22,24.702c2.12,6.929,4.327,13.045,6.78,28.131c1.901,11.691,2.404,21.806,2.251,30.179c-0.211,11.44-1.647,19.632-2.418,24.155c-1.333,7.834-6.143,36.503-5.916,51.5c0.5,10.25,0.25,51.5-3.25,67.75c6-28.5,7-68.5,8.25-77.75c1.714-12.684,12-53,12.25-78s-2.334-31.333-6.334-42.166C280.562,476,273.062,472,267.729,458.333z"
                      />
                      <path
                        id="hms_37"
                        className="gracilis-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gracilis-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M324.25,617c0.227-14.997-4.583-43.666-5.916-51.5c-0.771-4.523-2.207-12.715-2.418-24.155c-0.154-8.373,0.349-18.487,2.25-30.179c2.454-15.086,4.661-21.202,6.781-28.131c1.768-5.777,3.477-12.121,5.22-24.702c-5.333,13.667-12.833,17.667-16.833,28.5C309.334,497.667,306.75,504,307,529s10.536,65.316,12.25,78c1.25,9.25,2.25,49.25,8.25,77.75C324,668.5,323.75,627.25,324.25,617z"
                      />
                      <path
                        id="hms_38"
                        className="gastrocnemius-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gastrocnemius-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M247.229,743c-1.075,5.27-1.271,15.432-0.872,27.665C250.562,784,260.896,787.667,264.229,793c2-10.667,7-19,7.667-40c0.666-21-3.164-25.07-4-42.667C263.896,726.667,250.563,726.667,247.229,743z"
                      />
                      <path
                        id="hms_39"
                        className="gastrocnemius-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gastrocnemius-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M330,710.333C329.164,727.93,325.334,732,326,753s5.666,29.333,7.666,40c3.333-5.333,13.668-9,17.873-22.335c0.399-12.233,0.203-22.396-0.873-27.665C347.333,726.667,334,726.667,330,710.333z"
                      />
                      <path
                        id="hms_40"
                        className="soleus-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("soleus-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M246.356,770.665c0.838,25.684,4.301,60.498,7.762,78.335c6.11-28.667,8.11-45.333,10.11-56C260.896,787.667,250.562,784,246.356,770.665z"
                      />
                      <path
                        id="hms_41"
                        className="soleus-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("soleus-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M333.666,793c2,10.667,4,27.333,10.111,56c3.461-17.837,6.924-52.651,7.762-78.335C347.334,784,336.999,787.667,333.666,793z"
                      />
                      <path
                        id="hms_42"
                        className="tibialis-anterior-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "tibialis-anterior-rt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M235.646,814.75c-5.5-35.75-6.5-67.5-9-79.5s-3-27.5-6-44.75c0.5,12-2.76,18.748-4.75,47.75s2.75,45.5,6.25,64.25c0.878,4.703,1.945,9.689,3.117,14.78c3.501,15.203,7.944,31.332,11.112,43.613c3.931-0.716,7.276-1.853,7.021-3.644C242.896,853.75,241.146,850.5,235.646,814.75z"
                      />
                      <path
                        id="hms_43"
                        className="tibialis-anterior-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "tibialis-anterior-lt-front"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M382,738.25c-1.99-29.002-5.25-35.75-4.75-47.75c-3,17.25-3.5,32.75-6,44.75s-3.5,43.75-9,79.5s-7.25,39-7.75,42.5c-0.256,1.791,3.09,2.928,7.02,3.644c3.17-12.281,7.612-28.41,11.113-43.613c1.172-5.091,2.239-10.077,3.117-14.78C379.25,783.75,383.99,767.252,382,738.25z"
                      />
                      <path
                        id="hms_44"
                        className="peroneus-longus-rt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("peroneus-longus-rt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M215.896,738.25c1.99-29.002,5.25-35.75,4.75-47.75c-1.5,16.5-9.75,50-8.5,69.25s7.75,53,9.75,73.5s-0.25,29,4.25,28.75c1.194-0.066,3.005-0.186,5.011-0.391c1.011-1.391-4.011-28.359-5.894-44.329c-1.172-5.091-2.239-10.077-3.117-14.78C218.646,783.75,213.905,767.252,215.896,738.25z"
                      />
                      <path
                        id="hms_45"
                        className="peroneus-longus-lt-front"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("peroneus-longus-lt-front")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M382,738.25c1.99,29.002-2.75,45.5-6.25,64.25c-0.878,4.703-1.945,9.689-3.117,14.78c-1.883,15.97-6.905,42.938-5.894,44.329c2.006,0.205,3.816,0.324,5.011,0.391c4.5,0.25,2.25-8.25,4.25-28.75s8.5-54.25,9.75-73.5s-7-52.75-8.5-69.25C376.75,702.5,380.01,709.248,382,738.25z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="col-6">
                  <div id="hms-baseb">
                    <svg
                      style={{ pointerEvents: bodyLoader ? "none" : "" }}
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 600 980"
                      xmlSpace="preserve"
                      onClick={(e) =>
                        selectedPatient?.avatarCoordinates
                          ? ""
                          : avatarValues(e.target)
                      }
                    >
                      <image
                        overflow="visible"
                        width="600"
                        height="980"
                        xlinkHref={IMAGES.MUSCLE_MODEL_B}
                      />
                      <path
                        id="hms_46"
                        className="trapezius-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("trapezius-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M345.16,258.599c1.212-4.367,1.998-9.232,2.173-14.766c0.333-10.516,4.585-20.167,5.751-30s4.25-14.333,5.417-22.417c-3,1.667-6.5,0.25-4.583-4s22.416-1.75,22.416-1.75s0,0,6.5,0.417s9.5,0,10.667-3.583c-5.084-3.417-10.969-4.728-16.251-7.25S360,163.5,351.75,158.5s-20.302-10.94-23.25-17.75c-3.499-8.083-12.613-11.661-13.25-27c-0.083-2,2.001-10.25,0.584-11.75s-8.917-2.667-9.5,0s-1.894,18.099-0.084,23.25c2.289,6.516-3.111,7.944-7.291,8.22c1.754,0.116,3.294,0.03,3.959,0.03c-0.665,0-2.205,0.085-3.959-0.03c-1.754,0.116-3.294,0.03-3.959,0.03c0.665,0,2.205,0.085,3.959-0.03c-4.18-0.276-9.58-1.704-7.291-8.22c1.81-5.151,0.499-20.583-0.084-23.25s-8.083-1.5-9.5,0s0.667,9.75,0.584,11.75c-0.637,15.339-9.751,18.917-13.25,27c-2.948,6.81-15,12.75-23.25,17.75s-20.218,14.228-25.5,16.75s-11.167,3.833-16.251,7.25c1.167,3.583,4.167,4,10.667,3.583s6.5-0.417,6.5-0.417s20.499-2.5,22.416,1.75s-1.583,5.667-4.583,4c1.167,8.083,4.251,12.583,5.417,22.417s5.418,19.484,5.751,30c0.175,5.534,0.961,10.398,2.173,14.766c4.556,16.415,15.122,25.798,21.826,37.234c8.5,14.5,10.334,29.167,17.334,39.333c1.699,2.468,4.407,5.147,7.041,7.53c2.634-2.383,5.342-5.062,7.041-7.53c7-10.167,8.834-24.833,17.334-39.333C330.038,284.397,340.604,275.014,345.16,258.599z"
                      />
                      <path
                        id="hms_47"
                        className="infraspinatus-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        style={{
                          fill: avatarData?.includes("infraspinatus-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        vectorEffect="non-scaling-stroke"
                        d="M347.333,243.833c-0.175,5.534-0.961,10.398-2.173,14.766c4.379,1.859,7.465,2.437,10.106,2.198c3.714-0.335,8.622-2.706,10.858-4.547c8.5-7,10-22.125,13.875-30.5c0.555-1.198,1.143-2.314,1.756-3.348C385.431,216.213,390.037,213,394,213c-6.625-3.125-16.255-7.442-21-10.875c-1.771-1.281-9.5-7.375-14.499-10.708c-1.167,8.083-4.251,12.583-5.417,22.417S347.666,233.318,347.333,243.833z"
                      />
                      <path
                        id="hms_48"
                        className="infraspinatus-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("infraspinatus-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M203.918,213c3.963,0,8.569,3.213,12.244,9.402c0.613,1.033,1.201,2.149,1.756,3.348c3.875,8.375,5.375,23.5,13.875,30.5c2.236,1.841,7.145,4.212,10.858,4.547c2.642,0.238,5.728-0.339,10.106-2.198c-1.212-4.367-1.998-9.232-2.173-14.766c-0.333-10.516-4.585-20.167-5.751-30s-4.25-14.333-5.417-22.417c-4.999,3.333-12.729,9.427-14.499,10.708C220.173,205.558,210.543,209.875,203.918,213z"
                      />
                      <path
                        id="hms_49"
                        className="teres-major-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("teres-major-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M380,225.75c-3.875,8.375-5.375,23.5-13.875,30.5c-2.236,1.841-7.145,4.212-10.858,4.547c5.733,0.87,16.9,2.703,22.233,1.37s10-3.167,12.75-2.667c-0.889-1.615-1.725-11.372,0.021-19.829c-1.021-8.634-8.092-12.2-8.515-17.269C381.143,223.436,380.555,224.552,380,225.75z"
                      />
                      <path
                        id="hms_50"
                        className="teres-major-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("teres-major-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M217.918,225.75c-0.555-1.198-1.143-2.314-1.756-3.348c-0.423,5.069-7.494,8.635-8.515,17.269c1.745,8.457,0.909,18.213,0.021,19.829c2.75-0.5,7.417,1.333,12.75,2.667s16.5-0.5,22.233-1.37c-3.714-0.335-8.622-2.706-10.858-4.547C223.293,249.25,221.793,234.125,217.918,225.75z"
                      />
                      <path
                        id="hms_51"
                        className="latissimus-dorsi-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("latissimus-dorsi-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M377.5,262.167c-5.333,1.333-16.5-0.5-22.233-1.37c-2.642,0.238-5.728-0.339-10.106-2.198c-4.556,16.415-15.122,25.798-21.826,37.234C314.834,310.333,313,325,306,335.167c11.166,7.167,10.834,10.167,19.5,17.333c9.122,7.544,14.333,18.333,14.833,30.667s2.167,22.667,13.667,27.5c-1.333-10,1.166-15,4.5-23.333s4.5-20.333,6.833-29.5S369.58,328.5,378,313c4.803-8.842,12.5-30.833,15.501-47.125c-0.499-1.125-1.876-3.875-3.251-6.375C387.5,259,382.833,260.833,377.5,262.167z"
                      />
                      <path
                        id="hms_52"
                        className="latissimus-dorsi-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("latissimus-dorsi-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M252.758,258.599c-4.379,1.859-7.465,2.437-10.106,2.198c-5.733,0.87-16.9,2.703-22.233,1.37s-10-3.167-12.75-2.667c-1.375,2.5-2.752,5.25-3.251,6.375c3.001,16.292,10.698,38.283,15.501,47.125c8.42,15.5,10.334,35.667,12.667,44.833s3.499,21.167,6.833,29.5s5.833,13.333,4.5,23.333c11.5-4.833,13.167-15.167,13.667-27.5s5.711-23.123,14.833-30.667c8.666-7.167,8.334-10.167,19.5-17.333c-7-10.167-8.834-24.833-17.334-39.333C267.88,284.397,257.313,275.014,252.758,258.599z"
                      />
                      <path
                        id="hms_53"
                        className="deltoid-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("deltoid-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M394,213c6.625,3.125,14.072,3.591,20.875,9c2.989,2.377,5.708,4.287,8.211,5.921c6.187,4.039,11.054,6.395,15.414,9.954c-0.625-5.125-2.25-16.25-9-28.125s-20.332-21.75-29.999-24.583c-1.001,9.208-25.333,6.167-29.917,5.5c-2.051-0.298-9.334-1.792-11.083,0.75c4.999,3.333,12.729,9.427,14.499,10.708C377.745,205.558,387.375,209.875,394,213z"
                      />
                      <path
                        id="hms_54"
                        className="deltoid-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("deltoid-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M183.043,222c6.803-5.409,14.25-5.875,20.875-9s16.255-7.442,21-10.875c1.771-1.281,9.5-7.375,14.499-10.708c-1.749-2.542-9.032-1.048-11.083-0.75c-4.584,0.667-28.916,3.708-29.917-5.5c-9.667,2.833-23.249,12.708-29.999,24.583s-8.375,23-9,28.125c4.36-3.56,9.228-5.916,15.414-9.954C177.335,226.287,180.054,224.377,183.043,222z"
                      />
                      <path
                        id="hms_55"
                        className="triceps-brachii-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("triceps-brachii-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M454.5,355.667c2.455,0.223,11.724-3.017,14.713-8.008c1.475-2.463-0.129-6.074,0.12-8.492c2.25-21.833-1.667-29.417-3-35s-6.669-23.829-9.667-30.167s-7.583-16.833-12.499-21.667c-1.896-1.864-5.002-9.89-5.667-14.458c-4.36-3.56-9.228-5.916-15.414-9.954c-5.211-2.046-18.961-5.921-27.211,0.579c-2.919,2.3-4.648,6.541-5.604,11.171c-1.745,8.457-0.909,18.213-0.021,19.829c1.375,2.5,2.752,5.25,3.251,6.375s6.249,14.375,11.624,22.875s15.714,23.917,17.961,28.917s4.602,10.396,6.247,14.667C433.063,339.938,450.834,355.334,454.5,355.667z"
                      />
                      <path
                        id="hms_56"
                        className="triceps-brachii-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("triceps-brachii-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M207.647,239.671c-0.956-4.631-2.686-8.872-5.604-11.171c-8.25-6.5-22-2.625-27.211-0.579c-6.187,4.039-11.054,6.395-15.414,9.954c-0.665,4.568-3.771,12.595-5.667,14.458c-4.916,4.833-9.501,15.329-12.499,21.667s-8.334,24.583-9.667,30.167s-5.25,13.167-3,35c0.249,2.418-1.354,6.029,0.12,8.492c2.989,4.991,12.258,8.231,14.713,8.008c3.666-0.333,21.438-15.729,25.167-23.333c1.646-4.271,4-9.667,6.247-14.667s12.586-20.417,17.961-28.917s11.125-21.75,11.624-22.875s1.876-3.875,3.251-6.375C208.557,257.885,209.393,248.128,207.647,239.671z"
                      />
                      <path
                        id="hms_57"
                        className="extensor-carpi-ulnaris-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "extensor-carpi-ulnaris-rt-back"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M511,416c-3.666-12.5-8.5-23.197-12.5-35.667s-7.911-17.563-9.834-24c-3.275-10.965-3.165-13.667-7.02-20c-2.176-3.576-2.98-5-3.48,11.667s3.834,29.5,7.001,36.333c10.833,15.833,25.166,42,28.666,48.333c1.15-0.456,3.251-1.25,4.418-1.833C516.084,428.25,513.613,424.911,511,416z"
                      />
                      <path
                        id="hms_58"
                        className="extensor-carpi-ulnaris-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "extensor-carpi-ulnaris-lt-back"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M119.752,348c-0.5-16.667-1.305-15.242-3.48-11.667c-3.854,6.333-3.744,9.035-7.02,20c-1.923,6.437-5.834,11.53-9.834,24S90.584,403.5,86.918,416c-2.613,8.911-5.084,12.25-7.251,14.833c1.167,0.583,3.268,1.377,4.418,1.833c3.5-6.333,17.833-32.5,28.666-48.333C115.918,377.5,120.252,364.667,119.752,348z"
                      />
                      <path
                        id="hms_59"
                        className="flexor-carpi-ulnaris-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "flexor-carpi-ulnaris-rt-back"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M454.5,355.667c-3.666-0.333-21.438-15.729-25.167-23.333c3.417,10.604,2.835,14.333,11.168,30.5s14.166,23.667,24.5,31s25.667,15,30.333,19.167s11.249,15.083,14.166,21c0.667,0,2.333-0.5,4.333-1.333c-3.5-6.333-17.833-32.5-28.666-48.333S459.667,358.5,454.5,355.667z"
                      />
                      <path
                        id="hms_60"
                        className="flexor-carpi-ulnaris-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes(
                            "flexor-carpi-ulnaris-lt-back"
                          )
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M143.418,355.667c-5.167,2.833-19.834,12.833-30.667,28.667s-25.166,42-28.666,48.333c2,0.833,3.666,1.333,4.333,1.333c2.917-5.917,9.5-16.833,14.166-21s19.999-11.833,30.333-19.167s16.167-14.833,24.5-31s7.751-19.896,11.168-30.5C164.855,339.938,147.084,355.334,143.418,355.667z"
                      />
                      <path
                        id="hms_61"
                        className="gluteus-medius-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-medius-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M373.833,464c-0.333-8.833-0.166-30.333-1.833-37.167s-4.167-13.667-5.667-17.167c-2.35,1.44-12.353,6.086-23.251,12.041C364,438,372.333,458.5,373.833,464z"
                      />
                      <path
                        id="hms_62"
                        className="gluteus-medius-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-medius-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M254.836,421.708c-10.898-5.955-20.901-10.601-23.251-12.041c-1.5,3.5-4,10.333-5.667,17.167s-1.5,28.333-1.833,37.167C225.585,458.5,233.918,438,254.836,421.708z"
                      />
                      <path
                        id="hms_63"
                        className="gluteus-maximus-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-maximus-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M331.666,530.167c2.456,0.105,5.088,0.011,7.795-0.301c0.886-0.102,1.779-0.227,2.678-0.375c13.68-2.266,28.403-10.022,31.694-25.157c4.167-19.167,1.5-34.834,0-40.334s-9.833-26-30.751-42.292C330.017,428.847,298.833,447,301,494.667c0.682,14.985,4.332,18.333,6.166,22.5S316.166,529.5,331.666,530.167z"
                      />
                      <path
                        id="hms_64"
                        className="gluteus-maximus-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gluteus-maximus-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M254.836,421.708C233.918,438,225.585,458.5,224.085,464s-4.167,21.167,0,40.334c3.291,15.135,18.015,22.892,31.694,25.157c0.898,0.148,1.792,0.273,2.678,0.375c2.707,0.312,5.339,0.406,7.795,0.301c15.5-0.667,22.666-8.833,24.5-13s5.484-7.515,6.166-22.5C299.085,447,267.901,428.847,254.836,421.708z"
                      />
                      <path
                        id="hms_65"
                        className="vastus-lateralis-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("vastus-lateralis-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M376.594,568.178c1.42,9.265,2.193,20.1,1.406,31.988C380.5,613,380.334,633.5,381.5,645c2-12.5,6.833-53.167,8.833-67.5s1.667-38.667-7.166-76.167C375.501,539,375.833,554.833,376.594,568.178z"
                      />
                      <path
                        id="hms_66"
                        className="vastus-lateralis-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("vastus-lateralis-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M221.324,568.178c0.761-13.345,1.093-29.178-6.573-66.845c-8.833,37.5-9.166,61.834-7.166,76.167s6.833,55,8.833,67.5c1.166-11.5,1-32,3.5-44.834C219.131,588.277,219.904,577.442,221.324,568.178z"
                      />
                      <path
                        id="hms_67"
                        className="biceps-femoris-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("biceps-femoris-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M376.594,568.178c-2.796-18.24-8.098-30.393-8.927-32.511c-3-7.667-13.804-5.355-25.528-6.176c10.972,14.491,11.528,27.342,11.528,27.342s0,0,1.333,10.667c1.063,8.507,1.278,32.169,0.899,53.322c-0.096,5.373-0.23,10.584-0.399,15.344c-0.833,23.5,6.5,31.334,6.5,31.334s0.998,1.827,2.527,4.668c3.955,7.348,11.468,21.48,14.473,28.332c0.167-6.333-0.256-27.398-0.941-35.333C376,641.333,376.5,622.833,378,600.166C378.787,588.277,378.014,577.442,376.594,568.178z"
                      />
                      <path
                        id="hms_68"
                        className="biceps-femoris-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("biceps-femoris-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M235.918,667.5c0,0,7.333-7.834,6.5-31.334c-0.169-4.76-0.304-9.971-0.399-15.344c-0.379-21.153-0.164-44.815,0.899-53.322c1.333-10.667,1.333-10.667,1.333-10.667s0.557-12.851,11.528-27.342c-11.725,0.82-22.528-1.491-25.528,6.176c-0.829,2.118-6.131,14.271-8.927,32.511c-1.42,9.265-2.193,20.1-1.406,31.988c1.5,22.667,2,41.167-0.059,65.001c-0.686,7.935-1.108,29-0.941,35.333c3.005-6.852,10.518-20.984,14.473-28.332C234.92,669.327,235.918,667.5,235.918,667.5z"
                      />
                      <path
                        id="hms_69"
                        className="semitendinosus-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("semitendinosus-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M351.833,645c0.667-8.167,3.3-18.855,4.066-24.178c0.379-21.153,0.164-44.815-0.899-53.322c-1.333-10.667-1.333-10.667-1.333-10.667s-0.557-12.851-11.528-27.342c-0.898,0.148-1.792,0.273-2.678,0.375c-2.039,3.533-3.961,13.8-6.628,33.467c-1.167,25.834,2.5,18,1.5,45.5s2.666,40.334,1.333,59.334s-6.167,29.833-4,44.333c1.957-11.167,10.409-27.063,11.5-33.5C344.833,669.167,351.382,650.521,351.833,645z"
                      />
                      <path
                        id="hms_70"
                        className="semitendinosus-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("semitendinosus-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M262.252,668.167c-1.333-19,2.333-31.834,1.333-59.334s2.667-19.666,1.5-45.5c-2.667-19.667-4.589-29.934-6.628-33.467c-0.886-0.102-1.779-0.227-2.678-0.375c-10.972,14.491-11.528,27.342-11.528,27.342s0,0-1.333,10.667c-1.063,8.507-1.278,32.169-0.899,53.322c0.767,5.322,3.399,16.011,4.066,24.178c0.451,5.521,7,24.167,8.667,34c1.091,6.437,9.543,22.333,11.5,33.5C268.419,698,263.585,687.167,262.252,668.167z"
                      />
                      <path
                        id="hms_71"
                        className="semimembranosus-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("semimembranosus-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M335.666,668.167c1.333-19-2.333-31.834-1.333-59.334s-2.667-19.666-1.5-45.5c-2.667,19.667-7.333,35-8.167,50.167c-0.409,7.439-1.734,31.027-2.666,40.666c0,27.5,8.26,48.952,9.666,58.334C329.499,698,334.333,687.167,335.666,668.167z"
                      />
                      <path
                        id="hms_72"
                        className="semimembranosus-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("semimembranosus-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M265.085,563.333c1.167,25.834-2.5,18-1.5,45.5s-2.666,40.334-1.333,59.334s6.167,29.833,4,44.333c1.406-9.382,9.666-30.834,9.666-58.334c-0.932-9.639-2.257-33.227-2.666-40.666C272.418,598.333,267.752,583,265.085,563.333z"
                      />
                      <path
                        id="hms_73"
                        className="adductor-magnus-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("adductor-magnus-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M332.833,563.333c2.667-19.667,4.589-29.934,6.628-33.467c-2.707,0.312-5.339,0.406-7.795,0.301c-15.5-0.667-22.666-8.833-24.5-13C306.166,539,306,559,309.833,576.5c1.607,7.338,4.771,20.476,6.841,33.974c2.866,18.694,4.552,38.078,5.326,43.692c0.932-9.639,2.257-33.227,2.666-40.666C325.5,598.333,330.166,583,332.833,563.333z"
                      />
                      <path
                        id="hms_74"
                        className="adductor-magnus-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("adductor-magnus-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M266.252,530.167c-2.456,0.105-5.088,0.011-7.795-0.301c2.039,3.533,3.961,13.8,6.628,33.467s7.333,35,8.167,50.167c0.409,7.439,1.734,31.027,2.666,40.666c0.774-5.614,2.46-24.998,5.326-43.692c2.069-13.498,5.233-26.636,6.841-33.974c3.833-17.5,3.667-37.5,2.667-59.333C288.918,521.334,281.752,529.5,266.252,530.167z"
                      />
                      <path
                        id="hms_75"
                        className="gastrocnemius-rt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gastrocnemius-rt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M350.333,691.5c-1.833-3.167-4.985-6.974-7.167-12.5c-1.091,6.437-9.543,22.333-11.5,33.5c1.999,13.334-3.499,37-4.833,55.666s2.167,28,4.167,34.167s7.283,39.305,7.833,41.833c0.834,3.834,21.834,4,22.667,0c1.727-8.29,6.833-20.5,16.167-39.333s8.333-39.5,7.666-50.5S379.5,716,379,700.5c-3.005-6.852-10.518-20.984-14.473-28.332C354.833,677.5,350.333,691.5,350.333,691.5z"
                      />
                      <path
                        id="hms_76"
                        className="gastrocnemius-lt-back"
                        fill="transparent"
                        stroke="#8C8C8C"
                        vectorEffect="non-scaling-stroke"
                        style={{
                          fill: avatarData?.includes("gastrocnemius-lt-back")
                            ? "#ff00005c"
                            : "rgba(255, 0, 0, 0)",
                        }}
                        d="M247.585,691.5c0,0-4.5-14-14.194-19.332c-3.955,7.348-11.468,21.48-14.473,28.332c-0.5,15.5-5.666,42.833-6.333,53.833s-1.668,31.667,7.666,50.5s14.44,31.043,16.167,39.333c0.833,4,21.833,3.834,22.667,0c0.55-2.528,5.833-35.666,7.833-41.833s5.501-15.501,4.167-34.167s-6.832-42.332-4.833-55.666c-1.957-11.167-10.409-27.063-11.5-33.5C252.57,684.526,249.418,688.333,247.585,691.5z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
MusclesStructure.propTypes = {
  setAvatarData: PropTypes.func.isRequired,
  avatarData: PropTypes.func.isRequired,
  selectedPatient: PropTypes.func.isRequired,
  bodyLoader: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
