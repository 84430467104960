import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "../../Components";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import {Formik, Field, Form, FieldArray, useFormikContext} from "formik";
import { toast } from "react-toastify";
import { FaPlus, FaMinus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getSpecializationQuestionsList,
  getSuggestedQuestion,
  saveSpecializationQuestionsList,
} from "../../Redux/actions/specializationQuestionsAction";

import { Form as BootstrapForm } from "react-bootstrap";
import CustomMultiSelect from "../Home/CustomMultiselect";

const consultantSpecializationList = [
  { id: 1, title: "Physician", key: "physician" },
  { id: 2, title: "Gastroenterologist", key: "gastroenterologist" },
  { id: 3, title: "Pulmonologist", key: "pulmonologist" },
  { id: 4, title: "Urologist", key: "urologist" },
  { id: 5, title: "Orthopedic", key: "orthopedic" },
];

const doctorList = [
  { id: 1, title: "Abid", key: "abid" },
  { id: 2, title: "Ahmad", key: "ahmad" },
  { id: 3, title: "Awais", key: "awais" },
  { id: 4, title: "Ahsan", key: "ahsan" },
];

const clinicList = [
  { id: 1, title: "Jeddah", key: "Jeddah" },
  { id: 2, title: "Riyadh", key: "Riyadh" },
  { id: 3, title: "Mecca", key: "Mecca" },
  { id: 4, title: "Medina", key: "Medina" },
];

export default function AddQuestionsAgainstSpecialization({
  onHide,
  setDoctorInfo,
  initialValues,
  speciality,
  getSaveQuestions,
  questionList
}) {
  const { width } = useWindowSizeHook();
  const dispatch = useDispatch();
  const inputRefs = useRef({});

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [updateSuggestedQuestion, setUpdateSuggestedQuestion] = useState([]);
  const [doctorData, setDoctorData] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(
    initialValues.specializationId || speciality
  );

  const {GET_SUGGESTED_QUESTIONS} = useSelector(
    (state) => state.specializationQuestions
  );

  const {GET_SPECIALIZATION_QUESTIONS} = useSelector(
      (state) => state.specializationQuestions
  );



  useEffect(() => {
    const selectedSpecialityKey = consultantSpecializationList.find(
      (speciality) => speciality.id === parseInt(selectedSpeciality)
    );

    if (selectedSpecialityKey) {
      dispatch(
        getSuggestedQuestion(selectedSpecialityKey.key, selectedLanguage)
      );
    }
  }, [dispatch, selectedSpeciality, selectedLanguage]);

  useEffect(() => {
    if (GET_SUGGESTED_QUESTIONS?.data) {
      setUpdateSuggestedQuestion(
        GET_SUGGESTED_QUESTIONS.data
      );
    }
  }, [GET_SUGGESTED_QUESTIONS?.data]);

  // const CLINIC_OPTIONS=[
  //   id:"1",
  //   name:""
  // ]

  useEffect(() => {
    if(initialValues?.questions){
      inputRefs?.current?.setFieldValue("questions", initialValues.questions || []);
    }
  }, [initialValues]);

  useEffect(() => {
    if(GET_SPECIALIZATION_QUESTIONS?.data){
      const questionList = GET_SPECIALIZATION_QUESTIONS?.data?.questions;
        const quest =  [...questionList?.map(item => ({
          question: item.Question,
          questionType: ["Multi-Select", "Single-Select", "Text"],
          options: item.Symptoms?.map(symptom => ({label: symptom, value: symptom})) ?? [],
          selectedType: item.QuestionType === "ms" ? "Multi-Select" : item.QuestionType === "us" ? "Single-Select" : "Text",
        })),{
          question: "",
          questionType: ["Multi-Select", "Single-Select", "Text"],
          options: [],
          symptoms: [""],
          selectedType: "",
        }
        ] ?? [{question: "", symptoms: [""]}]
        inputRefs.current.setFieldValue("questions", quest);
    }
  }, [GET_SPECIALIZATION_QUESTIONS]);


  return (
    <div className="mx-auto">
      {/* <div style={{ width: "80%" }} className="mx-auto">
      <LayoutNavbar />
    </div> */}
      <Box
        width="80%"
        maxWidth="202rem"
        borderRadius="1.375rem"
        background="linear-gradient(rgb(255 255 255 / 75%) 0%, rgba(255, 255, 255, 0.7) 100%)"
        // background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className={`${width > 600 ? "m-auto p-5" : "p-3 m-auto"}`}
      >
        <Formik
          initialValues={{
            specializationId: selectedSpeciality || "",
            questions: initialValues.questions || [],
          }}
          innerRef={inputRefs}
          onSubmit={(values) => {
            const { specializationId, questions } = values;
            if (!specializationId) {
              return toast.error("Please select specialization");
            }
            const questionData = {
              specializationId,
              questions: questions.filter(itm => itm.question?.length).map((question) => ({
                question: question.question,
                questionType:
                  question.selectedType === "Multi-Select"
                    ? "ms"
                    : question.selectedType === "Single-Select"
                    ? "us"
                    : "text",
                symptoms:
                  question.selectedType !== "Text"
                    ? question.options.map((symptom) => symptom.label)
                    : [],
              })),
            };

            dispatch(
              saveSpecializationQuestionsList(questionData, (response) => {
                if (response?.data?.httpStatusCode === 200) {
                  toast.success(response.data.message);
                  dispatch(getSpecializationQuestionsList(selectedSpeciality));
                  onHide();
                } else {
                  toast.error(response.data.message);
                }
              })
            );
            setDoctorInfo(questionData);
            onHide();
          }}
        >
          {({ values, setFieldValue }) => {
            const handleSpecializationChange = (event) => {
              const selectedId = event.target.value;
              setSelectedSpeciality(selectedId);
              setFieldValue("specializationId", selectedId);
              if (selectedId === "1") {
                // Set predefined questions for Physician
                setFieldValue("questions", initialValues?.questions);
              } else {
                setFieldValue("questions", [{ question: "", symptoms: [""] }]);
              }
            };

            const handleAddSuggestedQuestion = (question) => {
              // Check if the limit of 5 questions is reached
              if (values.questions.length >= 5) {
                toast.error("You can only add up to 5 questions.");
                return;
              }

              // Add the selected question with its options to the questions field
              const newQuestion = {
                question: question.question,
                symptoms: question.options || [],
              };

              // Update the questions field in the form state
              setFieldValue("questions", [...values.questions, newQuestion]);

              // Remove the added question from the suggested questions
              const updatedSuggestedQuestions = updateSuggestedQuestion.filter(
                (item) => item.question !== question.question
              );
              setUpdateSuggestedQuestion(updatedSuggestedQuestions);
            };

            return (
              <Form className="flex justify-items-center w-100 px-2 px-md-5 p-4 mb-4">
                <Typography as={width < 1300 ? "h5" : "h2"} align="center">
                  Welcome to Pre Consultant
                </Typography>
                <Typography
                  as="p"
                  align="center"
                  color="var(--logo-black-700, #4A4F4D)"
                  className="pb-5 px-2"
                >
                  Your AI Health Assistant! Gather essential health information
                  to streamline your doctor's consultation, all from the comfort
                  of your screen.
                </Typography>
                <div className="row">
                  <BootstrapForm.Group className="mb-4 col-3">
                    <BootstrapForm.Label className="fw-bold mb-2">
                      Language
                    </BootstrapForm.Label>
                    <BootstrapForm.Select
                      aria-placeholder="Select"
                      value={selectedLanguage}
                      onChange={(e) => {
                        setSelectedLanguage(e.target.value);
                        const selectedSpecialityKey =
                          consultantSpecializationList.find(
                            (speciality) =>
                              speciality.id === parseInt(selectedSpeciality)
                          );
                        if (selectedSpecialityKey) {
                          dispatch(
                            getSuggestedQuestion(
                              selectedSpecialityKey.key,
                              e.target.value
                            )
                          );
                        }
                      }}
                    >
                      <option value="">Select</option>
                      <option value="en">English</option>
                      <option value="ar">Arabic</option>
                    </BootstrapForm.Select>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mb-4 col-3">
                    <BootstrapForm.Label className="fw-bold mb-2">
                      Speciality
                    </BootstrapForm.Label>
                    <BootstrapForm.Select
                      aria-placeholder="Select"
                      value={selectedSpeciality}
                      onChange={(e) => {
                        handleSpecializationChange(e);
                        getSaveQuestions(e.target.value);
                      }}
                    >
                      {consultantSpecializationList?.map((item) => (
                        <option key={item.key} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </BootstrapForm.Select>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mb-4 col-3">
                    <BootstrapForm.Label className="fw-bold mb-2">
                      Clinic
                    </BootstrapForm.Label>
                    <BootstrapForm.Select
                      aria-placeholder="Select"
                      value={clinicData}
                      onChange={(e) => {
                        setClinicData(e.target.value);
                      }}
                    >
                      {clinicList?.map((item) => (
                        <option key={item.key} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </BootstrapForm.Select>
                  </BootstrapForm.Group>
                  <BootstrapForm.Group className="mb-4 col-3">
                    <BootstrapForm.Label className="fw-bold mb-2">
                      Doctor
                    </BootstrapForm.Label>
                    <BootstrapForm.Select
                      aria-placeholder="Select"
                      value={doctorData}
                      onChange={(e) => {
                        setDoctorData(e.target.value);
                      }}
                    >
                      {doctorList?.map((item) => (
                        <option key={item.key} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </BootstrapForm.Select>
                  </BootstrapForm.Group>
                </div>
                <Typography
                  align="left"
                  fontSize={width < 500 ? "1rem" : "1.2rem"}
                  fontWeight="500"
                  className="mb-3 fw-bold border-bottom-dashed"
                >
                  Add Questions
                </Typography>

                <FieldArray name="questions">
                  {({ push, remove }) => (
                    <>
                      {values?.questions?.map((question, index) => (
                        <Box
                          key={index}
                          width="100%"
                          height="100%"
                          background="#fff"
                          className="mb-3 border rounded p-3"
                        >
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="fw-bold">{`Question ${
                              index + 1
                            }`}</label>
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="bg-transparent border-0"
                            >
                              <FaTrash />
                            </button>
                          </div>

                          <Field
                            name={`questions.${index}.question`}
                            placeholder={`Enter Question ${index + 1}`}
                            className="form-control mb-2 qutions-field"
                            onChange={(e) => {
                              const { value } = e.target;
                              // Update the question field in Formik state
                              setFieldValue(
                                `questions.${index}.question`,
                                value
                              );
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault(); // Prevent the default behavior of the Enter key
                              }
                            }}
                          />
                          {question?.question && (
                            <div>
                              <BootstrapForm.Label className="fw-bold mt-3">
                                Question type
                              </BootstrapForm.Label>
                              <div className="d-flex align-items-center mb-2 gap-3">
                                {question?.questionType?.length > 0 &&
                                  question?.questionType?.map(
                                    (type, questionIndex) => {
                                      const isSelected =
                                        question.selectedType === type; // Modify based on your state management
                                      return (
                                        <button
                                          key={questionIndex} // Added key for each button
                                          id={`checkbox-${index}`}
                                          onClick={() => {
                                            setFieldValue(
                                              `questions.${index}.selectedType`,
                                              type
                                            ); // Update selectedType on click
                                          }}
                                          className={`suggested-tabs-style border ${
                                            isSelected ? "selected-tab" : ""
                                          }`}
                                          type="button"
                                        >
                                          <Typography
                                            as="label"
                                            // color="#fff"
                                            className="fw-bold"
                                            style={{
                                              marginTop: "auto",
                                              marginBottom: "auto", // Vertically center the text
                                              color: "#fff",
                                            }}
                                          >
                                            {type}
                                          </Typography>
                                        </button>
                                      );
                                    }
                                  )}
                              </div>
                              {question.selectedType === "Multi-Select" ||
                              question.selectedType === "Single-Select" ? (
                                <CustomMultiSelect
                                  name={`questions.${index}.options`}
                                  placeholder="Enter Options"
                                  onChange={(updatedOptions) => {
                                    setFieldValue(
                                      `questions.${index}.options`,
                                      updatedOptions
                                    ); // Update Formik state
                                  }}
                                  enableAddButton
                                />
                              ) : null}
                            </div>
                          )}
                        </Box>
                      ))}

                      {/* Only allow adding a new question if less than 5 questions are present */}
                      {values.questions.length < 5 && (
                        <Box className="d-flex justify-content-end">
                          <Button
                            type="button"
                            onClick={() =>
                              push({
                                question: "",
                                questionType: [
                                  "Multi-Select",
                                  "Single-Select",
                                  "Text",
                                ],
                                options: [],
                                symptoms: [""],
                                selectedType: "",
                              })
                            }
                            className="btn btn-primary btn-color"
                          >
                            Add To Bot
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </FieldArray>

                <div className="d-flex justify-content-end mt-4">
                  {/* <Button
                    onClick={() => onHide()}
                    className="btn btn-secondary bg-secondary mx-2"
                  >
                    Cancel
                  </Button> */}
                  <Button type="submit" className="btn btn-primary btn-color">
                    Train Your Bot
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </div>
  );
}
