import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { IMAGES } from "../Assets";
import { ROUTER_PATH } from "../config";
import useWindowSizeHook from "../Components/WindowSizeHook";

export default function AuthLayout() {
  const location = useLocation();
  const { width: useWidth } = useWindowSizeHook();
  return (
    <div className="">
      {[
        ROUTER_PATH.SIGN_IN,
        ROUTER_PATH.SIGN_UP,
        ROUTER_PATH.FORGOT_PASSWORD,
        ROUTER_PATH.VERIFY_OTP,
        ROUTER_PATH.RESET_PASSWORD,
      ]?.includes(location?.pathname) && (
        <>
          <img
            src={IMAGES.BACKGROUND}
            alt="background"
            className="bg-video d-none d-lg-block"
          />

          <div
            style={{
              position: "relative",
              width: "100%",
            }}
          >
            <img
              src={IMAGES.MEDISTREAMLINE_LOGO}
              alt="background"
              className="  d-lg-block "
              style={{
                width:
                  useWidth < 400 ? "190px" : useWidth < 500 ? "60px" : "100px",
                position: "absolute",
                top: useWidth < 400 ? "20px" : "50px",
                left:  "7%",  
               
              }}
            />
          </div>

          <img
            src={IMAGES.MINI_BACKGROUND}
            alt="background"
            className="mini-background d-block d-lg-none"
          />
        </>
      )}
      <Outlet />
    </div>
  );
}
