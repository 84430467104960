import SidebarLogo from "./SideBarLogo.svg";
import ChatAvatar from "./chat-avatar.svg";
import DateIcon from "./date_icon.svg";
import DoctorAvatar from "./doctor-avatar.svg";
import FemaleCharacter from "./female-standing.png";
import FolloUpIcon from "./follow_up_icon.svg";
import MaleCharacter from "./male-standing.png";
import Step1 from "./step_1.svg";
import Step2 from "./step_2.svg";
import Step3 from "./step_3.svg";
import Step4 from "./step_4.svg";
import Step5 from "./step_5.svg";
import Step6 from "./step_6.svg";
import ThankYouIcon from "./thank-you-icon.svg";
import TimeIcon from "./time_icon.svg";
import UserAvatar from "./user-avatar.svg";
// import FemaleDoctor from "./female_correct.mp4";
// import FemaleDoctor from "./Female-Video.mp4";
// import MaleDoctor from "./Male-Video.mp4";
import anatomyMouth from "./anatomy/Mouth.png";
import anatomyBrainLeft from "./anatomy/brain-left.png";
import anatomyEar from "./anatomy/ear.png";
import anatomyEye from "./anatomy/eye.png";
import anatomyMusclesFrontImage from "./anatomy/muscles-front-image.png";
import anatomyOrgansBg from "./anatomy/organs-bg.png";
import anatomySkeleton from "./anatomy/skeleton.png";
import anatomySpine1 from "./anatomy/spine1.png";
import anatomyFullBody from "./anatomy/full_body.png";
import { default as background, default as LOGIN_BG } from "./bg.png";
import backgroundOld from "./bg.png";
import BODY from "./body.svg";
import cardBackground from "./cardBackground.png";
import LOGIN_ASSET from "./doc-vector.png";
import FB_ICON from "./facebookIcon.svg";
import FEMALE_DOCTOR_AVATAR from "./femaleDoctoreAvatar.jpeg";
import GOOGLE_ICON from "./googleIcon.svg";
import logo from "./xeven-pre-consultant-logo.png";
import MALE_DOCTOR_AVATAR from "./maleDoctorAvatar.jpeg";
// import MUSCLE_MODEL_A from "./muscles/modela.png";
// import MUSCLE_MODEL_B from "./muscles/modelb.png";
import MUSCLE_MODEL_A from "./muscles/modela-v2.png";
import MUSCLE_MODEL_B from "./muscles/modelb-v2.png";
import xevenPreConsultantLogo from "./xeven-pre-consultant-logo.png";
import orthopedic from "./orthopedic.jpeg";
import urologist from "./urologist.png";
import pulmonologist from "./pulmonologist.png";
import physician from "./physician.png";
import gastroenterologist from "./gastroenterologist.png";

// import MaleDoctor from "./MaleDoctorTalking.mp4";

const IMAGES = {
  ORTHOPEIC: orthopedic,
  UROLOGIST: urologist,
  PULMONOLOGIST: pulmonologist,
  PHYSICIAN: physician,
  GASTROENTROLOGIST: gastroenterologist,
  SIDEBAR_LOGO: SidebarLogo,
  MEDISTREAMLINE_LOGO: logo,
  DATE_ICON: DateIcon,
  TIME_ICON: TimeIcon,
  FOLLOW_UP_ICON: FolloUpIcon,
  DOCTOR_AVATAR: DoctorAvatar,
  USER_AVATAR: UserAvatar,
  THANKYOU_ICON: ThankYouIcon,
  CHAT_AVATAR: ChatAvatar,
  STEP_ICON_1: Step1,
  STEP_ICON_2: Step2,
  STEP_ICON_3: Step3,
  STEP_ICON_4: Step4,
  STEP_ICON_5: Step5,
  STEP_ICON_6: Step6,
  MALE_CHARACTER: MaleCharacter,
  FEMALE_CHARACTER: FemaleCharacter,
  //	FEMALE_DOCTOR: FemaleDoctor,
  //	MALE_DOCTOR: MaleDoctor,
  MUSCLE_MODEL_A,
  MUSCLE_MODEL_B,

  XEVEN_PRE_CONSULTANT_LOGO: xevenPreConsultantLogo,
  BACKGROUND: background,
  MINI_BACKGROUND: backgroundOld,
  CARD_BACKGROUND: cardBackground,
  ANATOMY_MOUTH: anatomyMouth,
  ANATOMY_BRAIN_LEFT: anatomyBrainLeft,
  ANATOMY_EAR: anatomyEar,
  ANATOMY_EYE: anatomyEye,
  ANATOMY_MUSCLES_FRONT_IMAGE: anatomyMusclesFrontImage,
  ANATOMY_ORGANS_BG: anatomyOrgansBg,
  ANATOMY_SKELETON: anatomySkeleton,
  ANATOMY_SPINE_1: anatomySpine1,
  ANATOMY_FULL_BODY: anatomyFullBody,
  LOGIN_BG,
  LOGIN_ASSET,
  FB_ICON,
  GOOGLE_ICON,
  BODY,
  FEMALE_DOCTOR_AVATAR,
  MALE_DOCTOR_AVATAR,
};

export default IMAGES;
