import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  GenericLoader,
  GenericModal,
  Typography,
} from "../../Components";
import { getLoggedInUser } from "../../utils/helper";
import { ROUTER_PATH } from "../../config";
import { Formik, Form } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { saveChatAction } from "../../Redux/actions";
import { translateSuggestedQuestion } from "../../Redux/actions/specializationQuestionsAction";

const consultantSpecializationList = [
  { id: 1, title: "Physician", key: "physician" },
  { id: 2, title: "Gastroenterologist", key: "gastroenterologist" },
  { id: 3, title: "Pulmonologist", key: "pulmonologist" },
  { id: 4, title: "Urologist", key: "urologist" },
  { id: 5, title: "Orthopedic", key: "orthopedic" },
];

export default function SelectLanguage({ show, onHide, chat, gender }) {
  // const medUser = getLoggedInUser();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  let medUser = getLoggedInUser();

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  // const [formattedQuestions, setFormattedQuestions] = useState(null);

  const aiResponse = useSelector((state) => state.chat);
  const specializationQuestions = useSelector(
    (state) => state.specializationQuestions
  );

  const questionList = specializationQuestions?.TRANSLATE_QUESTIONS?.data;

  function moveToNext(response) {
    if (response.succeeded) {
      navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
        state: {
          gender: "male",
          // gender: chat?.CHAT[0]?.roleId === 101 ? "male" : "female",
          language: selectedLanguage,
          specialization: selectedSpecialization,
          // questions: formattedQuestions,
          suggestedResponse: response?.data,
        },
      });
    } else toast.error(response.message);
    console.log("response", response);
  }

  function getSpecializationAnswerList(id) {
    const selectedSpecialityKey = consultantSpecializationList.find(
      (speciality) => speciality.id === parseInt(id)
    );

    const payload = {
      doctorField: selectedSpecialityKey?.key,
      user_language: selectedLanguage,
      current_index: 0,
      db: {
        fromUserId: medUser?.userId,
        toUserId: "a94ca6cf-8251-44b5-b98c-aadf8393fda6",
        chatId: aiResponse?.CHAT?.[0]?.chatId || aiResponse?.CHAT_ID?.data,
      },
      is_last_message: false,
      is_first_message: true,
    };

    dispatch(translateSuggestedQuestion(payload, moveToNext));
  }

  const handleChatLanguage = async (values) => {
    console.log(values, "values");
    if (!selectedLanguage) {
      return toast.error("Please select language");
    }

    if (!selectedSpecialization) {
      return toast.error("Please select specialization");
    }

    // if (
    //   values.answers.length < 5 ||
    //   values.answers.some((answer) => answer.trim() === "")
    // ) {
    //   return toast.error("Please answer all questions");
    // }

    // const formattedAnswers = questionList?.questions.map((question, index) => ({
    //   id: `question_${index + 1}`,
    //   question: question,
    //   symptoms: values.symptoms[index] || [],
    // }));

    // let finalData = {
    //   doctorField: selectedSpecialization,
    //   query_language: selectedLanguage,
    //   // questions: formattedAnswers,
    //   gender: medUser?.gender,
    //   Id: 0,
    // };
    // setFormattedQuestions(formattedAnswers);

    // dispatch(getWelcomeMessage(finalData, moveToNext));
    getSpecializationAnswerList(selectedSpecialization);

    // navigate(ROUTER_PATH.PATIENT_DIAGNOSIS, {
    //   state: {
    //     gender: chat?.CHAT[0]?.roleId === 101 ? "male" : "female",
    //     language: selectedLanguage,
    //     specialization: selectedSpecialization,
    //     questions: formattedAnswers,
    //   },
    // });
  };

  // const handleSelectChange = (e) => {
  //   const selectedOptions = Array.from(
  //     e.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setSelectedSymptoms(selectedOptions);

  // };

  // const direction = selectedLanguage === "ar" ? "rtl" : "ltr";
  useEffect(() => {
    if (!aiResponse?.CHAT?.[0]?.chatId || aiResponse?.MESSAGE?.[0]?.chatId) {
      dispatch(
        saveChatAction({
          name: "",
          fromUserId: medUser?.userId,
          toUserId: "a94ca6cf-8251-44b5-b98c-aadf8393fda6",
          roleId: 101,
          questionAnswers: [],
        })
      );
    }
  }, []);

  return (
    <GenericModal
      show={show}
      onHide={onHide}
      size="lg"
      hideFooter={true}
      className="d-flex justify-content-center align-items-center custom-modal-style"
    >
      <Formik
        initialValues={{
          // answers: Array(5).fill(""),
          symptoms: Array(questionList?.questions.length).fill([]),
        }}
        enableReinitialize
        onSubmit={handleChatLanguage}
      >
        {({ values, setFieldValue }) => (
          <Form className="flex justify-items-center w-100 px-2 px-md-5 p-4 mb-4">
            <Typography
              align="center"
              fontSize="1.2rem"
              fontWeight="500"
              className="p-3 fw-bold"
            >
              Select Preferences to Continue
            </Typography>

            <div>
              <BootstrapForm.Group className="mb-4">
                <BootstrapForm.Label className="fw-bold mb-2">
                  Select Language
                </BootstrapForm.Label>
                <BootstrapForm.Select
                  aria-placeholder="Select"
                  value={selectedLanguage}
                  onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                  <option value="en">English</option>
                  <option value="ar">Arabic</option>
                </BootstrapForm.Select>
              </BootstrapForm.Group>

              <BootstrapForm.Group className="mb-2">
                <BootstrapForm.Label className="fw-bold mb-2">
                  Select Specialization
                </BootstrapForm.Label>
                <BootstrapForm.Select
                  aria-placeholder="Select"
                  value={selectedSpecialization}
                  onChange={(e) => {
                    setSelectedSpecialization(e.target.value);
                    // getSpecializationAnswerList(e.target.value);
                    // setFieldValue("answers", Array(5).fill(""));
                    // setFieldValue(
                    //   "symptoms",
                    //   Array(questionList?.questions.length).fill([])
                    // );
                  }}
                >
                  <option value="">Select</option>
                  {consultantSpecializationList?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.title}
                    </option>
                  ))}
                </BootstrapForm.Select>
              </BootstrapForm.Group>
            </div>

            {/* {specializationQuestions?.TRANSLATE_QUESTIONS_LOADING ? (
              <div className="mt-4 text-center">
                <GenericLoader />
              </div>
            ) : (
              <div className="w-100" >
                {questionList?.questions?.map((item, index) => (
                  <div key={index} className="mb-2">
                    <Typography
                      align={selectedLanguage === "ar" ? "right" : "left"}
                      fontSize="0.9rem"
                      fontWeight="400"
                      className="p-1 fw-bold"
                    >
                      {item?.question}
                    </Typography>
                    <CustomMultiSelect
                      symptoms={item?.options} 
                      selectedValues={values.symptoms[index]}
                      setSelectedValues={(vals) =>
                        setFieldValue(`symptoms.${index}`, vals)
                      }
                      selectedLanguage={selectedLanguage}
                    />
                  </div>
                ))}
              </div>
            )} */}

            <div className="d-flex gap-3 justify-content-end align-items-end">
              <Button
                minWidth="8rem"
                height="3rem"
                type="submit"
                className={`btn-color ${
                  specializationQuestions?.TRANSLATE_QUESTIONS_LOADING &&
                  "cursor-not-allowed"
                }`}
                disabled={specializationQuestions?.TRANSLATE_QUESTIONS_LOADING}
              >
                <Typography
                  align="center"
                  fontSize="1rem"
                  fontWeight="500"
                  color="white"
                >
                  {specializationQuestions?.TRANSLATE_QUESTIONS_LOADING ? (
                    <GenericLoader />
                  ) : (
                    "Proceed"
                  )}
                </Typography>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </GenericModal>
  );
}

{
  /* <Button
          className="bg-secondary"
          minWidth={width < 500 ? "6rem" : "8rem"}
          height={width < 500 ? "2.5rem" : "3rem"}
          onClick={() => {
            handleChatLanguage();
          }}
        >
          <Typography
            align="center"
            fontSize="1rem"
            fontWeight="500"
            color="white"
          >
            Continue
          </Typography>
        </Button> */
}

{
  /* <div className="d-flex gap-3  justify-content-center align-items-center">
          <Button
            className="bg-secondary"
            minWidth={width < 500 ? "6rem" : "8rem"}
            height={width < 500 ? "2.5rem" : "3rem"}
            onClick={() => {
              handleChatLanguage("en");
            }}
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="500"
              color="white"
            >
              English
            </Typography>
          </Button>
          <Button
            minWidth={width < 500 ? "6rem" : "8rem"}
            height={width < 500 ? "2.5rem" : "3rem"}
            onClick={() => {
              handleChatLanguage("ar");
            }}
            className="btn-color"
          >
            <Typography
              align="center"
              fontSize="1rem"
              fontWeight="500"
              color="white"
            >
              Arabic
            </Typography>
          </Button>
        </div> */
}
