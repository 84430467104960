/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-var */
/* eslint-disable import/no-duplicates */
import jQuery from "jquery";

var hmsconfig = {
  default: {
    outlineColor: "#ff8080",
  },
  /*==========================================================================================*/
  /*==========================================================================================*/
  /*================ !!! CUSTOMIZATION OF THE MALE FIGURE FRONT SIDE !!!  ====================*/
  /*==========================================================================================*/
  /*==========================================================================================*/
 hms_1: {
    //front-head
    hover: "HEAD", //info of the popup
    //"url": "https://www.humananatomyillustrations.com/",//link to any webpage
    //"target": "same_window",// use "new_window", "same_window", "modal" with bootstrap only, or "none"
    enabled: true, //true/false to activate/deactivate
  },
 hms_2: {
    //front-eye-rt
    hover: "EYE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_3: {
    //front-eye-lt
    hover: "EYE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_4: {
    //front-ear-rt
    hover: "EAR [RT]",
    /*"url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_5: {
    //front-ear-lt
    hover: "EAR [LT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_6: {
    //front-nose
    hover: "NOSE",
    /*"url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_7: {
    //front-mouth
    hover: "MOUTH",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_8: {
    //front-neck
    hover: "NECK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_9: {
    //front-chest
    hover: "CHEST",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_10: {
    //front-abdomen
    hover: "ABDOMEN",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_11: {
    //front-pelvis
    hover: "PELVIS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_12: {
    //front-pubis
    hover: "PUBIS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_13: {
    //front-shoulder-rt
    hover: "SHOULDER [RT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_14: {
    //front-shoulder-lt
    hover: "SHOULDER [LT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_15: {
    //front-arm-rt
    hover: "ARM [RT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_16: {
    //front-arm-lt
    hover: "ARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_17: {
    //front-arm-lt
    hover: "ELBOW [RT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_18: {
    //front-elbow-lt
    hover: "ELBOW [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_19: {
    //front-forearm-rt
    hover: "FOREARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_20: {
    //front-forearm-lt
    hover: "FOREARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_21: {
    //front-wrist-rt
    hover: "WRIST [RT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_22: {
    //front-wrist-lt
    hover: "WRIST [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_23: {
    //front-hand-rt
    hover: "HAND [RT]",
    /*  "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_24: {
    //front-hand-lt
    hover: "HAND [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_25: {
    //front-thigh-rt
    hover: "THIGH [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_26: {
    //front-thigh-lt
    hover: "THIGH [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_27: {
    //front-knee-rt
    hover: "KNEE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_28: {
    //front-knee-lt
    hover: "KNEE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_29: {
    //front-leg-rt
    hover: "LEG [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_30: {
    //front-leg-lt
    hover: "LEG [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_31: {
    //front-ankle-rt
    hover: "ANKLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_32: {
    //front-ankle-lt
    hover: "ANKLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_33: {
    //front-foot-rt
    hover: "FOOT [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_34: {
    //front-foot-lt
    hover: "FOOT [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
  /*============================================================================================*/
  /*============================================================================================*/
  /*================ !!! CUSTOMIZATION OF THE FEMALE FIGURE FRONT SIDE !!!  ====================*/
  /*============================================================================================*/
  /*============================================================================================*/
 hms_35: {
    //front-head
    hover: "HEAD",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_36: {
    //front-eye-rt
    hover: "EYE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_37: {
    //front-eye-lt
    hover: "EYE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_38: {
    //front-ear-rt
    hover: "EAR [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_39: {
    //front-ear-lt
    hover: "EAR [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_40: {
    //front-nose
    hover: "NOSE",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_41: {
    //front-mouth
    hover: "MOUTH",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_42: {
    //front-neck
    hover: "NECK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_43: {
    //front-chest
    hover: "CHEST",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_44: {
    //front-breasts
    hover: "BREASTS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_45: {
    //front-abdomen
    hover: "ABDOMEN",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_46: {
    //front-pelvis
    hover: "PELVIS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_47: {
    //front-pubis
    hover: "PUBIS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_48: {
    //front-shoulder-rt
    hover: "SHOULDER [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_49: {
    //front-shoulder-lt
    hover: "SHOULDER [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_50: {
    //front-arm-rt
    hover: "ARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_51: {
    //front-arm-lt
    hover: "ARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_52: {
    //front-elbow-rt
    hover: "ELBOW [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_53: {
    //front-elbow-lt
    hover: "ELBOW [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_54: {
    //front-forearm-rt
    hover: "FOREARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_55: {
    //front-forearm-lt
    hover: "FOREARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_56: {
    //front-wrist-rt
    hover: "WRIST [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_57: {
    //front-wrist-lt
    hover: "WRIST [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_58: {
    //front-hand-rt
    hover: "HAND [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_59: {
    //front-hand-lt
    hover: "HAND [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_60: {
    //front-thigh-rt
    hover: "THIGH [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_61: {
    //front-thigh-lt
    hover: "THIGH [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_62: {
    //front-knee-rt
    hover: "KNEE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_63: {
    //front-knee-lt
    hover: "KNEE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_64: {
    //front-leg-rt
    hover: "LEG [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_65: {
    //front-leg-lt
    hover: "LEG [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_66: {
    //front-ankle-rt
    hover: "ANKLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_67: {
    //front-ankle-lt
    hover: "ANKLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_68: {
    //front-foot-rt
    hover: "FOOT [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_69: {
    //front-foot-lt
    hover: "FOOT [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
  /*=========================================================================================*/
  /*=========================================================================================*/
  /*================ !!! CUSTOMIZATION OF THE MALE FIGURE BACK SIDE !!!  ====================*/
  /*=========================================================================================*/
  /*=========================================================================================*/
 hms_70: {
    //back-head
    hover: "HEAD",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_71: {
    //back-neck
    hover: "NECK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_72: {
    //back-back
    hover: "BACK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_73: {
    //back-loin
    hover: "LOIN",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_74: {
    //back-buttocks
    hover: "BUTTOCKS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_75: {
    //back-shoulder-rt
    hover: "SHOULDER [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_76: {
    //back-shoulder-lt
    hover: "SHOULDER [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_77: {
    //back-arm-rt
    hover: "ARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_78: {
    //back-arm-lt
    hover: "ARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_79: {
    //back-elbow-rt
    hover: "ELBOW [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_80: {
    //back-elbow-lt
    hover: "ELBOW [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_81: {
    //back-forearm-rt
    hover: "FOREARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_82: {
    //back-forearm-lt
    hover: "FOREARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_83: {
    //back-wrist-rt
    hover: "WRIST [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_84: {
    //back-wrist-lt
    hover: "WRIST [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_85: {
    //back-hand-rt
    hover: "HAND [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_86: {
    //back-hand-lt
    hover: "HAND [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_87: {
    //back-hamstring-rt
    hover: "HAMSTRING [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_88: {
    //back-hamstring-lt
    hover: "HAMSTRING [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_89: {
    //back-knee-rt
    hover: "KNEE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_90: {
    //back-knee-lt
    hover: "KNEE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_91: {
    //back-calf-rt
    hover: "CALF [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_92: {
    //back-calf-lt
    hover: "CALF [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_93: {
    //back-ankle-rt
    hover: "ANKLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_94: {
    //back-ankle-lt
    hover: "ANKLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_95: {
    //back-sole-rt
    hover: "SOLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_96: {
    //back-sole-lt
    hover: "SOLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_97: {
    //back-foot-rt
    hover: "FOOT [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_98: {
    //back-foot-lt
    hover: "FOOT [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
  /*===========================================================================================*/
  /*===========================================================================================*/
  /*================ !!! CUSTOMIZATION OF THE FEMALE FIGURE BACK SIDE !!!  ====================*/
  /*===========================================================================================*/
  /*===========================================================================================*/
 hms_99: {
    //back-head
    hover: "HEAD",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_100: {
    //back-neck
    hover: "NECK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_101: {
    //back-back
    hover: "BACK",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_102: {
    //back-loin
    hover: "LOIN",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_103: {
    //back-buttocks
    hover: "BUTTOCKS",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_104: {
    //back-shoulder-rt
    hover: "SHOULDER [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_105: {
    //back-shoulder-lt
    hover: "SHOULDER [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_106: {
    //back-arm-rt
    hover: "ARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_107: {
    //back-arm-lt
    hover: "ARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_108: {
    //back-elbow-rt
    hover: "ELBOW [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_109: {
    //back-elbow-lt
    hover: "ELBOW [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_110: {
    //back-forearm-rt
    hover: "FOREARM [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_111: {
    //back-forearm-lt
    hover: "FOREARM [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_112: {
    //back-wrist-rt
    hover: "WRIST [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_113: {
    //back-wrist-lt
    hover: "WRIST [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_114: {
    //back-hand-rt
    hover: "HAND [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_115: {
    //back-hand-lt
    hover: "HAND [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_116: {
    //back-hamstring-rt
    hover: "HAMSTRING [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_117: {
    //back-hamstring-lt
    hover: "HAMSTRING [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_118: {
    //back-knee-rt
    hover: "KNEE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_119: {
    //back-knee-lt
    hover: "KNEE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_120: {
    //back-calf-rt
    hover: "CALF [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_121: {
    //back-calf-lt
    hover: "CALF [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_122: {
    //back-ankle-rt
    hover: "ANKLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_123: {
    //back-ankle-lt
    hover: "ANKLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_124: {
    //back-sole-rt
    hover: "SOLE [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_125: {
    //back-sole-lt
    hover: "SOLE [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_126: {
    //back-foot-rt
    hover: "FOOT [RT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
 hms_127: {
    //back-foot-lt
    hover: "FOOT [LT]",
    /* "url": "https://www.humananatomyillustrations.com/", "target": "same_window",*/
    enabled: true,
  },
};

