import axios from "axios";
import { API_URLS, BASE_URL, api } from "../../config";

function getWelcomeMessageApi(payload) {
  // return axios.get(`${BASE_URL}?id=${id}`);
  return axios.post(`${BASE_URL}`, payload);
}

function getPromptResponseApi(payload, OptionalPayload, chat_id, Id, file) {
  const modifiedPayload =
    OptionalPayload?.firstMessage === true
      ? OptionalPayload
      : { firstMessage: false, gender: OptionalPayload.gender };

  return axios.post(`${API_URLS.CHAT.PROMPT_RESPONSE}`, {
    // user_query: payload,
    ...payload,
    ...(chat_id !== null ? { chat_id } : {}),
    // chat_id: chat_id,
    ...modifiedPayload,
    Id,
  });
}

function getAudioFromTextApi(data) {
  return axios.post(`${API_URLS.CHAT.AUDIO_FROM_TEXT}`, data);
}
// function getAudioFromTextApi(text, id) {
//   return axios.post(`${API_URLS.CHAT.AUDIO_FROM_TEXT}?text=${text}&id=${id}`);
// }

function saveUserChatApi(chatName, user_id) {
  return axios.post(
    `${API_URLS.CHAT.SAVE_USER_CHAT}?user_id=${user_id}&save_name=${chatName}`
  );
}

function getChatHistoryApi(userId) {
  return axios.post(`${API_URLS.CHAT.GET_CHAT_HISTORY}?user_id=${userId}`);
}

function saveChatApi(data) {
  return api.post(`${API_URLS.CHAT.SAVE_HISTORY}`, data);
}

async function saveMessageApi(data) {
  const res = await api.post(`${API_URLS.MESSAGE.SAVE}`, data);
  return res?.data;
}

function getMessageApi(data) {
  return api.get(
    `${API_URLS.MESSAGE.GET}?fromUserId=${data?.fromUserId}&toUserId=${data?.toUserId}`
  );
}

function getDoctorProfileApi(data) {
  return api.get(
    `${API_URLS.CHAT.DOCTOR_PROFILE}?isSystemDoctor=${data?.isSystemDoctor}`
  );
}

function sessionEndApi(data) {
  return api.put(`${API_URLS.CHAT.END_SESSION + data?.chatId}`);
}

function generateChatName(data) {
  return api.post(`${API_URLS.CHAT.GENERATE_CHAT_NAME}`, data);
}

function generateChaTSummary(data) {
  return api.post(`${API_URLS.CHAT.GENERATE_CHAT_SUMMARY}`, data);
}

function generateChatReport(data) {
  return api.post(`${API_URLS.CHAT.GENERATE_CHAT_REPORT}`, data);
}

// ----------------------------------

async function getChatApi(data) {
  const res = await api.post(`${API_URLS.CHAT.GET_CHAT}`, data);
  return res?.data;
}

export {
  getWelcomeMessageApi,
  getPromptResponseApi,
  saveUserChatApi,
  getChatHistoryApi,
  getAudioFromTextApi,
  getMessageApi,
  saveMessageApi,
  saveChatApi,
  getDoctorProfileApi,
  sessionEndApi,
  getChatApi,
  generateChatName,
  generateChatReport,
  generateChaTSummary,
};
