/* eslint-disable no-undef */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import jQuery from "jquery";

let mystyle = {
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial",
};

jQuery(document).ready(function () {
  var Array = [];

  // $("path").click(function () {
  //   var id = this.id;
  //   mystyle.backgroundColor = this.style.fill;
  //   if (mystyle.backgroundColor === "rgba(255, 0, 0, 0)") {
  //     $("#" + id).css({ fill: "red" });
  //   } else {
  //     $("#" + id).css({ fill: "rgba(255, 0, 0, 0)" });
  //   }
  //   var bodyPard = this.className.baseVal;
  //   if (!Array.includes(bodyPard)) {
  //     Array.push(bodyPard);
  //   } else {
  //     Array.splice(Array.indexOf(bodyPard), 1);
  //   }
  //   // document.querySelector(".txt").innerHTML = Array.join(",");
  //   if (document.querySelector(".txt")) {
  //     document.querySelector(".txt").innerHTML = Array.join(",");
  //   }
  // });

  // $(".bodyPart").click(function () {
  //   $("path").css({ fill: "rgba(255, 0, 0, 0)" });
  // });
});
