import PropTypes from "prop-types";

import { Box, Typography } from "../../../Components";
import { IMAGES } from "../../../Assets";
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai";
import useWindowSizeHook from "../../../Components/WindowSizeHook";
import { useState } from "react";

export default function ReplyTemplate({ ...props }) {
  const { width } = useWindowSizeHook();
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleCheckboxChange = (item) => {
    if(props.disabled){
        return;
    }
    if (props.type === "ms") {
      props?.setSelectedOptions((prev) =>
        prev.includes(item)
          ? prev.filter((option) => option !== item)
          : [...prev, item]
      );
      setSelectedValues((prev) =>
          prev.includes(item)
              ? prev.filter((option) => option !== item)
              : [...prev, item])
    } else {
      props?.setSelectedOptions([item]);
        setSelectedValues([item]);
    }
  };

  const handleAddClick = () => {
    props?.handleCheckboxClick(props?.selectedOptions);
  };

  return (
    <div
      style={{
        pointerEvents: props.disabled ? "none" : "auto",
      }}
    >
      <div className="d-flex align-items-center justify-content-start my-4">
        {!props.loader && (
          <>
            <img
              src={IMAGES.MALE_DOCTOR_AVATAR}
              alt=""
              width={"60px"}
              height={"60px"}
              className="mx-2 rounded-circle"
            />

            <Box
              background="#ffffff"
              borderRadius="0.75rem 0.75rem 0.75rem 0"
              className="px-1 py-1"
              width="fit-content"
              maxWidth="50rem"
            >
              <Typography as={width < 600 ? "label" : "p"} className="m-2">
                {props?.reply?.description
                  ? props?.reply?.description?.replace(/<END_OF_TURN>/g, "")
                  : props?.reply}
              </Typography>
            </Box>

            {!props?.reply?.description && (
              <Box
                width="1.5rem"
                height="1.5rem"
                background="#E30020"
                className="rounded-circle d-flex justify-content-center align-items-center mx-2 cursor-pointer"
              >
                {!props?.playAudioBlob ||
                currentlyPlayingId !== props.messageId ? (
                  <AiFillPlayCircle
                    color="#ffffff"
                    size={20}
                    onClick={() => {
                      props?.resetInActiveTimer();
                      props?.getAudio(props.reply, 0);
                      setCurrentlyPlayingId(props.messageId);
                    }}
                  />
                ) : (
                  <AiFillPauseCircle
                    color="#ffffff"
                    size={20}
                    onClick={() => {
                      props?.pauseAudioBlob(null);
                      setCurrentlyPlayingId(null);
                    }}
                  />
                )}
              </Box>
            )}
          </>
        )}
      </div>
      {/* {props?.options?.map((item, idx) => {
        return (
          <Typography as={width < 600 ? "label" : "p"} className="m-2">
            {item}
          </Typography>
        );
      })} */}
      <div className="multi-select-container">
        {props?.options?.length > 0 && (
          <>
            {props?.options.map((item, index) => (
              <div
                key={index}
                className={`option-item ${
                  props?.state.language === "ar" ? "option-rtl" : ""
                }`}
              >
                <input
                  name={props.type === "ms" ? "" : `radio-${props.no}`}
                  type={props.type === "ms" ? "checkbox" : "radio"}
                  id={
                    props.type === "ms"
                      ? `checkbox-${props.no}`
                      : `radio-${props.no}`
                  }
                  style={{
                    width: "60px",
                    cursor: "pointer",
                  }}
                  className={`option-${
                    props.type === "ms" ? "checkbox" : "radio"
                  } mx-2`}
                  checked={selectedValues.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                  disabled={
                    props?.chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
                    props?.chat?.GET_PROMPT_RESPONSE_IS_LOADING
                  }
                />
                <label
                  htmlFor={`checkbox-${index}`}
                  onClick={() => handleCheckboxChange(item)}
                  className="option-label"
                >
                  <Typography
                    as="label"
                    align={props?.state.language === "ar" ? "right" : "left"}
                  >
                    {item}
                  </Typography>
                </label>
              </div>
            ))}
            {/* {
            !props.disabled
          &&
              <div className="w-100 d-flex justify-content-end">

          <button onClick={handleAddClick} className="add-button" >
            Send to Bot
          </button>

              </div>
              } */}
          </>
        )}
      </div>
    </div>
  );
}

ReplyTemplate.propTypes = {
  reply: PropTypes.string,
};

ReplyTemplate.defaultProps = {
  reply: "",
};
