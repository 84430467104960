/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-var */
/* eslint-disable import/no-duplicates */
import jQuery from "jquery";

const anaconfig = {
  default: {
    outlineColor: "#ff8080", 
  },
  /*= ========================================================================================= */
  /*= ========================================================================================= */
  /*= =============== !!! CUSTOMIZATION OF THE MALE FIGURE FRONT SIDE !!!  ==================== */
  /*= ========================================================================================= */
  /*= ========================================================================================= */
  ana1: {
    // front-head
    hover:
      "<b><u>HEAD</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>", // info of the popup
    // url: "https://www.humananatomyillustrations.com/", //link to any webpage
    //target: "same_window", // use "new_window", "same_window", "modal" with bootstrap only, or "none"
    enabled: true, // true/false to activate/deactivate
  },
  ana2: {
    // front-eye-rt
    hover:
      "<b><u>EYE [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana3: {
    // front-eye-lt
    hover:
      "<b><u>EYE [LT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana4: {
    // front-ear-rt
    hover:
      "<b><u>EAR [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana5: {
    // front-ear-lt
    hover:
      "<b><u>EAR [LT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana6: {
    // front-nose
    hover:
      "<b><u>NOSE</u></b>",
      //",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana7: {
    // front-mouth
    hover:
      "<b><u>MOUTH</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana8: {
    // front-neck
    hover:
      "<b><u>NECK</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana9: {
    // front-chest
    hover:
      "<b><u>CHEST</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana10: {
    // front-abdomen
    hover: "ABDOMEN",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana11: {
    // front-pelvis
    hover:
      "<b><u>PELVIS</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana12: {
    // front-pubis
    hover:
      "<b><u>PUBIS</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana13: {
    // front-shoulder-rt
    hover:
      "<b><u>SHOULDER [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana14: {
    // front-shoulder-lt
    hover:
      "<b><u>SHOULDER [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana15: {
    // front-arm-rt
    hover:
      "<b><u>ARM [RT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana16: {
    // front-arm-lt
    hover:
      "<b><u>ARM [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana17: {
    // front-arm-lt
    hover:
      "<b><u>ELBOW [RT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana18: {
    // front-elbow-lt
    hover:
      "<b><u>ELBOW [LT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana19: {
    // front-forearm-rt
    hover:
      "<b><u>FOREARM [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana20: {
    // front-forearm-lt
    hover:
      "<b><u>FOREARM [LT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana21: {
    // front-wrist-rt
    hover:
      "<b><u>WRIST [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana22: {
    // front-wrist-lt
    hover: "WRIST [LT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana23: {
    // front-hand-rt
    hover:
      "<b><u>HAND [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana24: {
    // front-hand-lt
    hover:
      "<b><u>HAND [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana25: {
    // front-thigh-rt
    hover:
      "<b><u>THIGH [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana26: {
    // front-thigh-lt
    hover:
      "<b><u>THIGH [LT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana27: {
    // front-knee-rt
    hover:
      "<b><u>KNEE [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana28: {
    // front-knee-lt
    hover:
      "<b><u>KNEE [LT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana29: {
    // front-leg-rt
    hover:
      "<b><u>LEG [RT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana30: {
    // front-leg-lt
    hover:
      "<b><u>LEG [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana31: {
    // front-ankle-rt
    hover:
      "<b><u>ANKLE [RT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana32: {
    // front-ankle-lt
    hover:
      "<b><u>ANKLE [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana33: {
    // front-foot-rt
    hover: "FOOT [RT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana34: {
    // front-foot-lt
    hover:
      "<b><u>FOOT [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  /*= =========================================================================================== */
  /*= =========================================================================================== */
  /*= =============== !!! CUSTOMIZATION OF THE FEMALE FIGURE FRONT SIDE !!!  ==================== */
  /*= =========================================================================================== */
  /*= =========================================================================================== */
  ana35: {
    // front-head
    hover:
      "<b><u>HEAD</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana36: {
    // front-eye-rt
    hover:
      "<b><u>EYE [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana37: {
    // front-eye-lt
    hover:
      "<b><u>EYE [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana38: {
    // front-ear-rt
    hover:
      "<b><u>EAR [RT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana39: {
    // front-ear-lt
    hover:
      "<b><u>EAR [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana40: {
    // front-nose
    hover:
      "<b><u>NOSE</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana41: {
    // front-mouth
    hover:
      "<b><u>MOUTH</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana42: {
    // front-neck
    hover:
      "<b><u>NECK</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana43: {
    // front-chest
    hover:
      "<b><u>CHEST</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana44: {
    // front-breasts
    hover:
      "<b><u>BREASTS</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana45: {
    // front-abdomen
    hover: "ABDOMEN",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana46: {
    // front-pelvis
    hover:
      "<b><u>PELVIS</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana47: {
    // front-pubis
    hover:
      "<b><u>PUBIS</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana48: {
    // front-shoulder-rt
    hover:
      "<b><u>SHOULDER [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana49: {
    // front-shoulder-lt
    hover:
      "<b><u>SHOULDER [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana50: {
    // front-arm-rt
    hover:
      "<b><u>ARM [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana51: {
    // front-arm-lt
    hover:
      "<b><u>ARM [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana52: {
    // front-elbow-rt
    hover:
      "<b><u>ELBOW [RT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana53: {
    // front-elbow-lt
    hover:
      "<b><u>ELBOW [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana54: {
    // front-forearm-rt
    hover:
      "<b><u>FOREARM [RT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana55: {
    // front-forearm-lt
    hover:
      "<b><u>FOREARM [LT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana56: {
    // front-wrist-rt
    hover:
      "<b><u>WRIST [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana57: {
    // front-wrist-lt
    hover:
      "<b><u>WRIST [LT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana58: {
    // front-hand-rt
    hover:
      "<b><u>HAND [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana59: {
    // front-hand-lt
    hover: "HAND [LT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana60: {
    // front-thigh-rt
    hover:
      "<b><u>THIGH [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana61: {
    // front-thigh-lt
    hover:
      "<b><u>THIGH [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana62: {
    // front-knee-rt
    hover:
      "<b><u>KNEE [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana63: {
    // front-knee-lt
    hover:
      "<b><u>KNEE [LT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana64: {
    // front-leg-rt
    hover:
      "<b><u>LEG [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana65: {
    // front-leg-lt
    hover:
      "<b><u>LEG [LT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana66: {
    // front-ankle-rt
    hover:
      "<b><u>ANKLE [RT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana67: {
    // front-ankle-lt
    hover:
      "<b><u>ANKLE [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana68: {
    // front-foot-rt
    hover:
      "<b><u>FOOT [RT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana69: {
    // front-foot-lt
    hover:
      "<b><u>FOOT [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  /*= ======================================================================================== */
  /*= ======================================================================================== */
  /*= =============== !!! CUSTOMIZATION OF THE MALE FIGURE BACK SIDE !!!  ==================== */
  /*= ======================================================================================== */
  /*= ======================================================================================== */
  ana70: {
    // back-head
    hover: "HEAD",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana71: {
    // back-neck
    hover:
      "<b><u>NECK</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana72: {
    // back-back
    hover:
      "<b><u>BACK</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana73: {
    // back-loin
    hover:
      "<b><u>LOIN</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana74: {
    // back-buttocks
    hover:
      "<b><u>BUTTOCKS</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana75: {
    // back-shoulder-rt
    hover:
      "<b><u>SHOULDER [RT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana76: {
    // back-shoulder-lt
    hover:
      "<b><u>SHOULDER [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana77: {
    // back-arm-rt
    hover:
      "<b><u>ARM [RT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana78: {
    // back-arm-lt
    hover:
      "<b><u>ARM [LT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana79: {
    // back-elbow-rt
    hover:
      "<b><u>ELBOW [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana80: {
    // back-elbow-lt
    hover:
      "<b><u>ELBOW [LT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana81: {
    // back-forearm-rt
    hover:
      "<b><u>FOREARM [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana82: {
    // back-forearm-lt
    hover: "FOREARM [LT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana83: {
    // back-wrist-rt
    hover:
      "<b><u>WRIST [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana84: {
    // back-wrist-lt
    hover:
      "<b><u>WRIST [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana85: {
    // back-hand-rt
    hover:
      "<b><u>HAND [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana86: {
    // back-hand-lt
    hover:
      "<b><u>HAND [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana87: {
    // back-hamstring-rt
    hover:
      "<b><u>HAMSTRING [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana88: {
    // back-hamstring-lt
    hover:
      "<b><u>HAMSTRING [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana89: {
    // back-knee-rt
    hover:
      "<b><u>KNEE [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana90: {
    // back-knee-lt
    hover:
      "<b><u>KNEE [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana91: {
    // back-calf-rt
    hover:
      "<b><u>CALF [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana92: {
    // back-calf-lt
    hover:
      "<b><u>CALF [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana93: {
    // back-ankle-rt
    hover:
      "<b><u>ANKLE [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana94: {
    // back-ankle-lt
    hover:
      "<b><u>ANKLE [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana95: {
    // back-sole-rt
    hover:
      "<b><u>SOLE [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana96: {
    // back-sole-lt
    hover:
      "<b><u>SOLE [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana97: {
    // back-foot-rt
    hover:
      "<b><u>FOOT [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana98: {
    // back-foot-lt
    hover:
      "<b><u>FOOT [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  /*= ========================================================================================== */
  /*= ========================================================================================== */
  /*= =============== !!! CUSTOMIZATION OF THE FEMALE FIGURE BACK SIDE !!!  ==================== */
  /*= ========================================================================================== */
  /*= ========================================================================================== */
  ana99: {
    // back-head
    hover:
      "<b><u>HEAD</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana100: {
    hover:
      "<b><u>NECK</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana101: {
    // back-back
    hover:
      "<b><u>BACK</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana102: {
    // back-loin
    hover:
      "<b><u>LOIN</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana103: {
    // back-buttocks
    hover:
      "<b><u>BUTTOCKS</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana104: {
    // back-shoulder-rt
    hover:
      "<b><u>SHOULDER [RT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana105: {
    // back-shoulder-lt
    hover:
      "<b><u>SHOULDER [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana106: {
    // back-arm-rt
    hover:
      "<b><u>ARM [RT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana107: {
    // back-arm-lt
    hover:
      "<b><u>ARM [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana108: {
    // back-elbow-rt
    hover: "ELBOW [RT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana109: {
    // back-elbow-lt
    hover:
      "<b><u>ELBOW [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana110: {
    // back-forearm-rt
    hover:
      "<b><u>FOREARM [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana111: {
    // back-forearm-lt
    hover:
      "<b><u>FOREARM [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana112: {
    // back-wrist-rt
    hover:
      "<b><u>WRIST [RT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana113: {
    // back-wrist-lt
    hover:
      "<b><u>WRIST [LT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana114: {
    // back-hand-rt
    hover:
      "<b><u>HAND [RT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana115: {
    // back-hand-lt
    hover:
      "<b><u>HAND [LT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana116: {
    // back-hamstring-rt
    hover:
      "<b><u>HAMSTRING [RT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana117: {
    // back-hamstring-lt
    hover:
      "<b><u>HAMSTRING [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana118: {
    // back-knee-rt
    hover:
      "<b><u>KNEE [RT]</u></b>",
      //<br>Change the hover info. for each<br>part independently.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana119: {
    // back-knee-lt
    hover:
      "<b><u>KNEE [LT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana120: {
    // back-calf-rt
    hover: "CALF [RT]",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana121: {
    // back-calf-lt
    hover:
      "<b><u>CALF [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana122: {
    // back-ankle-rt
    hover:
      "<b><u>ANKLE [RT]</u></b>",
      //<br><span style='color: #ff1a75;'>*Link each part to any webpage*</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana123: {
    // back-ankle-lt
    hover:
      "<b><u>ANKLE [LT]</u></b>",
      //<br><span style='color: #808080;'>Set any part as:<br><b>'active'</b> clickable or<br><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana124: {
    // back-sole-rt
    hover:
      "<b><u>SOLE [RT]</u></b>",
      //<br>You can write different information<br>for each organ.",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana125: {
    // back-sole-lt
    hover:
      "<b><u>SOLE [LT]</u></b>",
      //<br>Write any text and load images<br><img src='images/hover.png'>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
  ana126: {
    // back-foot-rt
    hover:
      "<b><u>FOOT [RT]</u></b>",
      //<br><span style='color: #999;'>Click to open a modal window!</span><br><span style='color: #ff6666;'><b>Modal Window Option is Compatible<br> with Bootstrap Only.</b></span>",
    //url: "#mymodal",
    //target: "modal",
    enabled: true,
  },
  ana127: {
    // back-foot-lt
    hover:
      "<b><u>FOOT [LT]</u></b>",
      //<br>You can set any part as:<br><b>'active'</b> clickable or<br><span style='color: #808080;'><b>'inactive'</b> > not clickable.</span>",
    // url: "https://www.humananatomyillustrations.com/",
    //target: "same_window",
    enabled: true,
  },
};

function isTouchEnabled() {
  return (
    "ontouchstart" in window ||
    navigator.MaxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

jQuery(document).ready(() => {
  jQuery("#baseb").hide().animate({ opacity: "0" }, 100);
  jQuery(".gob").on("click", () => {
    jQuery("#basea").hide().animate({ opacity: "0" }, 100);
    jQuery("#baseb").show().animate({ opacity: "1" }, 100);
  });
  jQuery(".goa").on("click", () => {
    jQuery("#baseb").hide().animate({ opacity: "0" }, 100);
    jQuery("#basea").show().animate({ opacity: "1" }, 100);
  });
});
jQuery(() => {
  jQuery("path[id^=ana]").each((i, e) => {
    anaaddEvent(jQuery(e).attr("id"));
  });
});

function anaaddEvent(id) {
  const arr = id.split("");
  const _obj = jQuery(`#${id},${arr.slice().join("")}`);
  
  _obj.attr({ cursor: "default" });
  
  if (anaconfig[id].enabled === true) {
    _obj.attr({ cursor: "pointer" });

    // Hover effect: Show tooltip and change color
    _obj.hover(
      function () {
        // Show the tooltip
        jQuery("#anatip").show().html(anaconfig[id].hover);

        // Change the color of the path (e.g., fill or stroke)
        jQuery(this).css({ fill: "rgba(255, 0, 0, 0.3)" });
      },
      function () {
        // Hide the tooltip
        jQuery("#anatip").hide();

        // Revert the color back to original on mouse leave
        jQuery(this).css({ fill: "rgba(255, 0, 0, 0)" });
      }
    );

    // Track cursor movement to position tooltip correctly
    _obj.mousemove(function (e) {
      // Get cursor coordinates with offset
      let x = e.clientX + 10;  // Offset to the right of the cursor
      let y = e.clientY + 15;  // Offset slightly below the cursor
  
      const $anatip = jQuery("#anatip");
      const tipw = $anatip.outerWidth();   // Tooltip width
      const tiph = $anatip.outerHeight();  // Tooltip height
      
      // Adjust position if tooltip goes outside the viewport on the right side
      if (x + tipw > window.innerWidth) {
          x = e.clientX - tipw - 10; // Move tooltip to the left of cursor
      }
  
      // Adjust position if tooltip goes outside the viewport on the bottom side
      if (y + tiph > window.innerHeight) {
          y = e.clientY - tiph - 10; // Move tooltip above the cursor
      }
  
      // Apply new tooltip position
      $anatip.css({
          left: x + "px",
          top: y + "px",
          position: "fixed" // Fixed positioning relative to viewport
      });
  });
  

    // Add click event for navigation or modal, etc.
    _obj.mouseup(function () {
      if (anaconfig[id].target === "new_window") {
        window.open(anaconfig[id].url);
      } else if (anaconfig[id].target === "same_window") {
        window.parent.location.href = anaconfig[id].url;
      } else if (anaconfig[id].target === "modal") {
        jQuery(anaconfig[id].url).modal("show");
      }
    });

    // Handle touch events for mobile devices
    if (isTouchEnabled()) {
      _obj.on("touchstart", function (e) {
        const touch = e.originalEvent.touches[0];
        let x = touch.pageX + 10;
        let y = touch.pageY + 15;
        const tipw = jQuery("#anatip").outerWidth();
        const tiph = jQuery("#anatip").outerHeight();
        x = x + tipw > jQuery(document).scrollLeft() + jQuery(window).width()
          ? x - tipw - 20
          : x;
        y = y + tiph > jQuery(document).scrollTop() + jQuery(window).height()
          ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10
          : y;

        jQuery("#anatip").show().html(anaconfig[id].hover);
        jQuery("#anatip").css({ left: x, top: y });

        jQuery(this).css({ fill: "rgba(255, 0, 0, 0.7)" });
      });

      _obj.on("touchend", function () {
        jQuery(this).css({ fill: "rgba(255, 0, 0, 0)" });
      });
    }
  }
}


export function initializeHoverEvents() {
  jQuery("path[id^=ana]").each((i, e) => {
    anaaddEvent(jQuery(e).attr("id"));
  });
}

// _obj.mousemove(function (e) {
//   // Get cursor coordinates
//   let x = e.pageX + 10;  // Add some offset to prevent tooltip overlap with cursor
//   let y = e.pageY + 15;

//   const tipw = jQuery("#anatip").outerWidth();  // Tooltip width
//   const tiph = jQuery("#anatip").outerHeight();  // Tooltip height
  
//   // Ensure the tooltip does not go outside the viewport
//   x = x + tipw > jQuery(document).scrollLeft() + jQuery(window).width() ? x - tipw - 20 : x;
//   y = y + tiph > jQuery(document).scrollTop() + jQuery(window).height() ? jQuery(document).scrollTop() + jQuery(window).height() - tiph - 10 : y;

//   // Apply new tooltip position
//   jQuery("#anatip").css({ left: x, top: y });
// });