import React, { useState } from "react";
import { Box, Button, InputField, Typography } from "../../Components";
import { Col, Form, Row } from "react-bootstrap";
import { ROUTER_PATH } from "../../config";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";

const PrimaryGoal = () => {
  const [activeOption, setActiveOption] = useState();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  function userAssessmentPrimaryGoal(data) {
    return {
      type: "PATIENT_ASSESSMENT_PRIMARY_GOAL",
      payload: data,
    };
  }

  const options = [
    {
      id: 1,
      label: "Personal health insights",
      description: "To understand and manage my own health conditions.",
    },
    {
      id: 2,
      label: "Someone else’s health assistance",
      description: "To help manage a family member's health conditions.",
    },
    {
      id: 3,
      label: "Professional use",
      description: "To help manage a family member's health conditions.",
    },
    {
      id: 4,
      label: "Other",
      description: "",
    },
  ];

  const preparePayload = (selectedOption) => {
    const payload = {
      isPersonalHealthSight: false,
      isSomeoneElseHealthAssistance: false,
      someoneElseHealthAssistance: "",
      isProfessionalUse: false,
      isOtherPrimaryGoal: false,
      otherPrimaryGoal: "",
    };

    switch (selectedOption?.activeOption) {
      case 1:
        payload.isPersonalHealthSight = true;
        break;
      case 2:
        payload.isSomeoneElseHealthAssistance = true;
        payload.someoneElseHealthAssistance = selectedOption?.name; // Set appropriate value
        break;
      case 3:
        payload.isProfessionalUse = true;
        break;
      case 4:
        payload.isOtherPrimaryGoal = true;
        payload.otherPrimaryGoal = selectedOption?.specifyValue; // Set appropriate value
        break;
      default:
        break;
    }

    return payload;
  };

  const handleSubmit = (values) => {
    const payload = preparePayload(values);
    dispatch(userAssessmentPrimaryGoal(payload));
    navigate(ROUTER_PATH.HEALTH_DETAILS);
  };

  const getValidationSchema = () => {
    let schema = yup.object().shape({});
    if (activeOption === 2) {
      schema = schema.shape({
        name: yup.string().required("This field is required"),
      });
    }
    if (activeOption === 4) {
      schema = schema.shape({
        specifyValue: yup.string().required("This field is required"),
      });
    }
    return schema;
  };

  return (
    <Formik
      validationSchema={getValidationSchema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        if (!activeOption) {
          setFieldError("activeOption", "Please select an option");
          setSubmitting(false);
        } else {
          handleSubmit(values);
        }
      }}
      initialValues={{
        name: "",
        specifyValue: "",
        activeOption: "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
        setFieldTouched,
        setErrors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="w-100 vh-100 d-flex justify-content-center align-items-center">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Box
                // width={width < 992 ? "100%" : "30rem"}
                height="100%"
                borderRadius="1.375rem"
                background="#fff"
                shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.03)"
                backdropFilter="blur(5px)"
                className="p-5 flex "
              >
                <Typography
                  fontWeight="400"
                  fontSize="1.25rem"
                  className="mb-5"
                >
                  what's your primary goal with pre consultant?
                </Typography>
                {options?.map((option) => (
                  <>
                    <Box
                      className="p-3 my-3 cursor-pointer option-wrapper"
                      onClick={() => {
                        setActiveOption(option?.id);
                        setFieldValue("activeOption", option?.id);
                        setFieldTouched("activeOption", false);
                        setErrors({ ...errors, activeOption: "" });
                      }}
                      height="100%"
                      width="100%"
                      borderRadius="0.5rem"
                      background={activeOption === option?.id && "#E30020"}
                      border={
                        activeOption === option?.id && "1px solid #E30020"
                      }
                    >
                      <Typography
                        fontSize="1rem"
                        fontWeight={activeOption === option?.id && "500"}
                        className="heading"
                      >
                        {option?.label}
                      </Typography>
                      <Typography fontWeight="300" fontSize="0.8rem">
                        {option?.description}
                      </Typography>
                    </Box>
                    {activeOption === 2 && option?.id === 2 && (
                      <InputField
                        type="text"
                        backgroundColor="#EBEBFF"
                        name="name"
                        placeholder="Nickname for the person you assist"
                        className="w-100"
                        value={values.name}
                        onChange={handleChange}
                        error={errors?.name}
                        hasHelperText
                        helperText="This field is required"
                        size="16px"
                      />
                    )}
                    {activeOption === 4 && option?.id === 4 && (
                      <InputField
                        type="text"
                        backgroundColor="#EBEBFF"
                        name="specifyValue"
                        placeholder={"Please Specify"}
                        className="w-100"
                        onChange={handleChange}
                        error={errors?.specifyValue}
                        value={values.specifyValue}
                        hasHelperText
                        helperText="This field is required"
                        size="16px"
                      />
                    )}
                  </>
                ))}
                {errors.activeOption && (
                  <Typography
                    color="red"
                    fontSize="0.875rem"
                    align="center"
                    className="mt-4"
                  >
                    {errors.activeOption}
                  </Typography>
                )}
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="mt-4"
                    // onClick={() => handleLogout()}
                  >
                    <Typography
                      align="center"
                      fontWeight="300"
                      fontSize="1rem"
                      color="#fff"
                    >
                      Continue
                    </Typography>
                  </Button>
                </div>
              </Box>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PrimaryGoal;
