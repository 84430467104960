/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillMicMuteFill, BsFillStopFill } from "react-icons/bs";
import { useNavigate } from "react-router";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Loader from "react-dots-loader";
import { FaCircleArrowUp } from "react-icons/fa6";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  ConfirmationModal,
  GenericInput,
  GenericLoader,
  GenericModal,
  Toaster,
  Typography,
} from "../../Components";
import {
  endSessionAction,
  generateChatSummaryAction,
  getChatByUsers,
  getDoctorProfile,
  getPromptResponse,
  getUserChatHistory,
  saveChatAction,
  saveMessage,
  saveUserChat,
} from "../../Redux/actions";
import { BASE_URL, ROUTER_PATH } from "../../config";
import ReplyTemplate from "./components/ReplyTemplate";
import MessageTemplate from "./components/MessageTemplate";
import { Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
// import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import { getAudioFromTextApi } from "../../Redux/api/chat";
import useWindowSizeHook from "../../Components/WindowSizeHook";
import { getLoggedInUser } from "../../utils/helper";
import UpgradeModal from "./components/UpgradeModal";
import { FaPowerOff } from "react-icons/fa";
// import { uploadMedia } from "../../Redux/api/blobUpload";
import { HumanBody } from "./HumanBody";
import ReactPlayer from "react-player";
import { IMAGES } from "../../Assets";
import { MusclesStructure } from "./MusclesStructure";
import {
  femaleFullbodyParts,
  maleFullbodyParts,
} from "./components/HumanBodyPartsList";
import { musclesbodyParts } from "./components/MusclesBodyPartsList";
import { translateSuggestedQuestion } from "../../Redux/actions/specializationQuestionsAction";

export default function PatientDiagnosis() {
  const { t } = useTranslation();
  const chat = useSelector((state) => state.chat);
  const getBotQueries = useSelector((state) => state.specializationQuestions);
  const [isSuggestedResponse, setIsSuggestedResponse] = useState(
    chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId ? true : false
  );

  const anatomyClass = chat?.PROMPT_RESPONSE?.anatomy_class;

  const playerRef = useRef();
  const USER_ID = localStorage.getItem("userId");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const medUser = getLoggedInUser();

  const [file, setFile] = useState();
  const [previewImg, setPreviewImg] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const inActiveTimer = useRef(null);
  const inputRef = useRef(null);
  // const [inActiveModal, setInActiveModal] = useState(false);
  const { state } = useLocation();

  const [textareaValue, setTextareaValue] = useState("");
  const [chatArray, setChatArray] = useState([]);
  const [userPrompt, setUserPrompt] = useState([]);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [chatName, setChatName] = useState();
  const [audioBlob, setAudioBlob] = useState(null);
  // const [playPause] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  // const [speechToTextResponse, setSpeechToTextResponse] = useState(null);
  // const [totalMessageCount, setTotalMessageCount] = useState(null);
  const [spokenMessages, setSpokenMessages] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [startListening, setStartListening] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [questionOptions, setQuestionOptions] = useState(
    state?.suggestedResponse
  );

  const [anatomyModal, setAnatomyModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(state?.language);
  }, [state]);

  // function startInActiveTimer() {
  //   inActiveTimer.current = setTimeout(() => {
  //     clearTimeout(inActiveTimer.current);
  //     setInActiveModal(true);
  //     // }, 10000);
  //   }, 600000);
  // }

  function resetInActiveTimer() {
    clearTimeout(inActiveTimer.current);
    // startInActiveTimer();
  }

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    resetInActiveTimer();
  };

  const updateUserPrompt = () => {
    const existingMessageIds = new Set(
      userPrompt.map((message) => message.messageId)
    );

    const newMessages = chat.CHAT[0].messages.filter(
      (message) =>
        message.fromUser !== "Robot Doctor" &&
        !existingMessageIds.has(message.messageId)
    );

    setUserPrompt([...userPrompt, ...newMessages]);
  };

  useEffect(() => {
    if (!chat?.DOCTOR_PROFILE?.data[0]?.doctorId)
      dispatch(getDoctorProfile({ isSystemDoctor: true }));
  }, [chat?.DOCTOR_PROFILE?.data, dispatch]);

  useEffect(() => {
    if (medUser?.userId && chat?.DOCTOR_PROFILE?.data[0]?.doctorId) {
      dispatch(
        getChatByUsers({
          pageNo: 1,
          size: 20,
          search: null,
          isPagination: true,
          sortBy: null,
          sortDirection: null,
          fromUserId: medUser?.userId,
          toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
          isSessionEnd: false,
          isTitleMessage: false,
        })
      );
    }
  }, [chat?.DOCTOR_PROFILE?.data, medUser?.userId]);

  const saveReply = (response) => {
    // setSpeechToTextResponse(null);
    setChatId(response?.chatId);
    dispatch(
      saveMessage({
        fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
        toUserId: medUser?.userId,
        chatId: response?.chatId,
        isFile: false,
        description: response?.reply,
        isFirstMessage: false,
        language: state?.language,
      })
    );
  };

  const saveChatMoveToNext = (response, reply) => {
    setChatId(response?.data?.data);
    dispatch(
      saveMessage(
        {
          fromUserId: medUser?.userId,
          toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
          chatId: response?.data?.data,
          language: state?.language,
          isFile: false,
          description: textareaValue,
          isFirstMessage: userPrompt?.length === 0 ? true : false, //    chat?.MESSAGE?.length > 0 ? false : true,
        },
        saveReply,
        reply
      )
    );
    setTextareaValue("");
  };

  // const language =  state.language;
  // console.log(state, "state from language", language);
  const chatMoveToNext = (reply, userText, messageValue) => {
    questionsListFunction(reply);
    setIsSuggestedResponse(true);
    setAnatomyModal(reply?.anatomy_class !== "No" ? true : false);
    const userMessage = messageValue
      ? messageValue
      : userText.user_query !== ""
      ? userText.user_query
      : userText.anatomy_text;

    // console.log(
    //   "chat?.CHAT?.length",
    //   chat?.CHAT?.length,
    //   chat?.DOCTOR_PROFILE,
    //   "a94ca6cf-8251-44b5-b98c-aadf8393fda6"
    // );
    // if (!chat?.CHAT?.[0]?.chatId) {
    //   dispatch(
    //     getChatByUsers({
    //       pageNo: 1,
    //       size: 20,
    //       search: null,
    //       isPagination: true,
    //       sortBy: null,
    //       sortDirection: null,
    //       fromUserId: medUser?.userId,
    //       toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
    //       isSessionEnd: false,
    //     })
    //   );
    // }
    if (
      chat?.CHAT?.length === 0 &&
      !chat?.MESSAGE?.[0]?.chatId &&
      !reply?.session_end
    ) {
      dispatch(
        saveChatAction(
          {
            name: "",
            fromUserId: medUser?.userId,
            toUserId: "a94ca6cf-8251-44b5-b98c-aadf8393fda6",
            roleId: 101,
            questionAnswers: [],
          },
          saveChatMoveToNext,
          reply?.ai_response
        )
      );
    } else if (!reply?.session_end) {
      dispatch(
        saveMessage(
          {
            fromUserId: medUser?.userId,
            toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
            chatId:
              chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
            language: state?.language,
            description: textareaValue !== "" ? textareaValue : userMessage,
            isFirstMessage: userPrompt?.length === 0 ? true : false, // chat?.MESSAGE?.length > 0 ? false : true,
            // isFirstMessage:
            //   !chat?.CHAT?.[0] || !chat?.MESSAGE?.[0] ? true : false,
          },
          saveReply,
          reply?.ai_response
        )
      );
    } else {
      handleEndSession();
    }
    if (reply?.anatomy_class === "full_body") {
      setSelectTab(1);
    } else if (reply?.anatomy_class === "muscular_body") {
      setSelectTab(2);
    }
  };

  const sendPrompt = (messageValue, avatarValue, messageKey) => {
    resetInActiveTimer();
    //
    const OptionalPayload = {
      name: medUser?.name,
      age: medUser?.age,
      gender: medUser?.gender,
      occupation: medUser?.occupation,
      firstMessage: userPrompt?.length === 0 ? true : false,
      // firstMessage: userPrompt?.length === 0 ? true : false,
    };

    const prompt = {
      id: Date.now() + Math.random(),
      type: `${file ? "imagePrompt" : "prompt"}`,
      message: messageValue,
      file: previewImg,
    };

    const messageData = {
      user_query: !avatarValue ? messageValue : "",
      anatomy_text: avatarValue ? messageKey : "",
      required_language: state?.language,
      doctorField: state?.specialization,
    };
    setChatArray([...chatArray, prompt]);
    setUserPrompt([...userPrompt, prompt]);
    // setTotalMessageCount((prev) => prev + 1);

    setPreviewImg(null);
    setTextareaValue("");
    dispatch(
      getPromptResponse(
        messageData,
        OptionalPayload,
        chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || null,
        // state?.gender === "male" ? 0 : 1,
        0,
        file,
        messageValue,
        chatMoveToNext
      )
    );
  };

  const handleButtonClick = (e) => {
    e.preventDefault();

    // Prevent sending the message if loading is true
    if (chat?.GET_PROMPT_RESPONSE_IS_LOADING) return;

    const simulatedEvent = { key: "Enter", preventDefault: e.preventDefault };
    handleKeyPress(simulatedEvent);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      audioRef.current?.pause();
      e.preventDefault();
      if (
        (!getBotQueries.TRANSLATE_QUESTIONS?.data?.is_last_question ??
          !chat?.CHAT?.[0]?.isMessageEnd) ||
        selectedOptions
      ) {
        handleCheckboxClick(textareaValue || selectedOptions);
        setSelectedOptions([]);
        setTextareaValue("");
        return;
      }

      if (e.shiftKey) {
        e.preventDefault();
        setTextareaValue((prevValue) => prevValue + "\n");
      } else {
        if (chat?.GET_PROMPT_RESPONSE_IS_LOADING) return;

        e.preventDefault();

        const trimmedValue = textareaValue.trim();

        if (trimmedValue !== "") {
          sendPrompt(trimmedValue);
          setTextareaValue("");
        }
      }
      setTextareaValue("");
    }
  };

  const handleSaveChat = () => {
    dispatch(saveUserChat(chatName, USER_ID, Toaster, moveToNext));
  };

  const moveToNext = () => {
    setConfirmationModalShow(false);
    dispatch(getUserChatHistory(USER_ID));
    setChatArray([]);
    setUserPrompt([]);
  };

  const scrollFunction = () => {
    const scroller = document.querySelector("#chatBox");
    scroller.scrollTo({
      top: scroller.scrollHeight,
      behavior: "smooth", // This enables smooth scrolling
    });
  };

  useEffect(() => {
    if (state?.gender === undefined) {
      navigate(ROUTER_PATH.HOME_PAGE);
    }
    scrollFunction();
  }, [chatArray]);

  const [questionsList, setQuestionsList] = useState(
    state?.suggestedResponse?.answers_list
      ? state?.suggestedResponse?.answers_list
      : []
  );

  // useEffect(() => {
  //   // if (state?.gender !== null && unityLoaded) {
  //   // if (state?.gender !== null) {
  //   dispatch(
  //     getWelcomeMessage(
  //       state?.language,
  //       state?.specialization,
  //       questionsListFunction
  //     )
  //   ); // pass specialization as well
  //   // dispatch(getWelcomeMessage({ id: state?.gender === "male" ? 0 : 1 }));
  //   // }
  //   // }, [dispatch, state, unityLoaded]);
  // }, [dispatch, state]);

  function questionsListFunction(data) {
    setQuestionsList(data?.answers_list);
  }

  const getAudio = async (text, id, messageType) => {
    // dispatch(getAudioFromText());
    let sendData = {
      text: text,
      id: id,
      required_language: state?.language,
    };
    if (messageType !== "imagePrompt" && text !== undefined) {
      const response = await getAudioFromTextApi(sendData);
      const audioDataUrl = `data:audio/mp3;base64,${response.data}`;
      // playPause ? setVideoPlaying(false) : setVideoPlaying(true);
      resetInActiveTimer();
      setAudioBlob(audioDataUrl);
    }
  };

  // useEffect(()=> {
  //   if (audioBlob) {
  //     setVideoPlaying(true);
  //   }

  //   return () => {
  //     setVideoPlaying(false);
  //   };
  // }, [audioBlob]);

  useEffect(() => {
    // if (unityLoaded) {

    const messages = chat?.CHAT?.[0]?.messages.map((item) => ({
      ...item,
      id: Date.now() + Math.random(),
      type: "reply",
      QuestionType: item.messageType,
    }));

    let reply = {
      id: Date.now() + Math.random(),
      type: "reply",
      QuestionType: "ms",
      toUserId: medUser?.userId,
      description: state?.suggestedResponse?.questions,
      options: questionOptions?.options,
      // options:getBotQueries?.TRANSLATE_QUESTIONS?.data?.options
      // description: chat?.WELCOME_MESSAGE,
    };

    if (chat?.CHAT?.length < 1) {
      setChatArray([reply]);
    } else {
      setChatArray(messages);
      updateUserPrompt();
    }
    // startInActiveTimer();
    // setTotalMessageCount(chat?.TOTAL_MESSAGES);
    // }
    // }, [chat?.WELCOME_MESSAGE, unityLoaded]);
  }, [chat?.WELCOME_MESSAGE, chat?.CHAT]);

  useEffect(() => {
    if (chat?.PROMPT_RESPONSE) {
      let reply = {
        id: Date.now() + Math.random(),
        type: "reply",
        toUserId: medUser?.userId,
        description: chat?.PROMPT_RESPONSE?.ai_response,
      };
      setChatArray([...chatArray, reply]);
      // resetInActiveTimer();
      setFile(null);
    }
    // eslint-disable-next-line
  }, [chat?.PROMPT_RESPONSE]);

  const handleReplyMessage = (item) => {
    if (item.type === "imagePrompt") return;
    if (item.type === "reply" && !spokenMessages.includes(item.id)) {
      // Update gender value according to data
      if (state?.gender !== null && item.description !== null) {
        // speakMessage(item.reply, state?.gender);
        // commented by Mahid
        getAudio(
          item.description,
          state?.gender === "female" ? 1 : 0,
          item.type
        );
        resetInActiveTimer();
      }
      setSpokenMessages((prevMessages) => [...prevMessages, item.id]);
    }
  };

  useEffect(() => {
    // Speak "reply" messages when the component
    chatArray?.forEach(handleReplyMessage);
  }, [chatArray, audioBlob]);

  let mediaRecorder = null;
  let recordingStart = false;
  let audioStream = null;
  const timerRef = useRef(null);
  const MIN_DECIBELS = -40;

  const stopRecordingVoice = () => {
    mediaRecorder?.stop();
    audioStream?.getTracks()?.forEach((track) => track?.stop());
    recordingStart = false;
    setStartListening(false);
  };

  const startTimeCount = () => {
    timerRef.current = setTimeout(() => {
      // console.log("no voice");
      clearTimeout(timerRef.current);
      stopRecordingVoice();
      // console.log("recording stop");
    }, 5000);
  };

  function handleResetTimer() {
    // console.log("reseting");
    clearTimeout(timerRef.current);
    startTimeCount();
  }

  const startVoiceRecording = async () => {
    resetInActiveTimer();
    audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    mediaRecorder = new MediaRecorder(audioStream);
    mediaRecorder.start();

    recordingStart = true;
    // console.log("recording start");
    startTimeCount();
    const audioChunks = [];
    mediaRecorder.addEventListener("dataavailable", (event) => {
      audioChunks.push(event.data);

      resetInActiveTimer();
    });

    const audioContext = new AudioContext();
    const audioStreamSource = audioContext.createMediaStreamSource(audioStream);
    const analyser = audioContext.createAnalyser();
    analyser.minDecibels = MIN_DECIBELS;
    audioStreamSource.connect(analyser);

    const bufferLength = analyser.frequencyBinCount;
    const domainData = new Uint8Array(bufferLength);

    let soundDetected = false;

    const detectSound = () => {
      if (!recordingStart) {
        // console.log("not recording");
        return;
      }

      analyser.getByteFrequencyData(domainData);

      for (let i = 0; i < bufferLength; i++) {
        // const value = domainData[i];

        if (domainData[i] > 0) {
          // console.log("sound detected");
          soundDetected = true;
          handleResetTimer();
        }
      }

      window.requestAnimationFrame(detectSound);
    };

    window.requestAnimationFrame(detectSound);

    mediaRecorder.addEventListener("stop", async () => {
      if (soundDetected) {
        const audioBlob = new Blob(audioChunks, {
          type: "audio/wav",
        });
        sendAudioToAPI(audioBlob);
      }
      // console.log("soundDetected", soundDetected);
      resetInActiveTimer();
    });
  };

  const checkAndRequestMicrophonePermission = async (startListen = false) => {
    resetInActiveTimer();
    try {
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);

      // audioStream?.getTracks().forEach((track) => track.stop());
      if (startListen) {
        setStartListening(true);
      }
    } catch (error) {
      setIsPermissionGranted(false);
    }
  };

  useEffect(() => {
    // Add the window event listener for beforeunload
    const handleBeforeUnload = () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    // checkAndRequestMicrophonePermission();
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Stop speech synthesis when the component unmounts
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  // function isPlaying(audelem) {
  //   return !audelem.paused;
  // }

  const sendAudioToAPI = async (audioData) => {
    try {
      const formData = new FormData();
      formData.append("audio_file", audioData, "recorded-audio.wav");
      formData.append("required_language", state?.language);
      const response = await axios.post(
        BASE_URL + "/speech_to_text",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // await setSpeechToTextResponse(response?.data?.response);
      sendPrompt(response.data.response);

      // setSentVoice(false);
      // setAudioBlob(null);
    } catch (error) {
      console.error("Error sending audio to API:", error);
    }
  };

  // useEffect(() => {
  //   if (audioBlob) {
  //     sendAudioToAPI();
  //   }
  // }, [audioBlob]);

  const startVoiceCommand = () => {
    resetInActiveTimer();
    setAudioBlob(null);
    if (!videoPlaying) {
      if (isPermissionGranted) {
        setStartListening(true);
      } else {
        // Request microphone permission
        checkAndRequestMicrophonePermission(true);
      }
    }
  };

  useEffect(() => {
    if (startListening) {
      var resetTimer = setTimeout(() => {
        resetInActiveTimer();
      }, 1000);
      startVoiceRecording();
    }
    return () => {
      clearTimeout(resetTimer);
    };
  }, [startListening]);

  const { width } = useWindowSizeHook();

  const audioRef = useRef(null);

  const handlePlayPause = () => {
    if (audioRef?.current?.paused) {
      // audioRef.current.play();
    } else {
      // audioRef.current.pause();
      resetInActiveTimer();
    }
  };

  useEffect(() => {
    let handlePlayPauseTimer = setTimeout(() => {
      handlePlayPause();
    }, 10000);
    return () => {
      clearTimeout(handlePlayPauseTimer);
    };
  }, []);

  const handlePause = () => {
    // Check if the player reference exists
    if (playerRef?.current) {
      // Set the current time of the video to 0 seconds
      playerRef?.current?.seekTo(0);
    }
  };

  const handleImageUpload = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setPreviewImg(imageUrl);
    }
  };

  const sessionEndSuccess = () => {
    let finalData = {
      chat_id: chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
      required_language: state?.language,
    };
    dispatch(generateChatSummaryAction(finalData));
    // dispatch(generateChatNameAction(finalData));
    // navigate(ROUTER_PATH.THANK_YOU);
    navigate(ROUTER_PATH.THANK_YOU, {
      state: {
        language: state?.language,
      },
    });
  };

  const handleEndSession = () => {
    if (chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId) {
      dispatch(
        endSessionAction(
          {
            chatId:
              chat?.CHAT?.[0]?.chatId || chat?.MESSAGE?.[0]?.chatId || chatId,
          },
          sessionEndSuccess
        )
      );
    } else {
      navigate(ROUTER_PATH.HOME_PAGE);
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.muted = isMuted;
    }
  }, [isMuted, audioBlob]);

  const toggleMute = () => {
    setIsMuted((prevState) => !prevState);
  };

  // const handleMedicalRecord = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type.startsWith("image/")) {
  //     setUploadedImage(URL.createObjectURL(file));
  //   }
  // };

  // const uploadMediaAttachment = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("Image", file, file.name);

  //     uploadMedia(formData).then(
  //       (response) => {
  //         if (response?.data?.succeeded === true) {
  //           setUploadMedicalRecord({
  //             imageUrl: response.data.data.imageUrl,
  //             imageKey: response.data.data.imageKey,
  //             answer: response.data.data.answer,
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.error("Error uploading media", error);
  //       }
  //     );
  //   }
  // };

  // const uploadMediaAttachment = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("Image", file, file.name);

  //     uploadMedia(formData).then(
  //       (response) => {
  //         if (response?.data?.succeeded === true) {
  //           setImageUploadLoading(false);
  //           const { imageUrl, answer, imageKey } = response.data.data;
  //           // First object: for imageUrl, with type "prompt"

  //           const imageMessage = {
  //             id: Date.now() + Math.random(),
  //             type: "imagePrompt",
  //             // toUserId: medUser?.userId,
  //             // Set toUserId
  //             imageUrl: imageUrl,
  //           };
  //           dispatch(
  //             saveMessage({
  //               fromUserId: medUser?.userId,
  //               toUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
  //               chatId: chat?.CHAT?.[0]?.chatId || chatId,
  //               isFile: true,
  //               description: imageKey,
  //               isFirstMessage: false,
  //             })
  //           );
  //           // Second   : for answer, with type "reply"
  //           const replyMessage = {
  //             id: Date.now() + Math.random(),
  //             type: "reply",
  //             toUserId: medUser?.userId,
  //             description: answer,
  //           };
  //           dispatch(
  //             saveMessage({
  //               toUserId: medUser?.userId,
  //               fromUserId: chat?.DOCTOR_PROFILE?.data[0]?.doctorId,
  //               chatId: chat?.CHAT?.[0]?.chatId || chatId,
  //               isFile: false,
  //               description: answer,
  //               isFirstMessage: false,
  //             })
  //           );

  //           // Update chatArray with both objects
  //           setChatArray((prevChatArray) => [
  //             ...prevChatArray,
  //             imageMessage,
  //             replyMessage,
  //           ]);
  //         }
  //       },
  //       (error) => {
  //         setImageUploadLoading(false);
  //         console.error("Error uploading media", error);
  //       }
  //     );
  //   }
  // };

  // const [imageCount, setImageCount] = useState(0);
  const [selectTab, setSelectTab] = useState(1);
  const [avatarData, setAvatarData] = useState([]);

  // useEffect(() => {
  //   // Filter chatArray for entries with valid image URLs
  //   const validImageUrls = chatArray?.filter(
  //     (chat) => chat?.imageUrl && chat?.imageUrl?.startsWith("http")
  //   );
  //   // setImageCount(validImageUrls?.length); // Update the count of images
  // }, [chatArray]);

  const [lastMessage, setLastMessage] = useState("");

  useEffect(() => {
    if (avatarData?.length > 0) {
      const messageKey = avatarData[0];
      let message = messageKey;

      if (selectTab === 1 && medUser?.gender !== "Female") {
        const fullboldyMale = maleFullbodyParts?.[messageKey] || messageKey;
        message =
          state.language === "ar"
            ? fullboldyMale.arabic || messageKey
            : fullboldyMale.english || messageKey;
        // message = maleFullbodyParts?.[messageKey] || messageKey;
      } else if (selectTab === 2) {
        const musclePart = musclesbodyParts?.[messageKey] || {};
        message =
          state.language === "ar"
            ? musclePart.arabic || messageKey
            : musclePart.english || messageKey;
      } else {
        const fullboldyFemale = femaleFullbodyParts?.[messageKey] || messageKey;
        message =
          state.language === "ar"
            ? fullboldyFemale.arabic || messageKey
            : fullboldyFemale.english || messageKey;
        // message = femaleFullbodyParts?.[messageKey] || messageKey;
      }

      const avatarValue = true;

      if (lastMessage !== message) {
        setLastMessage(message);
        setAvatarData([]);

        setTimeout(() => {
          sendPrompt(message, avatarValue, messageKey);
        }, 0);
      }
    }
  }, [
    avatarData,
    selectTab,
    medUser,
    lastMessage,
    setLastMessage,
    setAvatarData,
    sendPrompt,
    maleFullbodyParts,
    femaleFullbodyParts,
    state?.language,
  ]);

  // useEffect(() => {
  //   if (avatarData?.length > 0) {
  //     const messageKey = avatarData[0];
  //     let message = messageKey;
  //     if (selectTab === 1 && medUser?.gender !== "Female") {
  //       message = maleFullbodyParts?.[messageKey] || messageKey;
  //     } else if (selectTab === 2) {
  //       message = musclesbodyParts?.[messageKey] || messageKey;
  //     } else {
  //       message = femaleFullbodyParts?.[messageKey] || messageKey;
  //     }

  //     const avatarValue = true;

  //     if (lastMessage !== message) {
  //       setLastMessage(message);
  //       setAvatarData([]);

  //       setTimeout(() => {
  //         sendPrompt(message, avatarValue, messageKey);
  //       }, 0);
  //     }
  //   }
  // }, [
  //   avatarData,
  //   selectTab,
  //   medUser,
  //   maleFullbodyParts,
  //   musclesbodyParts,
  //   femaleFullbodyParts,
  // ]);

  // useEffect(() => {
  //   if (avatarData?.length > 0) {
  //     const messageKey = avatarData[0]; // The key from avatarData
  //     let message = messageKey;

  //     console.log("messageKey:", messageKey);
  //     console.log("selectTab:", selectTab);

  //     if (selectTab === 1 && medUser?.gender !== "Female") {
  //       // Check if the key exists in maleFullbodyParts, else fallback to musclesbodyParts
  //       if (maleFullbodyParts?.[messageKey]) {
  //         message = maleFullbodyParts[messageKey];
  //       } else if (musclesbodyParts?.[messageKey]) {
  //         message = musclesbodyParts[messageKey]; // If key exists in muscles, use it
  //       } else {
  //         message = messageKey; // Fallback to key if not found in any object
  //       }
  //     } else if (selectTab === 2) {
  //       message = musclesbodyParts?.[messageKey] || messageKey;
  //       console.log("musclesbodyParts:", musclesbodyParts);
  //       console.log("musclesbodyParts message:", message);
  //     } else {
  //       message = femaleFullbodyParts?.[messageKey] || messageKey;
  //       console.log("femaleFullbodyParts:", femaleFullbodyParts);
  //       console.log("femaleFullbodyParts message:", message);
  //     }

  //     const avatarValue = true;

  //     if (lastMessage !== message) {
  //       setLastMessage(message);

  //       setTimeout(() => {
  //         sendPrompt(message, avatarValue, messageKey);
  //       }, 0);
  //     }
  //   }
  // }, [
  //   avatarData,
  //   selectTab,
  //   medUser,
  //   maleFullbodyParts,
  //   musclesbodyParts,
  //   femaleFullbodyParts,
  // ]);

  const ANATOMY_IMAGES = [
    {
      img: IMAGES.ANATOMY_FULL_BODY,
      click: true,
      state: () => setSelectTab(1),
    },
    {
      img: IMAGES.ANATOMY_MUSCLES_FRONT_IMAGE,
      click: true,
      state: () => setSelectTab(2),
    },
    {
      img: IMAGES.ANATOMY_MOUTH,
      click: false,
      state: () => {},
    },
    {
      img: IMAGES.ANATOMY_BRAIN_LEFT,
      click: false,
      state: () => {},
    },
    {
      img: IMAGES.ANATOMY_EAR,
      click: false,
      state: () => {},
    },
    // {
    //   img: IMAGES.ANATOMY_EYE,
    //   click: false,
    //   state: () => {},
    // },
    {
      img: IMAGES.ANATOMY_ORGANS_BG,
      click: false,
      state: () => {},
    },
    {
      img: IMAGES.ANATOMY_SKELETON,
      click: false,
      state: () => {},
    },
    {
      img: IMAGES.ANATOMY_SPINE_1,
      click: false,
      state: () => {},
    },
  ];

  // const consultantSpecializationList = [
  //   {
  //     title: "Physician",
  //     key: "physician",
  //     image: IMAGES.PHYSICIAN,
  //   },
  //   {
  //     title: "Gastroenterologist",
  //     key: "gastroenterologist",
  //     image: IMAGES.GASTROENTROLOGIST,
  //   },
  //   {
  //     title: "Pulmonologist ",
  //     key: "pulmonologist ",
  //     image: IMAGES.PULMONOLOGIST,
  //   },
  //   {
  //     title: "Urologist",
  //     key: "urologist",
  //     image: IMAGES.UROLOGIST,
  //   },
  //   {
  //     title: "Orthopedic",
  //     key: "orthopedic",
  //     image: IMAGES.ORTHOPEIC,
  //   },
  // ];

  const consultantSpecializationList = [
    { id: 1, title: "Physician", key: "physician" },
    { id: 2, title: "Gastroenterologist", key: "gastroenterologist" },
    { id: 3, title: "Pulmonologist", key: "pulmonologist" },
    { id: 4, title: "Urologist", key: "urologist" },
    { id: 5, title: "Orthopedic", key: "orthopedic" },
  ];

  function getNextQuestions(response) {
    if (!response.succeeded) {
      return;
    }
    setChatArray((prev) => [
      ...prev,
      {
        id: Date.now() + Math.random(),
        type: "reply",
        toUserId: medUser?.userId,
        description: response?.data?.questions,
        ...response?.data,
      },
    ]);

    // setQuestionOptions?.options(...questionOptions, response?.options || [])
    // setQuestionOptions((prevOptions) => ({
    //   ...prevOptions,
    //   options: [...(prevOptions.options || []), ...(response?.data?.options || [])]
    // }));
  }

  const handleCheckboxClick = (question, index) => {
    if (
      getBotQueries?.TRANSLATE_QUESTIONS?.data?.is_last_question ??
      chat?.CHAT?.[0]?.isMessageEnd
    ) {
      sendPrompt(question.toString());
      return;
    }
    if (
      !chat?.GET_WELCOME_MESSAGE_IS_LOADING &&
      !chat?.GET_PROMPT_RESPONSE_IS_LOADING
    ) {
      const prompt = {
        id: Date.now() + Math.random(),
        type: `${file ? "imagePrompt" : "prompt"}`,
        message: question,
        file: previewImg,
      };

      setChatArray([...chatArray, prompt]);
      setUserPrompt([...userPrompt, prompt]);

      // Prevent action when loading

      // sendPrompt(question);

      const selectedSpecialityKey = consultantSpecializationList.find(
        (speciality) => speciality.id === parseInt(state?.specialization)
      );

      const payload = {
        doctorField: selectedSpecialityKey?.key,
        user_language: state.language,
        current_index:
          getBotQueries?.TRANSLATE_QUESTIONS?.data?.next_index ??
          chat?.CHAT?.[0]?.messageCount,
        db: {
          description: question.toString(),
          fromUserId: medUser?.userId,
          toUserId: "a94ca6cf-8251-44b5-b98c-aadf8393fda6",
          chatId: chat?.CHAT?.[0]?.chatId || chat?.CHAT_ID?.data,
        },
        is_last_message:
          getBotQueries?.TRANSLATE_QUESTIONS?.data?.is_last_message,
        is_first_message: false,
      };

      dispatch(translateSuggestedQuestion(payload, getNextQuestions));
    }
  };

  return (
    <div className="d-lg-flex justify-content-center" id="app-content">
      {/* <HumanBody selectedPatient={{gender:"Male"}} avatarData={avatarData} setAvatarData={setAvatarData} /> */}
      <Box
        width={
          width < 992
            ? "25%"
            : width < 1300
            ? "20rem"
            : width < 1500
            ? "25rem"
            : "30rem"
        }
        height="100%"
        borderRadius="1.375rem"
        background={
          width < 992
            ? ""
            : "linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        }
        shadow={width < 992 ? "" : "0px 0px 40px 20px rgba(0, 0, 0, 0.09)"}
        backdropFilter="blur(15px)"
        className={`suggestedResponseBox ${
          isSuggestedResponse ? "" : "displayNone"
        } ${
          width < 992
            ? "float-end  mt-3"
            : "mb-5 mb-lg-0 chat-history-box me-3 py-3 chat-box-a"
        } `}
      >
        <div className="w-75 mx-auto">
          <Typography
            as="h6"
            color="#E30020"
            className="fw-bold mt-3 mb-5 text-uppercase text-center"
          >
            {t("suggestedQueries")}
          </Typography>

          {questionsList?.length > 0 &&
            questionsList?.map((question, index) => (
              <div
                key={index}
                className="d-flex align-items-center mb-2 gap-3 mt-3"
                style={{
                  direction: state.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <div
                  className={`d-flex gap-2 align-items-center mb-0 ${
                    (chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
                      chat?.GET_PROMPT_RESPONSE_IS_LOADING) &&
                    "cursor-no-drop"
                  }`}
                  style={{
                    flexDirection:
                      state.language === "ar" ? "row-reverse" : "row",
                    alignItems: "center",
                  }}
                >
                  <button
                    id={`checkbox-${index}`}
                    onClick={() => handleCheckboxClick(question, index)}
                    disabled={
                      chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
                      chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    }
                    className="suggested-tabs-style"
                    type="button"
                  >
                    <Typography
                      as="label"
                      // color="#fff"
                      align={state.language === "ar" ? "right" : "left"}
                      className="fw-bold"
                      style={{
                        marginTop: "auto",
                        marginBottom: "auto", // Vertically center the text
                      }}
                    >
                      {question}
                    </Typography>
                  </button>
                </div>
              </div>
            ))}
        </div>

        <div>
          {anatomyModal && (
            <GenericModal
              size={"lg"}
              show={anatomyModal}
              onHide={() => setAnatomyModal(false)}
              closeModal
            >
              {selectTab === 1 ? (
                <HumanBody
                  selectedPatient={medUser?.gender}
                  avatarData={avatarData}
                  setAvatarData={setAvatarData}
                  anatomyModal={anatomyModal}
                  onHide={() => setAnatomyModal(false)}
                  bodyLoader={chat?.GET_PROMPT_RESPONSE_IS_LOADING}
                />
              ) : (
                <MusclesStructure
                  selectedPatient={medUser?.gender}
                  avatarData={avatarData}
                  setAvatarData={setAvatarData}
                  bodyLoader={chat?.GET_PROMPT_RESPONSE_IS_LOADING}
                  anatomyModal={anatomyModal}
                  onHide={() => setAnatomyModal(false)}
                />
              )}

              <div className="type-btn">
                {ANATOMY_IMAGES?.map((item, index) => (
                  <div
                    className={`${
                      !item?.click ? "cursor-no-drop" : "curser-pointer"
                    } anatomy-img`}
                    onClick={item?.state}
                    key={index}
                  >
                    <img src={item.img} alt="anatomy" className="img-fluid" />
                  </div>
                ))}
              </div>
            </GenericModal>
          )}
        </div>
        <div
          className={` ${
            width < 992
              ? "m-0"
              : "video-container text-center w-100 mb-auto mt-2 position-relative"
          }  `}
        >
          <div className="d-none">
            {audioBlob && (
              <audio
                autoPlay
                ref={audioRef}
                onEnded={() => {
                  // setVideoPlaying(false);
                  setAudioBlob(null);
                  // resetInActiveTimer();
                }}
                src={audioBlob}
              >
                Your browser does not support the audio tag.
              </audio>
            )}
          </div>

          {/* <ReactPlayer
            url={IMAGES.FEMALE_DOCTOR}
            ref={videoPlaying ? playerRef : null}
            muted
            playing={videoPlaying}
            loop={videoPlaying}
            width={`${width < 992 ? "90%" : "100%"}`}
            height={"100%"}
            onPause={handlePause}
          /> */}
          {/* {state?.gender === "male" ? (
            <ReactPlayer
              url={IMAGES.MALE_DOCTOR}
              ref={playerRef}
              muted
              playing={videoPlaying}
              loop={videoPlaying}
              width={`${width < 992 ? "90%" : "100%"}`}
              height={"100%"}
              onPause={handlePause}
            />
          ) : (
            state?.gender === "female" && (
              <ReactPlayer
                url={IMAGES.FEMALE_DOCTOR}
                ref={playerRef}
                muted
                playing={videoPlaying}
                loop={videoPlaying}
                width={`${width < 992 ? "90%" : "100%"}`}
                height={"100%"}
                onPause={handlePause}
              />
            )
          )} */}
          <ReactPlayer
            url={IMAGES.MALE_DOCTOR}
            ref={playerRef}
            muted
            playing={videoPlaying}
            loop={videoPlaying}
            width={`${width < 992 ? "90%" : "100%"}`}
            height={"100%"}
            onPause={handlePause}
          />
        </div>

        <div className={`${width < 992 ? "d-none" : "w-100"}`}>
          <Row className="justify-content-start m-0 px-2 mb-3">
            <Col sm={12}>
              <Row>
                {state?.vitals?.map((item) => (
                  <Col sm={6}>
                    <Typography as="label" color="#636967" className="mt-2">
                      {item.title}
                    </Typography>
                    <Typography as="p" color="#4A4F4D">
                      {item.desc}
                    </Typography>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
        <div
          className={`${
            width < 992
              ? "d-none"
              : "px-3 my-2 w-100 d-flex align-items-center justify-content-center flex-column gap-2"
          }`}
        >
          <input
            ref={inputRef}
            onChange={handleImageUpload}
            className="d-none"
            type="file"
          />
        </div>
        {/* <div className="mb-4">
          <Button
            height="3rem"
            minWidth={width < 1300 ? "8rem" : "10rem"}
            className={`${width < 992 ? "d-none" : ""} mx-auto`}
            onClick={() => {
              setAnatomyModal(true);
              console.log("anatomyModal", anatomyModal);
            }}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              Anatomy
            </Typography>
          </Button>
        </div> */}

        {/* {["muscular_body", "full_body"].includes(anatomyClass) && (
        <div className="type-btn">
            {ANATOMY_IMAGES?.map((item, index) => (
              <div
                className={`${
                  !item?.click ? "cursor-no-drop" : "curser-pointer"
                } anatomy-img`}
                disabled={!item?.click}
                onClick={()=> {console.log(item?.state); setAnatomyModal(true); item.state(); console.log("!item?.click", item)}}
                key={index}
              >
                <img src={item.img} alt="anatomy" className="img-fluid" />
              </div>
            ))}
          </div>
        )} */}

        <div className="type-btn">
          {ANATOMY_IMAGES?.map((item, index) => (
            <div
              className={`anatomy-img ${
                item.click ? "cursor-pointer" : "cursor-no-drop"
              }`}
              onClick={() => {
                if (item.click) {
                  setAnatomyModal(true);
                  item.state();
                }
              }}
              key={index}
            >
              <img src={item.img} alt="anatomy" className="img-fluid" />
            </div>
          ))}
        </div>

        <div className="px-3 w-100 d-flex align-items-center justify-content-center  ">
          <Button
            height="3rem"
            minWidth={width < 1300 ? "8rem" : "10rem"}
            // disabled={medUser?.planId === 1}
            className={`${width < 992 ? "d-none" : ""} mx-auto`}
            onClick={() => {
              handleEndSession();
            }}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
              background="linear-gradient(90deg, #ff4c65, #e30020)"
            >
              {chat?.END_SESSION_LOADING ? <GenericLoader /> : t("endSession")}
            </Typography>
          </Button>

          {/* <Button
            height="3rem"
            minWidth={width < 1300 ? "6rem" : "10rem"}
            // disabled={medUser?.planId === 1}
            className={`${width < 992 ? "d-none" : ""}`}
            backgroundColor="rgb(83 147 44)"
            onClick={() => {
              setPlayPause(!playPause);
              setAudioBlob(null);
              setVideoPlaying(false);
              handlePause();
            }}
          >
            <Typography
              color="#fff"
              fontSize="1.5rem"
              fontWeight="500"
              className="text-center w-100"
            >
              {playPause ? "Unmute" : "Mute"}
            </Typography>
          </Button> */}
          <div
            className={`${width < 992 ? "d-none" : ""}`}
            onClick={() => {
              toggleMute();
              // setPlayPause(!playPause);
              // setAudioBlob(null);
              // setVideoPlaying(false);
              // handlePause();
            }}
          >
            {isMuted ? (
              <VolumeOffIcon
                className="fs-1 "
                style={{ color: "rgb(83 147 44)" }}
              />
            ) : (
              <VolumeUpIcon
                className="fs-1 "
                style={{ color: "rgb(83 147 44)" }}
              />
            )}
          </div>
        </div>
      </Box>

      <Box
        width={isSuggestedResponse ? "100%" : "80rem"}
        height="100%"
        maxHeight="35rem"
        maxWidth="200rem"
        borderRadius="1.375rem"
        background="linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.70) 100%)"
        shadow="0px 0px 40px 20px rgba(0, 0, 0, 0.09)"
        backdropFilter="blur(15px)"
        className="pt-5 mb-auto mb-5 chat-box-b"
      >
        <div>
          <Typography
            as={width < 600 ? "h6" : "h5"}
            align="center"
            color="#E30020"
          >
            {t("chatWindowHeading")}
          </Typography>
          <div
            className={
              isSuggestedResponse ? "d-none" : "d-flex justify-content-end"
            }
          >
            <Button
              onClick={() => handleEndSession()}
              className={` text-white fw-normal btn-color me-5 ${
                width > 650 && width < 700
                  ? "mt-2"
                  : width > 700 && width < 750
                  ? "mt-3"
                  : width > 750 && width < 770
                  ? "mt-4"
                  : ""
              }`}
              borderRadius="8px"
              height="2.7rem"
              minWidth="7rem"
              backgroundColor="linear-gradient(90deg, #ff4c65, #e30020)"
            >
              {t("endSession")}
            </Button>
          </div>
        </div>

        <Box
          width="100%"
          height="calc(100vh - 28.5rem)"
          minHeight="450px"
          background="transparent"
          className="px-sm-1 px-lg-5 mt-4"
          overflowY="auto"
          id="chatBox"
        >
          {chatArray?.map((item, index) => {
            const isReply = item?.toUserId === medUser?.userId;

            return isReply ? (
              <ReplyTemplate
                reply={item?.reply ? item?.reply : item?.description}
                // loader={
                //   chat?.GET_WELCOME_MESSAGE_IS_LOADING || imageUploadingLoading
                // }
                getAudio={getAudio}
                resetInActiveTimer={resetInActiveTimer}
                gender={state?.gender}
                videoPlaying={videoPlaying}
                setVideoPlaying={setVideoPlaying}
                setAudioBlob={setAudioBlob}
                setIsMuted={setIsMuted}
                playAudioBlob={audioBlob}
                pauseAudioBlob={setAudioBlob}
                messageId={index}
                disabled={chatArray.length - 1 !== index}
                options={item.options ?? []}
                type={item.QuestionType}
                state={state}
                no={index}
                handleCheckboxClick={handleCheckboxClick}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                // image={item?.imageUrl}
              />
            ) : (
              <MessageTemplate
                fromUser={item?.fromUser}
                message={item?.message || item?.description}
                image={item?.imageUrl}
              />
            );
          })}

          {(chat?.GET_WELCOME_MESSAGE_IS_LOADING ||
            chat?.GET_PROMPT_RESPONSE_IS_LOADING ||
            getBotQueries?.TRANSLATE_QUESTIONS_LOADING) && (
            <Loader size={10} className="m-3" />
          )}
        </Box>

        <Box
          width="100%"
          background="var(--secondary-150, #F4FBEA)"
          borderRadius="0.75rem 0.75rem 0 0"
          className="d-flex flex-wrap justify-content-between py-2 px-sm-1 px-lg-3"
        >
          {/* Voice Recording Section */}
          <div className="d-flex align-items-center justify-content-center bg-red-700">
            {startListening && chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true ? (
              <Box
                width="2.2rem"
                height="2.2rem"
                background="#ED6A58"
                className="rounded-circle d-flex justify-content-center align-items-center mx-3 cursor-pointer"
                onClick={() => {
                  setStartListening(false);
                  resetInActiveTimer();
                  stopRecordingVoice();
                }}
              >
                <BsFillStopFill color="white" size={width > 1360 ? 24 : 15} />
              </Box>
            ) : (
              <Box
                width="2.2rem"
                height="2.2rem"
                background={
                  videoPlaying || chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
                    ? "#898989"
                    : "#53972C"
                }
                className={`rounded-circle d-flex justify-content-center align-items-center mx-3 mt-1 ${
                  videoPlaying || chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING === true
                    ? null
                    : startVoiceCommand
                }
              >
                <BsFillMicMuteFill
                  color="#ffffff"
                  size={width > 1360 ? 20 : 15}
                />
              </Box>
            )}

            {startListening &&
              chat?.GET_PROMPT_RESPONSE_IS_LOADING !== true && (
                <Loader size={10} />
              )}
            {!startListening && (
              <b className="me-2">
                <small style={{ fontSize: width < 600 ? "11px" : "13px" }}>
                  {t("pressMicButton")}
                </small>
              </b>
            )}
          </div>
        </Box>

        <div className="input-field">
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <GenericInput
              placeholder={t("placeholder")}
              fontSize={width < 600 ? "14px" : "1rem"}
              width="100%"
              minHeight="100%"
              disabled={
                chat?.CHAT?.[0]?.isMessageEnd
                  ? false
                  : chatArray[chatArray.length - 1]?.QuestionType !== "text" &&
                    !isSuggestedResponse
              }
              // value={textareaValue}
              value={
                selectedOptions.length > 0
                  ? selectedOptions.join(", ")
                  : textareaValue
              }
              onChange={handleTextareaChange}
              onKeyPress={handleKeyPress}
              as="textarea"
              border="none"
              borderRadius="0 0 1.375rem 2rem"
              maxLength={1000}
            />

            <button
              onClick={handleButtonClick}
              disabled={
                (!textareaValue.trim() && selectedOptions.length === 0) ||
                chat?.GET_PROMPT_RESPONSE_IS_LOADING
              }
              style={{
                background: "none",
                border: "none",
                cursor:
                  (!textareaValue.trim() && selectedOptions.length === 0) ||
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? "not-allowed"
                    : "pointer",
                padding: "0 10px",
                opacity:
                  (!textareaValue.trim() && selectedOptions.length === 0) ||
                  chat?.GET_PROMPT_RESPONSE_IS_LOADING
                    ? 0.5
                    : 1,
              }}
            >
              <FaCircleArrowUp size={30} color="#000" />
            </button>
          </div>

          {/* {uploadedImage && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <img
                src={uploadedImage}
                alt="Uploaded"
                style={{ width: "100px", marginRight: "10px" }}
              />
              <FaTimes
                size={20}
                style={{ cursor: "pointer" }}
                onClick={() => setUploadedImage(null)}
              />
            </div>
          )} */}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span style={{ fontSize: "12px", color: "#757575" }}>
              {textareaValue.length}/1000 {t("characters")}
            </span>
          </div>
        </div>
      </Box>
      {/*  */}
      {confirmationModalShow && (
        <ConfirmationModal
          show={confirmationModalShow}
          onHide={() => setConfirmationModalShow(false)}
          size="md"
          title={<Typography as="h6">Save Chat in History</Typography>}
          successAction={handleSaveChat}
          loader={chat?.SAVE_CHAT_HISTORY_IS_LOADING}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <Typography as="p" size="1.5rem">
                Chat Name
              </Typography>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name of Chat"
              onChange={(e) => {
                setChatName(e.target.value);
              }}
              autoFocus
            />
          </Form.Group>
        </ConfirmationModal>
      )}
      {/*
      <ConfirmationModal
        show={inActiveModal}
        onHide={() => setInActiveModal(false)}
        size="md"
        hideFooter={true}
        className="d-flex justify-content-center align-items-center custom-modal-style"
      >
        <div className="text-center w-100 px-2 px-md-5">
          <Typography
            align="center"
            fontSize={width < 500 ? "1rem" : "1.2rem"}
            fontWeight="500"
            className="p-3"
          >
            Hey, are you still with us?
          </Typography>
          <div className="d-flex gap-3  justify-content-center align-items-center">
            <Button
              className="bg-secondary"
              minWidth={width < 500 ? "6rem" : "8rem"}
              height={width < 500 ? "2.5rem" : "3rem"}
              onClick={() => {
                handleEndSession();
              }}
            >
              <Typography
                align="center"
                fontSize="1rem"
                fontWeight="500"
                color="#fff"
              >
                No
              </Typography>
            </Button>
            <Button
              minWidth={width < 500 ? "6rem" : "8rem"}
              height={width < 500 ? "2.5rem" : "3rem"}
              onClick={() => {
                setInActiveModal(false);
                resetInActiveTimer();
              }}
              className="px-4 py-2 text-white"
              variant="success"
            >
              <Typography
                color="#fff"
                fontSize="1.3rem"
                fontWeight="400"
                className="text-center w-100"
              >
                Yes
              </Typography>
            </Button>
          </div>
        </div>
      </ConfirmationModal>
      */}
      <GenericModal
        show={previewVisible}
        onHide={() => setPreviewVisible(false)}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImg} />
      </GenericModal>
      <UpgradeModal isVisible={isVisible} setIsVisible={setIsVisible} />
    </div>
  );
}
