/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Box, GenericLoader, Typography } from "../../../../Components";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLookupList } from "../../../../Redux/actions";

const DietaryHabits = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllLookupList("DietaryHabit"));
  }, []);

  const data = useSelector((state) => state.patientProfile);

  return (
    <div>
      {" "}
      <Typography fontSize="1rem" fontWeight="400" color="#E30020">
        LIFE PATTERNS & HABITS
      </Typography>
      <Typography fontSize="1.25rem" fontWeight="500" className="my-3">
        Dietary Habits
      </Typography>
      {data?.GET_LOOKUP_LIST_LOADING ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "150px" }}
        >
          <GenericLoader />
        </div>
      ) : (
        data?.GET_LOOKUP_LIST?.map((option, idx) => (
          <>
            <Box
              className="p-3 my-3 cursor-pointer option-wrapper"
              onClick={() => {
                setFieldValue("dietary", option?.lookupId);
              }}
              height="100%"
              width="100%"
              borderRadius="0.5rem"
              background={values?.dietary === option?.lookupId && "#E30020"}
              border={
                values?.dietary === option?.lookupId && "1px solid #E30020"
              }
            >
              <Typography
                fontSize=".9rem"
                fontWeight={values?.dietary === option?.lookupId && "500"}
                className="heading"
                color={values?.dietary === option?.lookupId && "white"}
              >
                {option?.text}
              </Typography>
              <span style={{ fontSize: "14px" }}>{option?.description}</span>
            </Box>
            {values?.dietary === 4 && option?.id === 4 && (
              <>
                <Form.Control
                  type="text"
                  backgroundColor="#EBEBFF"
                  name="dietaryInput"
                  placeholder={"Please Specify"}
                  className="w-100"
                  value={values?.dietaryInput}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.dietaryInput && !!errors.dietaryInput}
                  size="lg"
                  autoFocus
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dietaryInput}
                </Form.Control.Feedback>
              </>
            )}
          </>
        ))
      )}
    </div>
  );
};

export default DietaryHabits;
