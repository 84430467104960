// import React, { useState } from "react";
// import {
//   MenuItem,
//   Select,
//   FormControl,
//   Stack,
//   Chip,
//   Typography,
//   TextField,
//   Button,
// } from "@mui/material";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CheckIcon from "@mui/icons-material/Check";

// export default function CustomMultiSelect({
//   symptoms,
//   selectedValues,
//   setSelectedValues,
//   selectedLanguage, // Pass selectedLanguage as a prop
// }) {
//   const [newSymptom, setNewSymptom] = useState(""); // State for new symptom input

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;

//     setSelectedValues(value);
//   };

//   const handleAddSymptom = () => {
//     if (newSymptom && !symptoms.includes(newSymptom)) {
//       // Add new symptom if it's not already included
//       symptoms.push(newSymptom);
//       setNewSymptom(""); // Clear the input field
//       setSelectedValues([...selectedValues, newSymptom]); // Select the new value
//     }
//   };

//   return (
//     <FormControl sx={{ m: 1, width: 500 }}>
//       <Select
//         multiple
//         value={selectedValues || []}
//         onChange={handleChange}
//         renderValue={(selected) => (
//           <Stack gap={1} direction="row" flexWrap="wrap">
//             {selected.map((value) => (
//               <Chip
//                 key={value}
//                 label={value}
//                 onDelete={() => {
//                   const newSelected = selectedValues?.filter(
//                     (item) => item !== value
//                   );
//                   setSelectedValues(newSelected);
//                 }}
//                 deleteIcon={
//                   <CancelIcon
//                     onMouseDown={(event) => event.stopPropagation()}
//                   />
//                 }
//               />
//             ))}
//           </Stack>
//         )}
//         MenuProps={{
//           PaperProps: {
//             sx: {
//               maxHeight: 200,
//               overflow: "auto",
//             },
//           },
//         }}
//       >
//         {symptoms?.map((name) => (
//           <MenuItem
//             key={name}
//             value={name}
//             sx={{ justifyContent: "space-between" }}
//           >
//             {name}
//             {selectedValues?.includes(name) ? <CheckIcon color="info" /> : null}
//           </MenuItem>
//         ))}
//       </Select>

//       {/* Input field for adding a new symptom */}
//       <Stack direction="row" spacing={1} alignItems="center" mt={2}>
//         <TextField
//           label="Add symptom"
//           variant="outlined"
//           size="small"
//           value={newSymptom}
//           onChange={(e) => setNewSymptom(e.target.value)}
//         />
//         <Button variant="btn" className="btn-color" onClick={handleAddSymptom}>
//           Add
//         </Button>
//       </Stack>
//     </FormControl>
//   );
// }

import { Chip, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import { Field, useFormikContext, getIn } from "formik"; // Import necessary Formik components
import { FiX } from "react-icons/fi";

export default function CustomMultiSelect({
  name,
  placeholder,
  label,
  disabled,
  enableAddButton,
  onChange,
}) {
  const [manualTag, setManualTag] = useState(""); // State for manual tag input
  const { setFieldValue, values } = useFormikContext();

  // Access selected values from Formik
  const selectedValueWatch = Array.isArray(getIn(values, name))
    ? getIn(values, name)
    : [];

  // Function to clear the selected value
  const clearSelection = () => {
    setFieldValue(name, []);
    onChange([]); // This should not throw an error anymore
  };

  const handleDelete = (chipToDelete) => {
    const filteredData = selectedValueWatch.filter(
      (chip) => chip.value !== chipToDelete.value
    );
    setFieldValue(name, filteredData);
    onChange(filteredData);
  };

  const handleAddManualTag = () => {
    if (manualTag) {
      const newTag = { label: manualTag, value: manualTag }; // Create new tag object
      const updatedValues = [...selectedValueWatch, newTag];
      setFieldValue(name, updatedValues);
      onChange(updatedValues);
      setManualTag(""); // Clear the input after adding
    }
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div
        className="mui-select-search custom-form-element"
        style={{ width: "100%" }}
      >
        <TextField
          placeholder={placeholder || `Enter ${label}`}
          variant="outlined"
          className="mui-multi-select w-25"
          value={manualTag}
          onChange={(e) => setManualTag(e.target.value)} // Update manualTag state on change
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent the default behavior of the Enter key

              handleAddManualTag(); // Add the manual tag on Enter key press
            }
          }}
          InputProps={{
            endAdornment: (
              <>
                {selectedValueWatch.length > 0 && !disabled && (
                  <FiX
                    style={{ color: "#343a40", cursor: "pointer" }}
                    onClick={clearSelection}
                  />
                )}
              </>
            ),
          }}
        />
        {enableAddButton && (
          <button
            type="button"
            style={{
              background:"linear-gradient(90deg, #ff4c65, #e30020)",
                color:"#fff",
            }}
            className="btn ms-2"
            onClick={handleAddManualTag}
          >
            Add
          </button>
        )}
      </div>

      <div
        className="d-flex gap-2 flex-wrap chipContainerMultiselect"
        style={{ margin: "3px" }}
      >
        {selectedValueWatch.map((item) => (
          <Chip
            key={item.value} // Added key for each Chip
            label={item.label}
            onDelete={() => handleDelete(item)}
          />
        ))}
      </div>

      {/* Hidden field for Formik */}
      <Field name={name} type="hidden" />
    </>
  );
}

CustomMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func, // Ensure onChange is optional
};

CustomMultiSelect.defaultProps = {
  onChange: () => {}, // Default to a no-op function
};
