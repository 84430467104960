import {
  getSpecializationList,
  getSuggestedQuestionsAPI,
  saveSpecializationList,
  translateQuestionsAPI,
} from "../api/specializationQuestions";

export function getSpecializationQuestionsList(id) {
  return (dispatch) => {
    dispatch({
      type: "GET_SPECIALIZATION_QUESTIONS_REQUEST",
    });
    getSpecializationList(id).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "GET_SPECIALIZATION_QUESTIONS_SUCCESS",
            payload: response.data,
          });
        }
      },
      (error) => {
        dispatch({
          type: "GET_SPECIALIZATION_QUESTIONS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function saveSpecializationQuestionsList(payload, moveToNext) {
  return (dispatch) => {
    dispatch({
      type: "SAVE_SPECIALIZATION_QUESTIONS_REQUEST",
    });
    saveSpecializationList(payload).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "SAVE_SPECIALIZATION_QUESTIONS_SUCCESS",
            payload: response.data,
          });
        }
        if (moveToNext) moveToNext(response);
      },
      (error) => {
        dispatch({
          type: "SAVE_SPECIALIZATION_QUESTIONS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function getSuggestedQuestion(specialization, language) {
  return (dispatch) => {
    dispatch({
      type: "GET_SUGGESTED_QUESTIONS_REQUEST",
    });
    getSuggestedQuestionsAPI(specialization, language).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "GET_SUGGESTED_QUESTIONS_SUCCESS",
            payload: response.data,
          });
        }
      },
      (error) => {
        console.log("API error:", error); // Log any error for debugging
        dispatch({
          type: "GET_SUGGESTED_QUESTIONS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}

export function translateSuggestedQuestion(
  payload,
  moveToNext,
  messageValue,
) {
  return (dispatch) => {
    dispatch({
      type: "TRANSLATE_QUESTIONS_REQUEST",
    });
    translateQuestionsAPI(payload).then(
      (response) => {
        if (response?.status === 200 || response?.succeeded) {
          dispatch({
            type: "TRANSLATE_QUESTIONS_SUCCESS",
            payload: response.data,
          });
        }
        if (moveToNext) moveToNext(response?.data, messageValue);
      },
      (error) => {
        if (moveToNext) moveToNext(error?.response?.data);
        dispatch({
          type: "TRANSLATE_QUESTIONS_FAILURE",
          payload: error.message,
        });
      }
    );
  };
}
