import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { chatReducer } from "./chatReducer";
import { vitalMonitoringReducer } from "./vitalMonitoringsReducer";
import { subscriptionReducer } from "./subscriptionReducer";
import { messageReducer } from "./messageReducer";
import { patientProfileReducer } from "./patientProfileReducer";
import { DashboardReducer } from "./dashboardReducer";
import { specializationQuestionsReducer } from "./specializationQuestionsReducer";

export const rootReducer = combineReducers({
  users: userReducer,
  chat: chatReducer,
  vitals: vitalMonitoringReducer,
  subscription: subscriptionReducer,
  messages: messageReducer,
  patientProfile: patientProfileReducer,
  AdminDashboard:DashboardReducer,
  specializationQuestions:specializationQuestionsReducer,
});
